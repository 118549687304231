//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { utils } from "ethers";
import dayjs from "dayjs";
import { fetchPerpetualHistory } from "../../../api/wallet/index";
import { commifyAndFloor, formatUTCTime, getHashBlockUrl, toFutresI18n, getUTCTime } from "../../../utils/index";
import futuresConfig from "./../../../config/futures";
export default {
	name: "FuturesHistory",
	props: {
		showBread: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			bread: [
				{
					path: "/future",
					name: this.$t("wallet_common_futures"),
				},
				{
					path: "/wallet/future/transaction-history",
					name: this.$t("wallet_history_transaction_history"),
				},
			],

			time: [],
			chainLayer: null,
			symbol: null,
			tokens: [],

			txType: "",
			txTypeList: [
				{
					label: this.$t("wallet_common_all"),
					value: "All",
				},
				{
					label: this.$t("wallet_perpetual_transfer"),
					value: "transfer",
				},
				{
					label: this.$t("wallet_perpetual_commission_fee"),
					value: "commission_fee",
				},
				{
					label: this.$t("wallet_perpetual_funding_fee"),
					value: "funding_fee",
				},
				{
					label: this.$t("wallet_perpetual_realized_pnl"),
					value: "pnl",
				},
				// {
				// 	label: this.$t("wallet_perpetual_margin_pledge"),
				// 	value: "margin_pledge",
				// },
				// {
				// 	label: this.$t("wallet_perpetual_margin_redemption"),
				// 	value: "margin_redemption",
				// },
			],

			loadingHistory: false,
			historyList: [],
			pages: {
				pageNo: 1,
				pageSize: 20,
                total: 0,
			},
			columns: [
				{
					title: this.$t("wallet_history_type"),
					// dataIndex: "txType",
					key: "txType",
					width: 180,
					scopedSlots: { customRender: "txType" },
				},
				{
					title: this.$t("trade_trade_order_symbol"),
					// dataIndex: "createdAt",
					key: "symbol",
					width: 120,
					scopedSlots: { customRender: "symbol" },
				},
				{
					title: this.$t("wallet_common_date"),
					// dataIndex: "createdAt",
					key: "createdAt",
					width: 200,
					scopedSlots: { customRender: "createdAt" },
				},
				{
					title: this.$t("wallet_common_asset"),
					// dataIndex: "token",
					key: "token",
					width: 80,
					scopedSlots: { customRender: "token" },
				},
				{
					title: this.$t("wallet_common_amount"),
					// dataIndex: "amount",
					key: "amount",
					width: 120,
					scopedSlots: { customRender: "amount" },
				},
				{
					title: this.$t("wallet_common_status"),
					// dataIndex: "amount",
					key: "status",
					// width: 100,
					scopedSlots: { customRender: "status" },
				},
				{
					title: this.$t("wallet_common_information"),
					key: "information",
					scopedSlots: { customRender: "information" },
                    width: 350,
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
                    align: "center",
                    width: 100,
					scopedSlots: { customRender: "action" }
				},
			],
			transferColumns: [
				{
					title: this.$t("wallet_history_type"),
					// dataIndex: "txType",
					key: "txType",
					width: 180,
					scopedSlots: { customRender: "txType" },
				},
				{
					title: this.$t("wallet_common_date"),
					// dataIndex: "createdAt",
					key: "createdAt",
					width: 200,
					scopedSlots: { customRender: "createdAt" },
				},
				{
					title: this.$t("wallet_common_asset"),
					// dataIndex: "token",
					key: "token",
					width: 80,
					scopedSlots: { customRender: "token" },
				},
				{
					title: this.$t("wallet_common_amount"),
					// dataIndex: "amount",
					key: "amount",
					width: 100,
					scopedSlots: { customRender: "amount" },
				},
				{
					title: this.$t("wallet_common_status"),
					// dataIndex: "amount",
					key: "status",
					// width: 100,
					scopedSlots: { customRender: "status" },
				},
				{
					title: this.$t("wallet_common_information"),
					key: "information",
					scopedSlots: { customRender: "information" },
                    width: 450,
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
                    align: "center",
                    width: 100,
					scopedSlots: { customRender: "action" }
				},
			],
            toFutresI18n,
            showTxHashArr: [] // 显示和隐藏交易详情
		};
	},
	computed: {
		// account() {
		// 	return this.$store.state.wallet.wallet.address;
		// },
		account() {
			return this.$store.getters.account || '';
		},
		chainId() {
			const [chainId] = this.chainLayer.split('-');
			return chainId;
		},
		layer2Config() {
			return this.$store.state.siteConfig.layer2Map[this.chainId];
		},
		tokenIdMap() {
			let result = {};
			this.tokens.forEach((item) => {
				if (item.symbol !== "All") {
					result[item.id] = item;
				}
			});
			return result;
		},
        networksOverview() {
            return this.$store.getters.networksOverview;
        },
		networks() {
			return this.networksOverview.filter(item => item.layer === "L2");
		},
	},
    watch: {
        chainLayer(val, oldVal) {
            if(val != oldVal) {
				this.fetchTokens();
                this.onSearch();
            }
        },
        symbol(val, oldVal) {
            if(val != oldVal && oldVal != '') {
                this.onSearch();
            }
        },
        txType(val, oldVal) {
            if(val != oldVal && oldVal != '') {
                this.onSearch();
            }
        }
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			const { chainLayer, symbol } = this.$route.query;
			if (chainLayer) {
				this.chainLayer = chainLayer;
			} else {
				// 设置为钱包网络
				this.chainLayer = this.$store.state.wallet.walletChainId+ '-2';;
			}
			this.symbol = symbol;
			this.setFilter();

			// await this.fetchTokens();
			// this.fetchHistory();
		},
		isFromAccount(str) {
			return ('0x'+ str).toLowerCase() === this.account.toLocaleLowerCase();
		},
		setFilter() {
			this.time = [];
			this.time.push(dayjs().subtract(30, "days").format("YYYY-MM-DD"));
			this.time.push(dayjs().format("YYYY-MM-DD"));
			this.symbol = "All";
			this.txType = "All";
		},

		async fetchTokens() {
			const tokens = await this.$store.dispatch(
				"getLayer2TokenList",
				this.chainId
			).catch(e => []);
			// 前端过滤出对应币种。
			const symbols = futuresConfig.symbols;
			this.tokens = [
				{
					symbol: this.$t("wallet_common_all"), // All
				},
				...tokens.filter((item) => symbols.includes(item.symbol)),
			];
		},

        onChangeDate(val) {
            this.time = val;
            console.log('this.time:', this.time)
        },
		onReset() {
			this.setFilter();
		},
		onSearch() {
			this.pages.pageNo = 1;
			this.fetchHistory('reset');
		},
		onChangePages(page) {
			this.pages.pageNo = page;
			this.fetchHistory('reset');
		},
		async fetchHistory(reset) {
			if (!this.layer2Config) return;

			const restUrl = this.layer2Config.rest;
			const startTime =
				this.time.length > 0 ? getUTCTime(new Date(this.time[0])) : null;
			const endTime =
				this.time.length > 0 ? getUTCTime(new Date(this.time[1])) + 24 * 60 * 60 * 1000 : null;
			const offset = (this.pages.pageNo - 1) * this.pages.pageSize;
            const perpetualAccount = await this.$store.dispatch('getPerpetualAccount', this.chainId)
			console.log('perpetualAccount:', perpetualAccount, startTime,  endTime)
            const params = {
				url: restUrl,
				account: perpetualAccount && perpetualAccount.create2Address,
				data: {
					from: "latest",
					txType: this.txType === 'All' ? null : this.txType,
					token: this.symbol === 'All' ? null : this.symbol,
					offset: offset,
					limit: 20,
					createdStartAt: startTime, // 13位时间戳
					createdEndAt: endTime, // 13位时间戳
					direction: "older", // older/newer TODO：后台逻辑不对，统一的，不好改，前端临时改。
				},
			};
            this.historyList = [];
            if (!params.account) return
            if (this.loadingHistory) return
			this.loadingHistory = true;
			await fetchPerpetualHistory(params)
				.then((res) => {
					this.historyList = res.result.list;
                    this.pages.total = res.result.pagination.count;
				})
				.catch((e) => {
                    // console.log('e:', e)
                    this.loadingHistory = false
                });
			this.loadingHistory = false;
		},
		formatUTCTime(date) {
			return formatUTCTime(date);
		},
		formatToken(tokenId) {
			const token = this.tokenIdMap[tokenId];
			return token.symbol;
		},
		formatAmount(value, tokenId) {
			const token = this.tokenIdMap[tokenId];
			return commifyAndFloor(value, token.decimals);
		},
		formatUnits(value, tokenId) {
			const token = this.tokenIdMap[tokenId];
			return utils.formatUnits(value, token.decimals);
		},
        showInfo(item, index, column) {
            item.information = !item.information
            this.$set(this.historyList, index, item)
        },
        goExplorer(txHash) {
            let url = getHashBlockUrl(txHash, this.chainId, 'L2', this.$store)
            // console.log('url:', url)
            window.open(url, 'future-explorer')
        }
	},
};
