/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2021-03-05 17:07:24
 * @LastEditors: Lewis
 * @LastEditTime: 2022-07-04 09:37:16
 */
import { axios } from '@/utils/Http/axios';
import * as axiosManuel from 'axios'
// const baseUrl = 'exchange'
const baseUrl = 'trades'
let Base64 = require('js-base64').Base64
const perpetualBaseUrl = 'perpetual/trades'

export const getOpenOrders = data => {
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/open-orders`,
        method: 'get',
        params: data,
    })
}

export const getOrerHistory = data => {
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/order-history`,
        method: 'get',
        params: data,
    })
}

export const getTradeHistory = data => {
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/trade-history`,
        method: 'get',
        params: data,
    })
}


export const getOrderDetail = data => {
    let orderId = data.id
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/${orderId}`,
        method: 'get'
    })
}

export const cancelAll = data => {
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders?symbol=all`,
        method: 'delete',
        // params: data,
        headers: {
            signature: data.sign && Base64.encode(JSON.stringify({
                tx: data.message,
                signature: data.sign
            })) || ''
        }
    })
}

export const cancelOrder = data => {
    let orderId = data.orderId
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/${orderId}`,
        method: 'delete',
        params: data,
        headers: {
            signature: data.sign && Base64.encode(JSON.stringify({
                tx: data.message,
                signature: data.sign
            })) || ''
        }
    })
}


// trade --- transfer

export const getCoinDetails = data => {
    let coin_name = data.coin_name
    delete data.coin_name
    return axios.request({
        url: `/wallet/v1.0/asset/spot/${coin_name}`,
        method: 'get',
        params: data,
    })
}
// gdye

export const rigidPayment = data => {
    let coin_name = data.coin_name
    delete data.coin_name
    return axios.request({
        url: `/wallet/v1.0/asset/spot/rigid-payment/${coin_name}`,
        method: 'post',
        data
    })
}
export const getBlocksOverview = data => {
    return axios.request({
        // url: `/trade-mining/v1.0/blocks/overview`,
        url: `http://xapi.chainscan.powx.io/blocks/overview`,
        method: 'get',
        params: data,
    })  
}

export const getBlocks = data => {
    return axios.request({
        url: `/trade-mining/v1.0/blocks`,
        method: 'get',
        params: data,
    })  
}

export const getBlocksDetail = data => {
    let dividend_id = data.dividend_id
    delete data.dividend_id
    return axios.request({
        url: `/trade-mining/v1.0/blocks/${dividend_id} `,
        method: 'get',
        params: data,
    })
}

export const breakdown = params => {
    return axios.request({
        url: `${baseUrl}/v1.0/breakdown`,
        method: 'get',
        params
    })
} 

export const getTradeMiningChart = data => {
    return axios.request({
        // url: `/trade-mining/v1.0/trade-mining`,
        url: `/trade-mining/v1.0/price-trend`,
        method: 'get'
    })
}

export const getTradeMining = data => {
    return axios.request({
        url: `/trade-mining/v1.0/me/trade-mining-revenues`,
        method: 'get',
        params: data
    })
}

export const getTradeGain = data => {
    return axios.request({
        url: `/wallet/v1.0/me/gain-changes`,
        method: 'get',
        params: data,
    })
}

export const getDividendView = data => {
    return axios.request({
        url: `/wallet/v1.0/asset/spot/dividend/overview`,
        method: 'get'
    })
}

export const convert = data => {
    let fromCoin_toCoin = data.fromCoin_toCoin
    delete data.fromCoin_toCoin
    return axios.request({
        url: `/wallet/v1.0/asset/spot/convert/${fromCoin_toCoin}`,
        method: 'post',
        data
    })
}

export const buyAndSellOrderDex = data => {
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/prepare`,
        method: 'post',
        data
    })
}

export const buyAndSellOrder = data => {
    // let sign = data.sign && Base64.encode(JSON.stringify(data.sign)) || ''
    // delete data.sign
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders`,
        method: 'post',
        data
        // headers: {
        //     signature: sign
        // }
    })
}

export const confirmOrder = data => {
    let order_id = data.order_id
    delete data.order_id
    return axios.request({
        url: `${baseUrl}/v1.0/spot/orders/${order_id}/commit`,
        method: 'post',
        data,
        headers: {
            signature: data.sign && Base64.encode(data.sign) || ''
        }
    })
}

export const getSignleSymbolPrice = data => {
	return axios.request({
		url: `${site_config.site_common_market_api_url}/data/price`,
		method: "get",
		params: data,
		unToken: true
	})
}

// getSymbolPrice
export const getMultifullSymbolPrice = params => {
    let queryParams = `fsyms=${params.from}`;
    let queryUrl = window.site_config['site_common_market_api_url']
    queryUrl = queryUrl.replace(/(^\s*)|(\s*$)/g, '')
    if (queryUrl.endsWith('/')) {
        queryUrl = queryUrl.slice(0, queryUrl.length - 1)
    }
    let url = `${queryUrl}/data/pricemultifull?${queryParams}`
    return axiosManuel.get(`${url}`)
}

// 已经取消逻辑。
export const getWalletAddress = data => {
    // trade 
    return axios.request({
        url: `wallet/v1.0/wallets/transfer-address`,
        // url: 'exchange/v1.0/dex/spot/orders/transfer-address',
        method: 'get',
        params: Object.assign({
            transfer_wallet_type: 2
        }, data)
    })
}

// 以下2个方法弃用，合并成一个，后一个(参考：buyAndSellOrder方法)。
export const swapConfirmOrder = data => {
    let order_id = data.order_id
    delete data.order_id
    return axios.request({
        url: `${baseUrl}/v1.0/spot/swap/orders/${order_id}/commit`,
        method: 'post',
        // data,
        headers: {
            signature: data.sign && Base64.encode(data.sign) || ''
        }
    })
}
export const swap = data => {
    let sign = data.sign && Base64.encode(JSON.stringify(data.sign)) || ''
    return axios.request({
        url: `${baseUrl}/v1.0/spot/swap/orders`,
        method: 'post',
        data,
        headers: {
            signature: sign // 放入body
        }
    })
}

/** perpetual  **/
export const perpetualOrder = {
    getOrderDetail: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders/${data.id}`,
            method: 'get'
        })
    },
    getOrderMatch: orderId => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders/${orderId}/match-results`,
            method: 'get'
        })        
    },
    // symbol, type [market_price|limit_price|all], direction [sell|buy|all], page_no, page_size, hide_all_canceled [true|false]
    getOrderHistory: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders/order-history`,
            method: 'get',
            params: data
        })
    },
    getOpenOrder: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders/open-orders`,
            method: 'get',
            params: data
        })        
    },
    getOrderList: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders`,
            method: 'get',
            params: data
        })
    },
    getTradeHistory: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders/trade-history`,
            method: 'get',
            params: data
        })
    },
    placeOrder: data => {
        return axios.request({
            url: `${perpetualBaseUrl}/v1.0/orders`,
            method: 'post',
            data
        })
    },
    getPositionList: data => {
        // let account_id = data.account_id
        // let layer2Url = data.layer2Url 
        // delete data.account_id
        // delete data.layer2Url
        return axios.request({
            // url: `${layer2Url}/perpetual/${account_id}/positions`,
            url: `perpetual/trades/v1.0/positions`,
            method: 'get',
            params: data
        })
    }
}