//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFromcoins, getTocoins, getSymbols } from "../../api/market/Index";
import { getAuctionOrderList } from "../../../marketplace-submodule/api";
import { ethers } from "ethers";
import {
    getOpenOrders,
    getOrerHistory,
    cancelAll,
    cancelOrder,
    perpetualOrder
} from "../../api/trade";
import {
    emitLoading,
    isUserCenter,
    getUTCTime,
    getOrderCancelledKey,
    getChainLayer2Config,
    formatStringNum,
    getPerpetualSupportChains
} from "../../utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import BNP from "bignumber.js";
dayjs.extend(utc);

function formatWei(num, decimal) {
    if (!num || !decimal) {
        return 0;
    }
    const numStr = String(num);
    return ethers.utils.formatUnits(numStr, decimal);
}
const getNetworkComposedList = (allNetworkName) => {
    let layer1 = window.site_config["layer1_list"];
    let layer2 = window.site_config["layer2_list"];
    let wallet_type =
        (window.site_config && window.site_config.wallet_type) || "";
    let list = [
        {
            value: "All",
            label: allNetworkName,
            chainId: "",
            chainName: allNetworkName,
        },
    ];
    try {
        const layer1Obj = JSON.parse(layer1);
        const layer2Obj = JSON.parse(layer2);
        const layer1List = layer1Obj.map((item) => {
            const obj = (item && Object.values(item)[0]) || {};
            return {
                value: `${obj.chainId}-1`,
                label: obj.chainName,
                chainId: obj.chainId,
                chainName: obj.chainName,
                layer: 1,
                chainLayer: obj.chainId + "-L1",
            };
        });
        const layer2List = layer2Obj.map((item) => {
            const obj = (item && Object.values(item)[0]) || {};
            return {
                value: `${obj.chainId}-2`,
                label: obj.chainName + "-L2",
                chainId: obj.chainId,
                chainName: obj.chainName + "-L2",
                layer: 2,
                chainLayer: obj.chainId + "-L2",
                rpcUrl: obj.rest,
            };
        });
        if (wallet_type == "L1") {
            list = list.concat(layer1List);
        } else {
            list = list.concat(layer1List, layer2List);
        }
    } catch (error) {}
    list.sort((a, b) => a.chainId - b.chainId);
    // console.log('list:', list);
    return list;
};
function calcTime(auctionEnd) {
    if (!auctionEnd) {
        return;
    }
    const timeStamp = Number(auctionEnd);
    const now = dayjs().unix();
    if (timeStamp <= now) {
        return "--";
    }
    const timeDiff = timeStamp - now;
    let dayDiff = Math.floor(timeDiff / (24 * 3600)); //计算出相差天数
    let leave1 = timeDiff % (24 * 3600); //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / 3600); //计算出小时数
    //计算相差分钟数
    let leave2 = leave1 % 3600; //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / 60); //计算相差分钟数
    //计算相差秒数
    let leave3 = leave2 % 60; //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3);
    console.log(`${dayDiff}D:${hours}H:${minutes}M`);
    return `${dayDiff}D:${hours}H:${minutes}M`;
    // this.days = dayDiff;
    // this.hours = hours;
    // this.minutes = minutes;
    // this.seconds = seconds;
}
let addZore = function (num) {
    return num < 10 ? "0" + num : num;
};
function getStatus(statu) {
    if (!statu) {
        return "--";
    }
    const STATUS_MAP = {
        1: "Selling",
        2: "Complete",
        3: "Canceled",
        4: "Expired",
        4: "Failed",
    };
    return STATUS_MAP[statu] || "--";
}
// import { h } from "vue"
// const h = this.$createElement;
export default {
    name: "trade-history",
    beforeRouteUpdate(val) {
        let routeType = val.query.type;
        let tradeMode = this.tradeMode
        this.model = tradeMode == 'perpetual' ? 0 : (routeType == "orders" ? 1 : 2)
        if (this.uid) {
            this.init();
        }
    },
    data() {
        return {
            tabId: "spot",
            navList: [{ name: this.$t("wallet_common_spot"), path: "" }],
            model: 1,
            orderStatus: "All",
            orderStatusList: [
                "All",
                "Limit Order",
                "Swap Order",
                "Market Order",
            ],
            orderList: [],
            historyList: [],
            pages: 0,
            value2: [],
            userCenter: isUserCenter(),
            col: [
                {
                    title: this.$t("trade_trade_order_date"),
                    key: "time",
                    width: 230,
                    customRender:(text, record) => {
                        let  h = this.$createElement
                        return h('span', {}, record.time)
                    }
                },
                {
                    title: this.$t("trade_trade_order_pair"),
                    key: "symbol",
                    dataIndex: 'symbol_display_name'
                },
                {
                    title: this.$t("trade_trade_order_type"),
                    key: "type",
                    customRender:(text, record) => {
                        let  h = this.$createElement
                        let resText = record.mode == 'SWAP' ? record.mode : record.direction + " " + record.type.split("_")[0];
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t("trade_trade_order_price"),
                    key: "price",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {}, record.price + " " + record.base_symbol)
                    }
                },
                {
                    title: this.$t("trade_trade_order_amount"),
                    key: "amount",
                    dataIndex: "amount"
                },
                {
                    title: this.$t("trade_trade_order_executed"),
                    key: "traded_amount",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = (record.traded_amount.replace(/\,/g, "") / record.amount.replace(/\,/g, "") * 100).toFixed(2) + "%"
                        return h('span', {}, resVal)
                    }
                },
                {
                    title: this.$t('trade_trade_order_total'),
                    key: "traded_total",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = record.turnover + ' ' + record.base_symbol
                        return h('span', {}, resVal)
                    }
                },
                {
                    title: this.$t("trade_trade_order_action"),
                    key: "action",
                    align: "right",
                    customRender: (text, record, index) => {
                        let resVal = this.$t("trade_history_order_cancel")
                        let that = this
                        let h = this.$createElement
                        return h('span', {
                                style: {
                                    color: "#EA5455",
                                    "font-size": "16px",
                                    "font-family": "Font",
                                    "font-weight": 400,
                                    "text-align": "left",
                                    color: "#ea5455",
                                    cursor: "pointer"
                                },
                                on: {
                                    click: () => {
                                        that.cancelOrder(record, index);
                                    },
                                }
                        }, resVal)
                    }
                },
            ],
            col_history: [
                {
                    title: this.$t("trade_trade_order_date"),
                    key: "time",
                    width: 230,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {
                                style: {
                                    "white-space": "nowrap",
                                }
                        }, record.time)
                    }
                },
                {
                    title: this.$t("trade_trade_order_pair"),
                    key: "symbol",
                    dataIndex: "symbol_display_name"
                },
                {
                    title: this.$t("trade_trade_order_side"),
                    key: "side",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resText = record.mode == "SWAP" ? record.mode : record.direction + " " + record.type.split("_")[0];
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t("trade_trade_order_price"),
                    key: "price",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {}, record.price + " " + record.base_symbol)
                    }
                },
                {
                    title: this.$t("trade_trade_order_executed"),
                    key: "traded_amount",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = (record.traded_amount.replace(/\,/g, "") / record.amount.replace(/\,/g, "") *100).toFixed(2) + "%"
                        return h('span', {}, resVal)
                    }
                },
                {
                    title: this.$t("trade_trade_order_fee"),
                    key: "total_fee",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = (record.total_fee || "") + " " + (record.total_fee_unit || "")
                        return h('span', {}, resVal)
                    }
                },
                {
                    title: this.$t("trade_trade_order_total"),
                    key: "tatal",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = record.turnover + " " + record.base_symbol
                        return h('span', {}, resVal)
                    }
                },
                {
                    title: this.$t("trade_trade_order_status"),
                    key: "status",
                    align: "right",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {}, [
                                h(
                                    "div",
                                    {
                                        style: {
                                            "white-space": "nowrap",
                                        },
                                    },
                                    `${record.status}`
                                ),
                                h(
                                    "div",
                                    {
                                        style: {
                                            "white-space": "nowrap",
                                            "margin-top": "10px",
                                            // "line-height": 1,
                                        },
                                    },
                                    record.cancelledOrder ? "(" + record.cancelledOrder + ")" : ""
                                )
                            ])
                    }
                }
            ],
            col_perpetual_history: [
                {
                    title: this.$t("trade_trade_order_date"),
                    key: "time",
                    width: 230,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let resVal = record.time
                        return h('span', {
                                style: {
                                    "white-space": "nowrap",
                                }
                            },
                            resVal)
                    }
                },
                {
                    title: this.$t("trade_trade_order_pair"),
                    key: "symbol",
                    dataIndex: "symbol"
                },
                {
                    title: this.$t("trade_trade_order_side"),
                    key: "side",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {
                            style: {
                                color: record.direction == 'BUY' && 'rgba(19, 179, 107, 1)' || 'rgba(234, 84, 85, 1)'
                            }
                        }, this.lowerCaseVal(record.perpetual_type) + ' ' + this.lowerCaseVal(record.direction))
                    }
                },
                {
                    title: this.$t('trade_perpetual_average'),
                    key: "average",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h('span', {}, record.avg_price)
                    }
                },
                {
                    title: this.$t("trade_trade_order_price"),
                    key: "price",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        return h('span', {}, record.price + " " + record.base_symbol)
                    }
                },
                {
                    title: this.$t("trade_trade_order_executed"),
                    key: "traded_amount",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.traded_amount * 1 && (record.traded_amount / record.amount * 100 + '%')
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t("trade_trade_order_amount"),
                    key: "amount",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.traded_amount  + ' ' + record.coin_symbol
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t("trade_trade_order_total"),
                    key: "total",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.turnover  + ' ' + record.base_symbol
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t("trade_trade_order_status"),
                    key: "status",
                    align: "right",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        return h('span', {},
                            [
                                h(
                                    "div",
                                    {
                                        style: {
                                            "white-space": "nowrap",
                                            // "line-height": 1,
                                        },
                                    },
                                    `${record.status}`
                                ),
                                record.cancelledOrder && h(
                                    "div",
                                    {
                                        style: {
                                            "white-space": "nowrap",
                                            "margin-top": "10px",
                                            // "line-height": 1,
                                        },
                                    },
                                    record.cancelledOrder ? "(" + record.cancelledOrder + ")" : ""
                                )
                            ]
                        )
                    }
                }
            ],
            col_position: [
                {
                    title: this.$t('trade_trade_order_symbol'),
                    key: "symbol",
                    dataIndex: "symbol"
                },
                {
                    title: this.$t('trade_perpetual_market_size'),
                    key: "size",
                    dataIndex: 'size'
                },
                {
                    title: this.$t('trade_perpetual_entry_price'),
                    key: "avgPrice",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = formatStringNum(record.avg_price, 2)
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t('trade_perpetual_mark_price'),
                    key: "markPrice",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.markPrice || '---'
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t('trade_perpetual_liq_price'),
                    key: "liqPrice",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = formatStringNum(record.liq_price + '', 2) || '---'
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t('trade_perpetual_margin_ratio'),
                    key: "marginRatio",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.marketRatio + '%'
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t('trade_perpetual_margin'),
                    key: "margin",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = formatStringNum(record.margin, 2) || '---'
                        return h('span', {}, resText)
                    }
                },
                {
                    title: this.$t('trade_perpetual_pnl_roe'),
                    key: "pln",
                    width: 200,
                    align: "right",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = record.pnl ? (formatStringNum(record.pnl, 2) + ' ' + record.base_symbol) : '---'
                        let pnlRate = `(${(record.pnlRate * 100).toFixed(2)}%)`
                        return h('span', {
                            style: {
                                whiteSpace: 'break-spaces',
                                // textAlign: 'right'
                            }
                        }, resText + '\n' + pnlRate)
                    }
                },
            ],
            searchInfo: {
                time: "",
                type: "",
                pair: "All",
                pair2: "",
                side: "All",
            },
            SideList: [
                {
                    value: "All",
                    label: "All",
                },
                {
                    value: "Swap",
                    label: "Swap",
                },
                {
                    value: "Buy limit",
                    label: "Buy limit",
                },
                {
                    value: "Sell limit",
                    label: "Sell limit",
                },
            ],
            SidePerpetualList: [
                {
                    value: "All",
                    label: "All",
                },
                {
                    value: "Buy",
                    label: "Buy/Long",
                },
                {
                    value: "Sell",
                    label: "Sell/Long",
                },
            ],
            list: [],
            list1: [],
            coinList: [],
            order_page_no: 0,
            history_page_no: 0,
            order_pages: 0,
            history_pages: 0,
            request: false,
            loading: false,



            chainId: "0",
            chainInitId: "",
            networkList: [],
            nftModel: 1,
            nftNetworkList: [],
            NFTChainId: "All",
            NFTorderStatus: "All",
            NFTorderRole: "All",
            NFTorderType: "All",
            NFTsearchDate: null,
            nft_page_no: 1,
            nft_page_size: 5,
            nftLoading:true,
            NFTTotal:0,
            NFTorderStatusList: [
                "All",
                "Complete",
                "Expired",
                "Failed",
                "Canceled"
            ],
            NFTorderTypeList: ["All", "Fixed price", "Time auction"],
            NFTorderRoleList: ["All", "Buyer",'Seller'],
            NFThistoryList: [],
            NFTColumns: [
                {
                    title: "Date",
                    key: "time",
                    width: 230,
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = dayjs.utc(record.time).local().format("YYYY-MM-DD HH:mm:ss")
                        return h('span', {
                            style: {
                                "white-space": "nowrap",
                            }
                        }, resText)
                    }
                },
                {
                    title: "NFT",
                    key: "nft_data",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let that = this
                        return h('img', {
                            attrs: {
                                src: record.nft_data.image,
                                style: "width: 100px;border-radius: 2px;height:100px;margin:10px 0px;cursor:pointer;", //图片在表格中的样式
                            },
                                on: {
                                click: function () {
                                    that.goDetail(record);
                                }
                            }
                        })
                    }
                },
                {
                    title: "Network",
                    key: "network",
                    dataIndex: 'network'
                },
                {
                    title: "Type",
                    key: "type",
                    dataIndex: 'type'
                },
                {
                    title: "Price",
                    key: "price",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        return h('span', {}, formatWei(record.price, 18) + "DTX")
                    }
                },
                {
                    title: "Role",
                    key: "role",
                    dataIndex: "role"
                },
                {
                    title: "Expiration",
                    key: "expiration",
                    dataIndex: "expiration"
                }
            ],
            NFTOpenColumns: [
                {
                    title: "Date",
                    key: "time",
                    width: 230,
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = dayjs.utc(record.time).local().format("YYYY-MM-DD HH:mm:ss")
                        return h('span', {
                            style: {
                                "white-space": "nowrap",
                            }
                        }, resText)
                    }
                },
                {
                    title: "NFT",
                    key: "nft_data",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let that = this
                        return h('img', {
                            attrs: {
                                src: record.nft_data.image,
                                style: "width: 100px;border-radius: 2px;height:100px;margin:10px 0px;cursor:pointer;", //图片在表格中的样式
                            },
                                on: {
                                click: function () {
                                    that.goDetail(record);
                                }
                            }
                        })
                    }
                },
                {
                    title: "Network",
                    key: "network",
                    dataIndex: "network"
                },
                {
                    title: "Type",
                    key: "type",
                    dataIndex: "type"
                },
                {
                    title: "Price",
                    key: "price",
                    dataIndex: "price",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        return h('span', {}, formatWei(record.price, 18) + "DTX")
                    }
                },
                {
                    title: "Role",
                    key: "role",
                    dataIndex: "role"
                },
                {
                    title: "Expiration",
                    key: "expiration",
                    dataIndex: "expiration"
                }
            ],
            NFThistoryColumns: [
                {
                    title: "Date",
                    key: "time",
                    width: 230,
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        let resText = dayjs.utc(record.time).local().format("YYYY-MM-DD HH:mm:ss")
                        return h('span', {
                            style: {
                                "white-space": "nowrap",
                            }
                        }, resText)
                    }
                },
                {
                    title: "NFT",
                    key: "nft_data",
                    customRender: (text, record) => {
                        let that = this
                        let  h = this.$createElement
                        return h('img', {
                            attrs: {
                                src: record.nft_data.image,
                                style: "width: 100px;border-radius: 2px;height:100px;margin:10px 0px;cursor:pointer;", //图片在表格中的样式
                            },
                                on: {
                                click: function () {
                                    that.goDetail(record);
                                }
                            }
                        })
                    }
                },
                {
                    title: "Network",
                    key: "network",
                    dataIndex: "network"
                },
                {
                    title: "Type",
                    key: "type",
                    dataIndex: "type"
                },
                {
                    title: "Price",
                    key: "price",
                    customRender: (text, record) => {
                        let  h = this.$createElement
                        return h('span', {}, formatWei(record.price, 18) + "DTX")
                    }
                },
                {
                    title: "Role",
                    key: "role",
                    dataIndex: "role"
                },
                {
                    title: "Expiration",
                    key: "expiration",
                    dataIndex: "texpirationype"
                },
                {
                    title: "Status",
                    key: "state",
                    render: (h, params) => {
                        return h("span", {}, params.row.state);
                    }
                }
            ],


            // 永续
            positionLoading: false,
            positionList: [],
            positionList_pages: 0,
            positionList_page_no: 0,
            marketPriceMap: {},
            markPriceMap: {},
            marginMap: {
                50000: {maxLeverage: '125x', marginRate: '0.004'},
                250000: {maxLeverage: '100x', marginRate: '0.005'},
                1000000: {maxLeverage: '50x', marginRate: '0.01'},
                10000000: {maxLeverage: '20x', marginRate: '0.025'},
                20000000: {maxLeverage: '10x', marginRate: '0.05'},
                50000000: {maxLeverage: '5x', marginRate: '0.1'},
                100000000: {maxLeverage: '4x', marginRate: '0.125'},
                200000000: {maxLeverage: '3x', marginRate: '0.15'},
                300000000: {maxLeverage: '2x', marginRate: '0.25'},
                max: {maxLeverage: '1x', marginRate: '0.50'}
            },
            supportChains: []
        };
    },
    computed: {
        orderqueryFilterList() {
            return this.orderStatusList.filter( v => {
                return this.tabId === 'perpetual' && v != 'Swap Order' || this.tabId !== 'perpetual'
            })
        },
        layer1NetworkList() {
            return this.$store.getters.layer1NetworkList;
        },
        uid() {
            let userInfo = this.$store.state.user.userInfo;
            let wallet = this.$store.state.wallet.wallet || {};
            return userInfo && userInfo.id;
        },
        supportNFT() {
            return this.$store.getters.supportNFT;
        },
        marketSupportPerpetual() {
            return this.$store.state.siteConfig.marketSupportPerpetual
            // return false
        },
        tradeMode() {
            return this.$store.state.trade.tradeMode
        },
        isPerpetual() {
            return this.$store.state.trade.tradeMode == 'perpetual'
        },
        showNetworkList() {
            return this.networkList.filter(v => this.supportChains.includes(v.chainId) && v.chainId != '0')
        },
        account() {
			return this.$store.getters.account || '';
		},
    },
    filters: {},
    mounted() {
        let routeType = this.$route.query.type;
        let tradeMode = this.tradeMode
        this.model = tradeMode == 'perpetual' ? 0 : (routeType == "orders" ? 1 : 2)
        if (this.uid) {
            this.init();
        }
    },
    watch: {
        uid(val) {
            if (val) {
                this.init();
            }
        },
        "searchInfo.pair"(val, oldval) {
            if (val != oldval) {
                this.getTocoins(val);
            }
        },
        orderStatus(val, oldval) {
            if (val != oldval) {
                this.getOpenOrdersFunc();
            }
        },
        model(val) {
            this.searchInfo.pair = "All";
            this.searchInfo.pair2 = "";
            if(val==1){
                this.getFromcoins
                this.getOpenOrdersFunc();
            }
            if(val==2){
                this.queryHistory();
            }
        },
        chainId(val, oldVal) {
            // 更改处理逻辑
            if (val != oldVal) {
                let tabId = this.tabId
                switch (tabId) {
                    case 'spot':
                        this.pageChange(0)
                        break;
                    case 'perpetual':
                        this.pageChange(0)
                        break;
                    case 'nft':
                        this.nftPageChange(0)
                        break;
                    default:
                        break;
                }
            }
        },
        NFTChainId(val, oldVal) {
            if (val != oldVal) {
                this.getAuctionOrderList();
            }
        },
        nftModel(val, oldVal) {
            if (val != oldVal) {
                this.getAuctionOrderList();
            }
            if(val==1){
                this.NFTColumns=this.NFTOpenColumns;
            }
            if(val==2){
                this.NFTColumns = this.NFThistoryColumns;
            }
        },
        NFTorderType(val, oldVal) {
            if (val != oldVal) {
                this.getAuctionOrderList();
            }
        },
        NFTorderStatus(val, oldVal) {
            if (val != oldVal) {
                this.getAuctionOrderList();
            }
        },
        NFTorderRole(val, oldVal) {
            if (val != oldVal) {
                this.getAuctionOrderList();
            }
        },
        tradeMode(val, oldVal) {
            if(val == 'perpetual') {
                this.model = 0
                this.tabId = 'perpetual'
                this.setPerpetualChainId('perpetual')
            } else {
                let routeType = this.$route.query.type;
                this.model = routeType == "orders" ? 1 : 2
                this.chainId = '0'
            }
        },
        isMultiChain() {
            return Boolean(this.$store.state.trade.isMultiChains * 1)
        }
    },
    methods: {
        init() {
			let { tradeMode, chainId } = this.$route.params;
			tradeMode = tradeMode || "spot";
			this.$store.commit("trade/setTradeMode", tradeMode); // 交易类型
            if(tradeMode == 'perpetual') {
                this.model = 0
                this.tabId = 'perpetual'
                this.setPerpetualChainId('perpetual')
            }
            this.initFunc()
        },
        initFunc() {
            let tradeMode = this.tradeMode
            this.setDefaultTime();
            this.getFromcoins();
            this.queryHistory();
            this.getOpenOrdersFunc();
            this.setChainArrLists();
            this.setNftNetworkList();
            this.setDefaultNFTSearchTime();
            this.getAuctionOrderList();
            if (tradeMode == 'perpetual' && this.chainId != '0') {
                this.getPositionList()
            }
        },
        changeTab(key) {
            this.tabId = key;
            if (key == 'perpetual') {
                this.model == 0
                this.$store.commit("trade/setTradeMode", 'perpetual'); // 交易类型
                this.initFunc()
            } else if( key === "spot"){
                this.$store.commit("trade/setTradeMode", 'spot'); // 交易类型
                let routeType = this.$route.query.type;
                let tradeMode = this.tradeMode
                this.model = routeType == "orders" ? 1 : 2
                this.initFunc()
            } else {
                this.getAuctionOrderList();
            }
        },
        getNFTsearchType(str) {
            if (str === "All") {
                return null;
            }
            if (str === "Fixed price") {
                return "fixedPrice";
            }
            if (str === "Time auction") {
                return "bid";
            }
            return null;
        },
        getNFTsearchRole(str) {
            if (str === "All") {
                return null;
            }
            if (str === "Buyer") {
                return "Buyer";
            }
            if (str === "Seller") {
                return "Seller";
            }
            return null;
        },
        getNFTsearchState(str) {
            const STATE_MAP = {
                All: 'history',
                Selling: 1,
                Complete: 2,
                Canceled: 3,
                Expired: 4,
                Failed: 5,
            };
            return STATE_MAP[str] || null;
        },
        changeNFTModel(num) {
            this.nftModel = num;
            this.initNFTParams();
        },
        initNFTParams() {
            this.NFTChainId = "All";
            this.NFTorderStatus = "All";
            this.NFTorderType = "All";
            this.NFTorderRole = "All";
            this.nft_page_no = 1;
            this.setDefaultNFTSearchTime();
        },
        setDefaultNFTSearchTime() {
            this.NFTsearchDate = [];
            this.NFTsearchDate.push(
                dayjs().subtract(30, "days").format("YYYY-MM-DD")
            );
            this.NFTsearchDate.push(dayjs().format("YYYY-MM-DD"));
        },
        nftPageChange(val){
            this.nft_page_no = val;
            this.getAuctionOrderList();
        },
        goDetail(row){
            const { id, layer, chain_id } = row;
            if(!id){
                return;
            }
            let url = `/nft/detail?id=${id}&chainId=${chain_id}&layer=${layer}`;
            this.$router.push(url)
        },
        async getAuctionOrderList() {
            this.nftLoading = true;
            const account = this.account;//this.walletData.getAddress();
            const [start_date,end_date] = this.NFTsearchDate;
            const [chainId, layer] =
                this.NFTChainId === "All"
                    ? [null, null]
                    : this.NFTChainId.split("-");
            const params = {
                page_no: this.nft_page_no,
                page_size: this.nft_page_size,
                account,
                type: this.getNFTsearchType(this.NFTorderType),
                role:this.getNFTsearchRole(this.NFTorderRole),
                sort: null,
                start_date,
                end_date,
                search: "",
                state:
                    this.nftModel == 1
                        ? 1
                        : this.getNFTsearchState(this.NFTorderStatus),
                mode: "",
                layer: layer ? `L${layer}` : null,
            };
            const { code, list, total } = await getAuctionOrderList(params).catch(err=>{
                this.nftLoading = false;
                this.$Message.error(err.message||'get order list failed')
            });
            if(code!==200){
                return
            }
            this.NFTTotal = total;
            this.NFThistoryList = list.map((item) => {
                let nft_data = null;
                if (item.nft_data.startsWith("data:application/json")) {
                    try {
                        nft_data = JSON.parse(
                            atob(
                                item.nft_data.replace(
                                    "data:application/json;base64,",
                                    ""
                                )
                            )
                        );
                    } catch (error) {
                        nft_data = {
                            nft_data: {
                                levels: [],
                                stats: [],
                            },
                        };
                    }
                } else {
                    nft_data = JSON.parse(item.nft_data);
                }
                let role;
                if (
                    item.nft_buyer &&
                    item.nft_buyer.toLowerCase() === account.toLowerCase()
                ) {
                    role = "Buyer";
                }
                if (
                    item.account_address &&
                    item.account_address.toLowerCase() === account.toLowerCase()
                ) {
                    role = "Seller";
                }
                return {
                    time: item.create_time,
                    nft_data,
                    nft_name: item.nft_name,
                    price:
                        item.buy_now_price ||
                        item.nft_highest_bid ||
                        item.min_price,
                    state: getStatus(item.state),
                    network: item.layer === "L1" ? "DTX" : `DTX-${item.layer}`,
                    type: item.buy_now_price ? "Fixed price" : "Time auction",
                    expiration:
                        item.state == 1 ? calcTime(item.auction_end) : "--",
                    role,
                    layer:item.layer,
                    id:item.id,
                };
            });
            this.nftLoading = false;
        },
        NFTDateChange(e){
            console.log('NFTDateChange');
            this.NFTsearchDate = e;
            this.getAuctionOrderList();
        },
        async setChainArrLists() {
            this.networkList = []
            if (!this.layer1NetworkList.length) {
                await this.$store.dispatch('fetchNetworkList')
            }
            let networks = window.site_config['networks'] || "[]"
            let initAllWorkSet = [{
                chainId: '0',
                chainName: this.$t('wallet_common_all_networks')
            }]
            let networksOpt = JSON.parse(networks).map(v => {
                let network = this.layer1NetworkList.find(item => item.chainId == v.chainId)
                v.chainName = network.name
                return {
                    chainId: v.chainId,
                    chainName: network.name
                }
            })
            this.networkList = initAllWorkSet.concat(networksOpt)
        },
        setNftNetworkList() {
            this.nftNetworkList = getNetworkComposedList(
                this.$t("wallet_common_all_networks")
            ).filter(
                (item) =>
                    item.chainId == 97 || item.chainName === "All - Networks"
            );
            console.log("setNftNetworkList");
            console.log(this.nftNetworkList);
        },
        setPerpetualChainId(type) {
            if (type == 'perpetual') {
                let { tradeMode, chainId } = this.$route.params;
                let supportChains = getPerpetualSupportChains() || []
                let configChainId = supportChains.find(v => v != '0')
                this.chainId = chainId || configChainId && configChainId || '0'
                this.supportChains = supportChains
            }
        },
        changeModel(type) {
            if (this.model == 0 && type != 0) {
                this.chainId = '0'
                this.model = type;
            } else if (type == 0){
                this.model = type
                this.setPerpetualChainId('perpetual')
            } else {
                this.model = type
            }
        },
        setDefaultTime() {
            this.searchInfo.time = [];
            this.searchInfo.time.push(
                dayjs().subtract(30, "days").format("YYYY-MM-DD")
            );
            this.searchInfo.time.push(dayjs().format("YYYY-MM-DD"));
        },
        getFromcoins() {
            getFromcoins({
                chain_id: this.chainId,
            }).then((res) => {
                this.list = res.data || [];
                this.list.unshift('All')
            }).catch(e => e);
        },
        getTocoins(coin) {
            if (coin == 'All') {
                this.list1 = []
                this.searchInfo.pair2 = "";
            } else {
                getTocoins({ coin }).then((res) => {
                    this.list1 = res.data || [];
                }).catch(e => e);
            }
        },
        getOrderStatus(val) {
            let type;
            if (val == "all") {
                type = "all";
            } else if (val == "Limit Order") {
                type = "limit_price";
            } else if (val == "Market Order") {
                type = "market_price";
            } else if (val == "Swap Order") {
                type = "all";
            }
            return type;
        },
        getPositionList() {
            let chainId = this.chainId
            return new Promise(async (resolve, reject) => {
                if (this.positionLoading) return
                this.positionLoading = true
                let symbol = ''
                let queryParam = {
                    symbol: '',
                    page_no: this.positionList_page_no,
                    page_size: 20,
                    account_id: await this.getAccountId(),
                    chain_id: chainId
                }
                console.log('queryParam', queryParam)
                if (!queryParam.account_id) {
                    this.positionList = []
                    this.positionList_pages = 0
                    return this.positionLoading = false
                }
                // 永续
                let getOrderFunc = perpetualOrder.getPositionList
                getOrderFunc(queryParam).then(res => {
                    if (res.code == '200') {
                        let resList = res.data && res.data.content || []
                        this.positionList = this.forMartPosition(resList)
                        this.positionList_pages = res.data && res.data.total_elements
                    }
                }).catch(e => e).finally(fina => {
                    this.positionLoading = false
                    this.getSymbolsFunc()
                })
            })
        },
        getOpenOrdersFunc() {
            this.loading = true;
            let type = this.getOrderStatus(this.orderStatus);
            //
            let getOrderFunc = this.isPerpetual ? perpetualOrder.getOpenOrder : getOpenOrders
            getOrderFunc({
                symbol: "all",
                type: type || "all",
                direction: "all",
                page_no: this.order_page_no,
                page_size: 20,
                mode: this.orderStatus == "Swap Order" ? "swap" : "all",
                chain_id: this.chainId,
            })
                .then((res) => {
                    if (res.code == 200) {
                        this.orderList =
                            res.data.content.map((v) => {
                                v.content = Math.floor(
                                    (v.trunover / v.amount) * 100
                                );
                                return v;
                            }) || [];
                        this.order_pages = res.data.total_elements || 0;
                    }
                }).catch(e => e).finally((fina) => {
                    this.loading = false;
                });
        },
        queryHistory() {
            let param = this.getParams();
            this.getTradeHistoryFunc(param);
        },
        getTradeHistoryFunc(param = {}) {
            if (!this.userCenter) {
                param.address =
                    this.$store.state.user.userInfo &&
                    this.$store.state.user.userInfo.uid;
            }
            this.loading = true;
            let getOrderHistory = this.isPerpetual ? perpetualOrder.getOrderHistory : getOrerHistory
            getOrderHistory({
                symbol: param.symbol,
                type: "all",
                direction: param.direction || "all",
                mode: param.mode,
                page_no: this.history_page_no,
                page_size: 20,
                start_time: param.start_time,
                end_time: param.end_time,
                hide_all_canceled: false,
                chain_id: this.chainId == "0" ? "" : this.chainId,
            })
                .then((res) => {
                    let historyList = res.data.content || [];
                    this.historyList = historyList.map((v) => {
                        v.cancelledOrder =
                            v.fail_reason && v.fail_reason !== "0"
                                ? this.$t(getOrderCancelledKey(v.fail_reason))
                                : "";
                        return v;
                    });
                    this.history_pages = res.data.total_elements || 0;
                }).catch(e => e).finally((fina) => {
                    this.loading = false;
                });
        },
        pageChange(val) {
            if (this.model == 1) {
                this.order_page_no = val <= 0 ? 0 : val - 1;
                this.getOpenOrdersFunc();
            } else if (this.model == 2) {
                this.history_page_no = val <= 0 ? 0 : val - 1;
                this.queryHistory();
            } else if (this.model == 0) {
                this.positionList_page_no = val <= 0 ? 0 : val - 1;
                this.getPositionList();
            }
        },
        dateChange(e) {
            this.searchInfo.time = Object.assign([], e);
        },
        getParams() {
            let params = {
                symbol: this.searchInfo.pair + "-" + this.searchInfo.pair2,
                start_time: getUTCTime(new Date(this.searchInfo.time[0])),
                end_time:
                    getUTCTime(new Date(this.searchInfo.time[1])) +
                    24 * 60 * 60 * 1000,
            };
            if (!this.searchInfo.pair || this.searchInfo.pair == 'All') params.symbol = "all";
            if (this.searchInfo.side == "all") {
                params.mode = "all";
                params.direction = "all";
            } else if (this.searchInfo.side == "Swap") {
                params.mode = "swap";
                params.direction = "";
            } else if (this.searchInfo.side == "Buy limit" || this.searchInfo.side == "Buy") {
                params.mode = "all";
                params.direction = "buy";
            } else if (this.searchInfo.side == "Sell limit" || this.searchInfo.side == "Sell") {
                params.mode = "all";
                params.direction = "sell";
            }
            return params;
        },
        reset() {
            this.searchInfo.type = "";
            this.searchInfo.pair = "";
            this.searchInfo.pair2 = "";
            this.searchInfo.side = "All";
            this.setDefaultTime()
            this.queryHistory();
        },
        //- toDo cancelOrder
        cancelOrder(item, index) {
           this.dealCancel(item, index)
        },
        async dealCancel(item, index, optType) {
            if (this.request) return;
            this.request = true;
            let ids = item && [item.order_id] || this.orderList.map(v => v.order_id)
            await this.$store.dispatch('trade/cancelOrder', {ids: ids, type: this.$store.state.trade.tradeMode, optType: '', callback: (code => {
                if (code == 200) {
                    if (item) {
                        this.orderList.splice(index, 1);
                    } else {
                        this.getOpenOrdersFunc();
                    }
                }
            })})
            this.request = false;
        },
        async dealAll(item, optType) {
            this.dealCancel('', '', 'all')
        },
        dateYear(time) {
            let timeData = new Date(time / 1000);
            let dateArr = [
                addZore(timeData.getFullYear()),
                addZore(timeData.getMonth() + 1),
                addZore(timeData.getDate()),
            ];
            return dateArr.join("-");
        },
        async getAccountId() {
            let perpetualAccount = await this.$store.dispatch('getPerpetualAccount', this.chainId)
            let account = perpetualAccount && perpetualAccount.create2Address || ''
            if (!account) return
            let accountId = await this.$store.dispatch('getPerpetualAccountId', {
                chainId: this.chainId,
                address: account,
                type: 'committed'
            }).catch(err => 0)
            if (!accountId) return
            return accountId
        },
        getSymbolsFunc(){
            let params = {
                chain_id: this.chainId
            }
            console.log('getSymbolsFunc:params', params)
            getSymbols(params).then(res => {
                if (res.code == 200) {
                    let marketPriceData = res.data || []
                    marketPriceData.map(v => {
                        this.$set(this.marketPriceMap, v.symbol, v.close)
                        this.$set(this.markPriceMap, v.symbol, v.mark_price)
                    })
                    console.log('marketPriceMap:', this.marketPriceMap)
                    console.log('markPriceMap:', this.markPriceMap)
                }
            }).catch(e => e).finally(fina => {
                this.forMartPosition(this.positionList)
                this.$set(this, 'positionList', this.positionList)
            })
        },
        forMartPosition(list, updateSymbol) {
            let markPriceMap = this.markPriceMap
            list.map((v, i) => {
                if (markPriceMap[v.symbol]) {
                    markPriceMap[v.symbol] = markPriceMap[v.symbol] + ''
                    let direction = v.side == 0 ? 1 : -1
                    let marketPrice = (this.marketPriceMap[v.symbol] + '').replace(/\,/g, '')
                    v.avg_price = v.avg_price + ''
                    v.marketPrice = marketPrice
                    v.marketPrice = marketPrice
                    v.markPrice = this.markPriceMap[v.symbol]
                    v.pnl = v.size * direction * (v.markPrice - v.avg_price) || '0.0'
                    v.marketRatio = formatStringNum(this.calcMarginRatio(v, v.pnl), 2) || '0.00'
                    v.pnlRate = Number(v.markPrice) && v.pnl / (v.size * v.markPrice / v.leverage) || '0.0'
                    v.sizeRate = v.sizeRate || 15
                    v.sizeCloseVal = v.size
                    if (updateSymbol == v.symbol) this.$set(this.positionList, i, v)
                } else {
                    let marketPrice = (this.marketPriceMap[v.symbol] + '').replace(/\,/g, '')
                    v.pnl = '0.0'
                    v.pnlRate = ''
                    v.marketRatio = '0.00'
                    v.marketPrice = marketPrice || '0.00'
                    v.markPrice = '0.00'
                }
            })
            return !updateSymbol ? list : []
        },
        calcMarginRatio(item, pnl) {
            let marginValBalance = (item.size * item.avg_price)
            let marginIndex = 'max'
            let marginVal = Object.keys(this.marginMap)
            marginVal.map(v => {
                if (marginValBalance < v && marginIndex == 'max') {
                    marginIndex = v
                }
            })
            let res = (marginValBalance * this.marginMap[marginIndex].marginRate) / (item.margin + pnl * 1) * 100
            return res
        },
        lowerCaseVal(val) {
            return val && val.toUpperCase().slice(0, 1) + val.toLowerCase().slice(1)
        },
    },
    unmounted() {
        console.log('un-mounted')
    },
	beforeDestroy() {
        console.log('un-beforeDestroy')
		this.$store.commit("trade/setTradeMode", "");
	}
};
