//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * wallet钱包入口。
 * 用于公共数据获取，公共样式定义等。
 */
// import { walletData } from '../../plugin/Wallet/walletData'

// 不需要登录的名单（路由名称）
const notLoginList = ["faucet", "account-detail"];

export default {
  name: "WalletEntry",
  data() {
    return {
      color: "",
      routeName: "",
      h5MenuHideTop: [
        "send",
        "receive",
        "wallet-send",
        "wallet-search",
        "wallet-deposit",
        "wallet-withdraw",
        "wallet-transfer",
        "wallet-bridge",
        "faucet",
        "wallet-balance",
        "wallet-mobile-detail",
        "wallet-mobile-history",
        "future-transaction-history",
      ],
      h5ShowIconArr: ["overview", "wallet-overview"],
      hideMenu: ['tools', 'faucet'],
      showSmallIcon: false,
      menuType: ''
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    userId() {
      return !!this.userInfo.id
    },
    isLogined() {
      return this.walletData && this.walletData.getAddress() || this.userInfo && (Object.keys(this.userInfo).length || this.userId);
    },
    notNeedLogined() {
      return notLoginList.includes(this.$route.name);
    },
    networkList() {
      return this.$store.state.coin.networkList;
    },
    account() {
      return this.$store.getters.account || "";
    },
    version() {
      return this.$store.state.siteConfig.footerMsgVersion || "";
    },
    themeMode() {
      // 钱包只支持浅色主题
      // return "light";
      // 默认 dark，目前只支持 dark , light。
      return this.$store.getters.themeOfRoute(this.$route.name)
    },
    isHideMenu() {
      let routeName = this.$route.name;
      return this.hideMenu.includes(routeName)
    },
    showMenu() {
      return this.$store.state.cmsstore.menuShow
    }
  },
  watch: {
    '$store.state.cmsstore.menuChange'(val) {
      let portfoType = sessionStorage.getItem('portfoilType')
      this.menuType = portfoType || (this.routeName.includes('wallet') ? 'user' : 'portfolio')
      console.log('this.menuType', this.menuType)
    },
    $route: {
      handler(val) {
        this.routeName = val.name;
        console.log("val:", this.routeName);
        this.color = val.name === "overview" ? "var(--primary-color)" : "";
        this.showSmallIcon = this.h5ShowIconArr.includes(val.name);
        let portfoType = sessionStorage.getItem('portfoilType')
        this.menuType = portfoType || (this.routeName.includes('wallet') ? 'user' : 'portfolio')
        this.checkLogin();
      },
      immediate: true,
    },
  },
  created() {
    this.fetchNetwork();
    this.checkLogin();
    this.$store.dispatch("getL1TokenList");
  },
  mounted() {
    if (!this.networkList.length) {
      this.fetchNetwork()
      setTimeout(() => {
        console.log('networkList-2:', this.networkList, this.networkList.length)
      }, 1000 * 10)
    }
  },
  methods: {
    checkLogin() {
      // 需要登录
      if (!this.notNeedLogined && !this.isLogined) {
        this.$router.push({
          name: "login_wallet",
        });
      }
    },
    fetchNetwork() {
      this.$store.dispatch("fetchNetworkList");
    },
  },
};
