/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2021-02-27 18:46:21
 * @LastEditors: Lewis
 * @LastEditTime: 2021-10-26 21:46:29
 */

import {axios} from '@/utils/Http/axios';
import { getConfigBaseUrl } from '../../utils/index'
const baseUrl = '/market'
// const baseExChange = 'exchange'
const baseExChange = 'trades'

export const getCoins = data => {
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/coins`,
        method: 'get',
        params: data
    })
}


export const getFavorites = data => {
    let baseUrl = getConfigBaseUrl(data, 'trades')
    return axios.request({
        url: `${baseUrl}/v1.0/favorites`,
        method: 'get',
        params:data,
    })   
}

export const addFavorites = data => {
    let baseUrl = getConfigBaseUrl(data, 'trades')
    return axios.request({
        url: `${baseUrl}/v1.0/favorites`,
        method: 'post',
        params:data,
    })
}

export const cancelFavorite = data => {
    let baseUrl = getConfigBaseUrl(data, 'trades')
    return axios.request({
        url: `${baseUrl}/v1.0/favorites`,
        method: 'delete',
        params:data,
    })
}

export const getOverview = data => {
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/overview`,
        method: 'get',
        params:data,
    })
}

export const getFromcoins = params => {
    let baseUrl = getConfigBaseUrl(params, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/swap/from-coins`,
        method: 'get',
        params
    })
}

export const getTocoins = params => {
    let baseUrl = getConfigBaseUrl(params, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/swap/to-coins`,
        method: 'get',
        params
    })
}

export const getTickerPrice = params => {
    let baseUrl = getConfigBaseUrl(params, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/swap/price`,
        method: 'get',
        params
    })
}

// market
export const getSymbols = data => {
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols/brief`,
        method: 'get',
        params:data,
    })
}

export const getScales = data => {
    let symbol = data.symbol
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols/${symbol}/depth-scales`,
        method: 'get',
        params: {
            chain_id: data.chain_id
        }
    })
}

export const getMarketSymbols = data => {
    let symbol = data.symbol
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols?coin_symbol=${symbol}`,
        method: 'get',
        data
    })
}

export const getOrderBook = data => {
    let {type, scale, symbol, chain_id} = data
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols/${symbol}/order-book?type=${type}&scale=${scale}`,
        method: 'get',
        params: {
            chain_id
        }
    })
}

export const getWalletsCoins = data => {
    let symbol = data.symbol
    delete data.symbol
    return axios.request({
        url: `wallet/v1.0/wallets/coins/?symbols=${symbol}`,
        method: 'get',
        params: data,
    })
}

export const getMarketData = data => {
    let symbol = data.symbol
    let baseUrl = getConfigBaseUrl(data, 'market')
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols/${symbol}/klines`,
        // url: 'binance/api/v1/klines?interval=1m&symbol=ETHUSDT',
        method: 'get',
        params: data,
    })
}

export const getTradeData = data => {
    let baseUrl = getConfigBaseUrl(data, 'market')
    let symbol = data.symbol
    delete data.symbol
    return axios.request({
        url: `${baseUrl}/v1.0/markets/symbols/${symbol}/trades`,
        method: 'get',
        params: data
    })
}




