/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-06-10 22:38:27
 * @LastEditors: Lewis
 * @LastEditTime: 2022-06-10 23:24:41
 */
import { walletData } from '@/common_modules/wallet-submodule/plugin/Wallet/walletData'
import { getChainId } from '@/common_modules/cms-submodule/utils'
import store from "@/store";

export const isUserCenter = () => {
    let configType = site_config && site_config.centralization_type || ''
    return configType == 'CENTRALIZATION'
}

export const getTimeZone = () => {
    let offset = new Date().getTimezoneOffset();
    if (offset != 0) {
        let symbol = Math.abs(offset) === offset ? "-" : "+";
        let h = (Math.floor(Math.abs(offset) / 60) + "").padStart(2, '0')
        let m = (Math.abs(offset) % 60 + "").padStart(2, '0')
        return `GMT${symbol}${h}:${m}`
    } else {
        return 'GMT+0';
    }
}

export const getTradeDefaultChainId = function () {
    return store.state.chainId;
}

export const getUTCTime = (d1) => {
    return new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() ).getTime()
}

export const emitLoading = obj => {
    $Vue.$bus.$emit('linkLoading', obj)
}

export const getFee = async function(transfer, fn) {
    let provider = walletData.getSyncWallet().provider
    if (provider) {
        const fullFee = await provider.getTransactionFee('Transfer', transfer.address, conf.getFeeType || transfer.token);
        transfer.fee = fullFee.totalFee;
        fn && fn(transfer.fee)
    } else {
        fn && fn(transfer.fee)
    }
}

export const getOrderCancelledKey = (val) => {
    let cancelledArr = [
        'cancelled',
        'deadline',
        'token_is_not_supported',
        'account_is_not_allowed',
        'order_account_is_incorrect',
        'system_reject',
        'account_not_exist',
        'account_not_activated',
        'order_signature_is_incorrect',
        'bigger_than_max_suppored',
        'recipient_account_not_exist',
        'balance_not_enough',
        'tokens_not_match',
        'swap_same_tokens',
        'amount_not_match',
        'eth_signature_is_incorrect',
        'trading_pairs_not_supported',
        'self_swap'
    ]
    return Number(val) ? 'trade_trade_order_canceled_reason_' + cancelledArr[val * 1] : val;
}

export const getLayer1RpcUrl = function (chainId) {
    let netWroks = []
    chainId = chainId && parseInt(chainId) || ''
    if (window.site_config) {
        try {
            let configNetworks = window.site_config['layer1_list']
            netWroks = configNetworks && configNetworks.length && JSON.parse(configNetworks) || []
            netWroks = netWroks.map(v => {
                let config = Object.keys(v).length ? v[Object.keys(v)[0]] : {}
                return {
                    rpc_url: config.layer1_rpc_url,
                    sub_type: 'layer1_config_',
                    subkey: Object.keys(v)[0]
                }
            })
        } catch (error) {
            netWroks = []
        }
    }
    return netWroks.filter(v => {
        return chainId && (v.sub_type + chainId == v.subkey) || !chainId
    })
}

export const getChainLayer2Config = function (chainId = 4) {
    let layer2Config = {}
    if (window.site_config) {
        try {
            chainId = chainId && parseInt(chainId) || ''
            let layer2_config = window.site_config['layer2_list']
            let layer1_config = getLayer1RpcUrl(chainId)
            let layer2ConfigArr = layer2_config && layer2_config.length && JSON.parse(layer2_config)
            layer2ConfigArr.map(v => {
                let layer2_key = Object.keys(v)[0]
                if ('layer2_config_' + chainId == layer2_key) {
                    layer2Config = v[layer2_key]
                    layer2Config.web3j = layer1_config[0].rpc_url || ''
                }
            })
        } catch (error) {
            layer2Config = {}
        }
    }
    return layer2Config
}

export const decimalFunc = function(val, decimal = 2) {
    let sign =  val * 1 < 0
    val = Math.abs(val)
    let reg = new RegExp(`^(\\d+)\\.(\\d{0,${decimal}}).*$`);
    val = val.toString().replace(reg, '$1.$2');
    // val = Number(val).toFixed(decimal)
    let resVal = sign ? '-' + val : val
    return resVal
}

export const formatStringNum = (num = 0, decimal=2, zoreFalse = 0) => {
    if (!num && num !== 0) return num;
    let initVal = decimalFunc(num, decimal)
    if (!Number(initVal) && decimal < 6) initVal = decimalFunc(num, 6)
    if (!Number(initVal) && decimal < 10) initVal = decimalFunc(num, 10)
    let reg = initVal.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    // let reg = /(\d)(?=(?:\d{3})+$)/g
    initVal = parseFloat(initVal) + ''
    initVal = initVal.replace(reg,'$1,') 
    if (!zoreFalse) {
        if (!~initVal.indexOf('.')) initVal = initVal + '.00'
        else if (initVal.split('.')[1].length < 2) initVal = initVal + '0'
    } 
    return initVal
}

const perpetualApiConfig = {
    market: {
        5: 'http://xapi.powx.io/perpetual/goerli/market',
        186: 'http://xapi.powx.io/perpetual/dtx/market'
    },
    trade: '/perpetual/trades'
}

export const getPerpetualUrl = (chainId, type) => {
    if (type == 'market') {
        return perpetualApiConfig.market[chainId]
    } else {
        return perpetualApiConfig.trade
    }
}

const toUpperDataKey = item => {
    let resItem = item.replace(/\_(\w)/g, function(a, b){
        return b.toUpperCase()
    })
    return resItem
}
// todo perpetual 改为后台配置的地址格式
export const getMarketApi = function (chainId, connectType = "market_api", apiType) {
    apiType = apiType || 'api'
    if (connectType == 'perpetual') {
        connectType = `market_${connectType}_${apiType}`
    } else if (connectType == 'spot') {
        connectType = `market_${apiType}`
    } else if (!connectType) {
        connectType = `market_${apiType}`
    }
    let marketApi = ''
    try {
        console.log('store.state.siteConfig', store.state.siteConfig)
        let config = store.state.siteConfig[connectType] || store.state.siteConfig[toUpperDataKey(connectType)]
        let marketSettingObj = config && config.length && typeof config == 'string' && JSON.parse(config) || config || {}
        marketApi = marketSettingObj[chainId] || ''
        // console.log('marketSettingObj:marketApi', chainId, marketSettingObj, marketApi)
    } catch (error) {
        marketApi = ''
    }
    return marketApi

}

/**
 * @name: getConfigBaseUrl
 * @desc: 处理请求头部的接口地址转发
 * @param {
 *      params: 请求参数对象
 *      type: [trades|market]
 *      multiChain: 是否多链
 * }
 * @return {*}
 */
 export const getConfigBaseUrl = (params, type, multiChain) => {
    let marketType = store.state.trade.tradeMode // 市场交易类型
    const isAggregated = store.state.siteConfig.supportMultiChain && store.state.trade.isMultiChains;
    let resUrl = ''
    // todo: 永续暂不支持多链 后续要处理
    switch (type) {
        case 'trades':
            if (marketType == 'perpetual') {
                resUrl = getPerpetualUrl('', type)
            } else {
                resUrl = '/trades'
            }
            break;
        case 'market':
            console.log("multiChain:", multiChain, isAggregated, JSON.stringify(params));
            let chainId = multiChain && '0' || marketType != 'perpetual' && isAggregated && '0' || params && params.chain_id || store.state.chainId || getChainId()
            let base_url_market = getMarketApi(chainId, marketType) || "/market";
            if (params && params.chain_id == 0) {
                delete params.chain_id
            }
            resUrl = base_url_market
            // console.log('base_url_market', marketType, chainId, base_url_market)
            break;
        default:
            break;
    }
    return resUrl
}

export const getPerpetualSupportChains = () =>{
    let marketApi = window.site_config['market_perpetual_api']
    let marketApiObj = marketApi && JSON.parse(marketApi) || {}
    return Object.keys(marketApiObj)
}