//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./component/List";
import {
	getAddress,
	addAddress,
	deleteAddress,
	getWithdraw,
} from "../../api/wallet/index";
import { FindResult, checkEthAddress } from "../../utils/index";
import CoinAllSelect from "./component/CoinAllSelect";
import CoinSelect from "./component/CoinSelect";
export default {
	name: "",
	components: {
		List,
		CoinAllSelect,
		CoinSelect,
	},
	data() {
		return {
			errInfo: "",
			checkRes: "",
			curName: "",
			curAddress: "",
            curMemo: "", // 地址描述
			errorStatus: "",
			networkFlag: false,
			addFlag: false,
			CoinSelectShow: false,
			coinValue: {
				name: "",
				symbol: "",
			},
			CoinSelectShow1: false,
			coinValue1: {
				name: "Ethereum",
				symbol: "ETH",
			},
			curList: [],
			depositData: [],
			bread: [
				{
					path: "/wallet",
					name: this.$t("wallet_common_wallet"),
				},
				{
					path: "/wallet/withdraw/address",
					name: this.$t("wallet_address_address_management"),
				},
			],
			clipboard: "",
			// exportText: "Export Deposit History",
			searchInfo: {
				transaction_type: "withdraw",
				coin_name: "All",
				network_id: "All",
			},
			columns: [
				// {
				// 	title: this.$t("wallet_withdraw_coin"),
				// 	key: "symbol",
				// 	width: 150,
				// },
				// {
				// 	title: this.$t("wallet_common_network"),
				// 	key: "network",
				// 	width: 150,
				// },
				{
					title: this.$t("wallet_common_name"),
					key: "name",
                    dataIndex: "name",
					width: "150"
				},
				{
					title: this.$t("wallet_common_address"),
					key: "address",
                    dataIndex: "address"
				},
				{
					title: this.$t("wallet_common_memo"),
					key: "memo",
                    width: "350",
                    dataIndex: "memo"
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
					width: 200,
					align: "right",
					customRender: (text, record) => {
                        const h = this.$createElement;
						return h(
							"div",
							{
								style: {
									display: "flex",
									whiteSpace: "nowrap",
									justifyContent: "flex-end",
								},
							},
							[
								h(
									"span",
									{
										style: {
											marginRight: "20px",
											minWidth: "40px",
											height: "26px",
											fontSize: "16px",
											fontFamily: "Font",
											fontWeight: 400,
											textAlign: "right",
											color: "var(--link-color)",
											lineHeight: "26px",
											whiteSpace: "nowrap",
											cursor: "pointer",
										},
										on: {
											click: () => {
												this.clipboard = record.address;
												this.$nextTick(function() {
													console.log(this.$refs.btn);
													this.$refs.btn.click();
												});
											},
										},
									},
									this.$t("wallet_common_copy")
								),
								h(
									"span",
									{
										style: {
											minWidth: "40px",
											height: "26px",
											fontSize: "16px",
											fontFamily: "Font",
											fontWeight: 400,
											textAlign: "right",
											color: "var(--link-color)",
											lineHeight: "26px",
											whiteSpace: "nowrap",
											cursor: "pointer",
										},
										on: {
											click: () => {
												this.goDelete(record);
											},
										},
									},
									this.$t("wallet_common_delete")
								),
							]
						);
					},
				},
			],
			data: [],
			pageNo: 0,
			pages: 0,
			pageSize: 20,
			confirmation: {},
			symbol: "",
			deleteFlag: false,
			verifyFlag: false,
            errorInfo: {
                address: '',
                name: ''
            }
		};
	},
	computed: {
		exportText: function() {
			return this.$t("wallet_address_address_management");
		},
	},
	watch: {
		curAddress: function(val, oldVal) {
			this.checkParams('address');
		},
		curName: function(val, oldVal) {
			this.checkParams('name');
		},
	},
	created() {
		this.init();
	},
	methods: {
		hide() {
			this.deleteFlag = false;
		},
		subDelete() {
			const params = Object.assign(
				{},
				{
					id: this.row.id,
				}
			);
			deleteAddress(params)
				.then((res) => {
					if (res.code == 200) {
						console.log(res);
						this.queryAddress();
						this.deleteFlag = false;
					}
				})
				.catch((e) => {
					this.$message.error(e.message);
				});
		},
		checkParams(type) {
            switch (type) {
                case 'address':
                    if (!this.curAddress) {
                        this.errorInfo.address = this.$t("wallet_address_recip")
                    } else if (checkEthAddress(this.curAddress)) {
                        this.errorInfo.address = this.$t("wallet_invalid_address")
                    } else {
                        this.errorInfo.address = ''
                    }
                    break;
                case 'name':
                        if (!this.curName) {
                            this.errorInfo.name = this.$t("wallet_address_right_name");
                        } else {
                            this.errorInfo.name = ''
                        }
                    break;
                default:
                    break;
            }
            this.checkRes = (this.errorInfo.address == '' && this.errorInfo.name == '') ? 'success' : ''
		},
		init() {
			this.searchInfo.transaction_type =
				this.$route.query.transaction_type || "All";
			this.searchInfo.coin_name = this.$route.query.coin_name || "All";
			this.searchInfo.network_id = this.$route.query.network_id || "All";
			this.searchInfo.network_name =
				this.$route.query.network_name || "All";
			this.queryAddress();
			if (this.coinValue.name) {
				getWithdraw({ coin_name: this.coinValue.name }).then((res) => {
					if (res.code == 200) {
						this.depositData = res.data.network_list;
						this.confirmation = res.data.network_list[0];
						this.symbol = res.data.symbol;
					}
				}).catch(e => e);
			}
		},
		getNetwork() {
			getWithdraw({ coin_name: this.coinValue1.name }).then((res) => {
				if (res.code == 200) {
					this.depositData = res.data.network_list;
					this.confirmation = res.data.network_list[0];
					this.symbol = res.data.symbol;
					this.networkFlag = true;
				}
			}).catch(e => e);
		},
		pageChange(val) {
			this.queryAddress();
		},
		queryAddress() {
			const params = Object.assign(
				{},
				{
					// network_id: this.searchInfo.network_id
					// address:
				}
			);
			getAddress(params)
				.then((res) => {
					if (res.code == 200) {
						this.pages = res.data.length;
                        this.data = res.data
						// this.data = res.data.map((item) => {
						// 	return Object.assign(item, {
						// 		coin_name: this.searchInfo.coin_name,
						// 		network: this.searchInfo.network_name,
						// 	});
						// });
						this.curList = this.data.map((item) => {
							return Object.assign(
								{
									symbol: this.$route.query.symbol,
								},
								item
							);
						});
					}
				})
				.catch((e) => {
					this.addFlag = false;
				});
		},
		copy() {
			this.$Message.config({
				top: 290,
				duration: 1.5,
			});
			this.$Message.success({
				content: this.$t("wallet_common_copied"),
				duration: 3,
				closable: true,
			});
		},
		goDelete(row) {
			this.deleteFlag = true;
			this.row = Object.assign({}, row);
		},
		changeCoin(item) {
			this.coinValue = item;
			this.goSearch();
		},
		changeCoin1(item) {
			this.coinValue1 = item;
			this.getNetwork();
		},
		change({ confirmation }) {
			this.confirmation = confirmation;
		},
		goSearch() {
			if (!this.coinValue) {
				this.curList = this.data;
				return;
			}
			this.curList = FindResult(
				this.coinValue.name,
				"coin_name",
				this.data
			);
		},
		goAddAddress() {
			this.addFlag = true;
		},
		goSubmit() {
			const params = Object.assign(
				{},
				{
					// network_id: this.searchInfo.network_id,
					address: this.curAddress,
					name: this.curName,
                    memo: this.curMemo
				}
			);
			addAddress(params)
				.then((res) => {
					const { code, message } = res;
					if (code == 200) {
						this.addFlag = false;
						this.queryAddress();
					} else {
						// if (message) {
						//     this.$Message.error(message)
						// }
					}
				}).catch((e) => {
                    let message = e.message
                    this.$Message.error({
                        content: message,
                        duration: 3,
                        closable: true
                    })
                });
		},
		cancle() {
			this.curAddress = "";
			this.curName = "";
            this.memo = ""
			this.addFlag = false;
			this.deleteFlag = false;
		},
	},
};
