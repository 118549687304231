//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./component/List";
import CoinSelect from "./component/CoinSelect";
import TokenSelect from "./component/TokenSelect";
import NetworkSelect from "./component/NetworkSelect";
import iconDown from "../../assets/img/icon-down.png";
import iconUp from "../../assets/img/icon-up.png";
import {
	getLayer2Balance,
	getLayer2Config,
	getHistoryTransaction,
} from "../../api/wallet/index";
import Address from "./component/Address";
import { ethers } from "ethers";
import {
	toI18n,
	isTokenETH,
	numberFormat,
	addZoreFormat,
	getWalletNetWorkConfig,
	getChainId,
	getLayer1RpcUrl,
	getChainLayer2Config,
	getExplorer,
	historyStatus,
	getHashBlockUrl,
	getUTCTime,
    filterL2Balance,
	formatTokenCommify,
} from "../../utils/index";
import NetworkTab from "./component/NetworkTab";
import ERC20 from '../../constracts/ERC20';
import ChainSelect from "./component/ChainSelect.vue";

let timer;
export default {
	name: "Deposit",
	components: {
		// Tabs,
		List,
		CoinSelect,
		Address,
		NetworkSelect,
		ChainSelect,
		TokenSelect,
		//NetworkTab,
	},
	data() {
		return {
			tabId: "token",
			chainId: "",
			chainName: "",
			clipboard: "",
			viewAllShow: false,
			confirmation: {
				depositConfirmations: 0,
			},
			symbol: "",
			decimals: 2,
			bread: [
				{
					path: "/wallet",
					name: this.$t("wallet_common_wallet"),
				},
				{
					path: "",
					name: this.$t("wallet_common_deposit"),
				},
			],
			curChain: {},
			// curToken: {
			// 	name: "",
			// 	symbol: "",
			// },
			CoinSelectShow: false,
			layer1BalanceWei: null,
			layer2BalanceWei: null,
			allowance: ethers.BigNumber.from(0),

			columns: [
				{
					title: this.$t("wallet_common_date"),
					key: "time",
					width: 210,
					customRender: (text, record) => {
                        let h = this.$createElement
						return h(
							"span",
							{},
							`${record.time || record.date}`
							// `${params.row.symbol}-${params.row.network_name}`
						);
					},
				},
				{
					title: this.$t("wallet_withdraw_coin"),
					key: "symbol",
					// width: 80,
					customRender: (text, record) => {
                        let h = this.$createElement
						return h(
							"span",
							{},
							`${record.symbol}`
							// `${params.row.symbol}-${params.row.network_name}`
						);
					},
				},
				{
					title: this.$t("wallet_common_amount"),
					key: "amount",
					// width: 100,
					customRender: (text, record) => {
                        let h = this.$createElement
						return h(
							"span",
							{},
							`${record.action_symbol} ${record.amount}`
						);
					},
				},
				{
					title: this.$t("wallet_history_status"),
					key: "status",
					// width: 120,
					customRender: (text, record) => {
                        let h = this.$createElement
						let st_Completed = this.$t("wallet_history_completed");
						let st_processing = this.$t("wallet_history_processing");
						if (record.status == 'Completed') {
							return h("span", {}, st_Completed);
						} else if (record.status == 'Processing') {
							let netArr = record.network_confirmation.split("/");
							let start = netArr.shift();
							let end = "/ " + netArr.shift();
							return h(
								"div",
								{
									class: {
										status: true,
									},
								},
								[
									h("p", {}, st_processing),
									h(
										"p",
										{
											class: {
												processing: true,
											},
										},
										[
											h("span", "( "),
											h(
												"span",
												{
													class: {
														already: true,
													},
												},
												`${start} `
											),
											h("span", `${end} )`),
										]
									),
								]
							);
						} else {
							return h(
								"span",
								{},
								historyStatus(record.status)
							);
						}
					},
				},
				{
					title: this.$t("wallet_common_information"),
					key: "key5",
					customRender: (text, record) => {
                        let h = this.$createElement
						let st_Address = this.$t("wallet_common_address");
						if (record.expand) {
							let { txid, chain_id, eth_hash, address } = record;
							let fromUrl = getHashBlockUrl(
								eth_hash,
								chain_id,
								"L1",
								this.$store
							);
							let toUrl =
								(eth_hash &&
									getHashBlockUrl(
										txid,
										chain_id,
										"L2",
										this.$store
									)) ||
								"";
							let information = [
								h("p", { class: { information: true } }, [
									h(
										"span",
										{
											style: {
												display: "inline-block",
												width: "90px",
											},
										},
										`${this.$t("wallet_common_address")}: `
									),
									h(
										"span",
										{ style: { display: "inline-block" } },
										address
									),
								]),
								h(
									"p",
									{
										class: { information: true },
										style: { display: "flex" },
									},
									[
										h(
											"span",
											{
												style: {
													display: "inline-block",
													"flex-basis": "90px",
												},
											},
											`${this.$t(
												"wallet_history_information_l1_txid"
											)}: `
										),
										h(
											"a",
											{
												attrs: {
													href: fromUrl,
													target: "_target",
												},
												style: {
													lineHeight: "1em",
													display: "inline-flex",
													flex: "1",
												},
											},
											[
												h(
													"span",
													{
														style: {
															display: "inline-block",
															lineSize: "fit-content",
                                                            wordBreak: "break-word"
														},
													},
													[
														eth_hash || "",
														h("img", {
															style: {
																display:
																	"inline-block",
																width: "20px",
																height: "20px",
																verticalAlign:
																	"-4px",
																marginLeft:
																	".2em",
															},
															attrs: {
																src: require("../../assets/img/share-link.png"),
															},
														}),
													]
												),
											]
										),
									]
								),
								h(
									"p",
									{
										class: { information: true },
										style: { display: "flex" },
									},
									[
										h(
											"span",
											{
												style: {
													display: "inline-block",
													"flex-basis": "90px",
												},
											},
											`${this.$t(
												"wallet_history_information_l2_txid"
											)}: `
										),
										h(
											"a",
											{
												attrs: {
													href: toUrl,
													target: "_target",
												},
												style: {
													lineHeight: "1em",
													display: "inline-flex",
													flex: "1",
												},
											},
											[
												h(
													"span",
													{
														style: {
															display: "inline-block",
															lineSize: "fit-content",
                                                            wordBreak: "break-word"
														},
													},
													[
														txid || "",
														h("img", {
															style: {
																display:
																	"inline-block",
																width: "20px",
																height: "20px",
																verticalAlign:
																	"-4px",
																marginLeft:
																	".2em",
															},
															attrs: {
																src: require("../../assets/img/share-link.png"),
															},
														}),
													]
												),
											]
										),
									]
								),
							];
							if (!txid) information.pop();
							return h("div", {}, information);
						} else {
							return h("div", [
								h(
									"p",
									{
										class: {
											action: true,
										},
									},
									[
										h("span", {}, st_Address),
										h(
											"span",
											{},
											record.address || record.from_address
										)
									]
								)
							]);
						}
					},
				},
				{
					title: this.$t("wallet_common_action"),
					key: "",
					width: 100,
					align: "center",
					customRender: (text, record, index) => {
                        let h = this.$createElement
						let st_Copy = this.$t("wallet_common_copy");
						let copyFn = this.copy;
						let icon = record.expand ? iconUp : iconDown;
						let btn = h("img", {
							style: {
								cursor: "pointer",
							},
							attrs: {
								width: "20px",
								height: "20px",
								src: icon,
							},
							on: {
								click: () => {
									this.data[index].expand = !this.data[index].expand;
								},
							},
						});
						let copy = h(
							"div",
							{
								class: {
									copy: true,
								},
								on: {
									click: () => {
										this.clipboard = record.txid;
										this.$nextTick(function () {
											this.$refs.btn.click();
										});
									},
								},
							},
							st_Copy
						);
						let btns = [btn];
						// if (params.row.expand) {
						// 	btns.push(copy);
						// }
						return h("div", btns);
					},
				},
			],
			data: [],
			networks: [],
			loading: false,
			loading_one: false,
			loading_list: false,
			chainConfigSet: {},
			amountDecimals: 0,
			loadLayer1Failed: false, // 1层余额加载失败。
		};
	},
	computed: {
		account() {
			return this.$store.getters.account || '';
		},
        tokens() {
            return this.$store.getters.chainLayerToken(`${this.chainId}-2`) || [];
        },
		curToken() {
			return this.tokens.find((item) => item.symbol === this.symbol) || {};
		},
		// 链的数据，统一使用ChainSelect组件
        netWorkList() {
            let networkList = this.$store.getters.walletNetworks || []
            return networkList
        },
		layer1Balance() {
			return this.layer1BalanceWei && ethers.utils.formatUnits(
				this.layer1BalanceWei.toString(),
				this.curToken.decimals
			);
		},
		layer1BalanceString() {
			return this.layer1Balance && formatTokenCommify(
				this.layer1Balance,
				this.curToken.show_scale
			);
		},
		layer2Balance() {
			return this.layer2BalanceWei && ethers.utils.formatUnits(
				this.layer2BalanceWei.toString(),
				this.curToken.decimals
			);
		},
		layer2BalanceString() {
			return this.layer2Balance && formatTokenCommify(
				this.layer2Balance,
				this.curToken.show_scale
			);
		},
		layer1NetworkList() {
			return this.$store.getters.layer1NetworkList;
		},
		blockExplorerUrl() {
			// 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
			const privateUrl = window.site_config.block_explorer_url;
			if (privateUrl) {
				return privateUrl.endsWith("/")
					? `${privateUrl}${this.chainId}/L2/`
					: `${privateUrl}/${this.chainId}/L2/`;
			} else {
				const url = this.$store.getters.filterNetworkItem(
					this.chainId,
					"L2"
				).blockExplorerUrl;
				return url.endsWith("/") ? url : url + "/";
			}
		},

	},
	watch: {
		"$store.state.chainId": function (chainId) {
			this.chainId = chainId;
            this.init();
		},
	},
	provide() {
		return {
			refresh: this.refresh,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			const cacheTokenSymbol = sessionStorage.getItem("TokenSymbol");
			const { symbol, chainId, tab } = this.$route.query;
			this.tabId = tab || "token";
			this.symbol = symbol || cacheTokenSymbol || '';
			// this.chainId = chainId || this.$store.state.wallet.walletChainId;
			this.chainId = Number(chainId || this.$store.state.chainId);
			this.$store.commit("setCheckDataChanId", this.chainId);
			// this.chainId = this.$store.getters.curChainId;
            this.getNetwork()
			this.fetchTokens();
		},
        async fetchTokens() {

            await this.$store.dispatch("getTokenByChainAndLayer", {chainLayer: `${this.chainId}-2`}).catch(e => []);
            if (!this.symbol) {
				this.symbol = this.tokens[0] && this.tokens[0].symbol;
			} else {
				// 检查选中的symbol是否在当前tokens中
				const symbols = this.tokens.map(item => item.symbol);
				if (!symbols.includes(this.symbol)) {
					this.symbol = this.tokens[0] && this.tokens[0].symbol;
				}
			}
            this.onChangeToken();
		},
		async onChangeChain(item) {
			if (this.chainId == item.chainId) return;
			this.curChain = item;
			this.chainId = item.chainId;
			this.$store.commit("setCheckDataChanId", this.chainId);
			this.layer1BalanceWei = 0;
			this.layer2BalanceWei = 0;
			this.fetchTokens();
		},
		onChangeToken() {
			sessionStorage.setItem("TokenSymbol", this.symbol);
			this.getInfo();
			this.getAllowance();
		},
        async getNetwork() {
			this.networks = this.getNetworkList();
			if (this.chainId) {
				this.confirmation = this.networks.find((v, i) => {
					if (v.chainId == this.chainId) this.chainName = i;
					return v.chainId == this.chainId;
				});
				this.curChain = this.confirmation;
			}
        },
		async getInfo() {
			// this.networks = this.getNetworkList();
			// if (this.chainId) {
			// 	this.confirmation = this.networks.find((v, i) => {
			// 		if (v.chainId == this.chainId) this.chainName = i;
			// 		return v.chainId == this.chainId;
			// 	});
			// 	this.curChain = this.confirmation;
			// }
			this.decimals = this.curToken.decimals;
			this.amountDecimals = this.curToken.show_scale;
			let confirmation = await this.getLayer2Config(this.chainId);
			this.confirmation = Object.assign(
				{},
				this.confirmation,
				confirmation
			);

			this.getAllBalance();
			this.getHistory();
		},
		getNetworkList(arr) {
			let netWorks = getWalletNetWorkConfig();
			// 融合基础网络列表数据
			netWorks = netWorks.map((item) => {
				return Object.assign(
					{},
					this.getLayer1NetworkItem(Number(item.chainId)),
					item
				);
			});
			return netWorks;
		},
		async getLayer2Config(chainId) {
			console.log("getLayer2Config chainId:", chainId);
			let config = getChainLayer2Config(chainId) || [];
			let reqUrl = config.rest;
			let layer2Config = await getLayer2Config(reqUrl).catch((e) => "");
			return layer2Config && layer2Config.result;
		},
		async getAllBalance(hideLoading) {
			this.getLayer1Balance(hideLoading);
			this.getLayer2Balance(hideLoading);
		},
		async getLayer1Balance(hideLoading) {
			const rpcUrl = this.curChain.rpcUrl;
			const chainId = this.chainId;
			const tokenAddress = this.curToken.contract_address;
			const tokenType = this.curToken.token_type;

			console.log("tokenAddress", tokenAddress);
			if (!hideLoading) {
				this.loading_one = true;
			}
			// this.layer1BalanceWei = 0;
			this.loadLayer1Failed = false;
			let balance;
			const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
			if (tokenType === "NATIVE") {
				balance = await provider.getBalance(this.account)
				// .then(balance => ethers.utils.formatEther(balance))
				.catch((e) => {
					console.error("[native]getLayer1Balance e:", e);
					this.loadLayer1Failed = true;
					return "0";
				});
			} else {
				const contract = await this.walletUtil.dealWeb3QueryContract(
					rpcUrl,
					tokenAddress
				);
				// console.log("this.account", this.account);
				balance = await contract.methods
					.balanceOf(this.account)
					.call()
					// .then((res) => res)
					.catch((e) => {
						console.error("[erc20]getLayer1Balance e:", e);
						this.loadLayer1Failed = true;
						return "0";
					});
			}
			// 判断网络和币种是否切换
			if (chainId === this.chainId && tokenAddress === this.curToken.contract_address) {
				this.layer1BalanceWei = balance;
				// console.log(this.curToken.symbol, "layer1BalanceWei", balance);
			}

			this.loading_one = false;
		},
		async getLayer2Balance(hideLoading) {
			let config = getChainLayer2Config(this.chainId) || [];
			let reqUrl = config.rest;
			//getLayer2Balance
			let queryLayer2Param = {
				url: reqUrl,
				address: this.account,
				type: "balances", // balances,committed
			};
			if (!hideLoading) {
				this.loading = true;
			}
			// this.layer2BalanceWei = "";
			if (!queryLayer2Param.address) return;
			await getLayer2Balance(queryLayer2Param)
				.then((res) => {
					if (res.status == "success") {
                        const { symbol } = this.curToken;
                        const totalBalance = filterL2Balance(res.result, 'committed', symbol);
						this.layer2BalanceWei = totalBalance || "0";
					}
				})
				.catch(e => {
					this.layer2BalanceWei = "0";
				});
			this.loading = false;
		},
		async getAllowance() {
			const contractAddress = this.curToken.contract_address;

			if (isTokenETH(contractAddress)) return;

			const layer2Config = await this.$store.dispatch('getLayer2Config', this.chainId);

			if (!layer2Config) {
				this.allowance = ethers.BigNumber.from(0);
				return;
			}

			const spender = layer2Config.contract; // 扣款合约地址
			console.log("getAllowance spender:", spender);

			const rpcUrl = this.curChain.rpcUrl;
			console.log("getAllowance rpcUrl:", rpcUrl);
			const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
			
			if (!contractAddress) {
				this.allowance = ethers.BigNumber.from(0);
				return;
			}

			const contract = new ethers.Contract(
				contractAddress,
				ERC20.abi,
				provider // provider/signer
			);

			this.allowance = ethers.BigNumber.from(0);
			contract.allowance(this.account, spender)
				.then((allowance) => {
					// console.log("getAllowance res:", allowance, allowance.toString());
					// 同链，同token。
					if (rpcUrl === this.curChain.rpcUrl && contractAddress === this.curToken.contract_address) {
						this.allowance = allowance;
					}
				})
				.catch((e) => {
					console.error("getAllowance e:", e);
				});
		},
		updateAllowance(symbol, allowance) {
			// console.log("update allowance:", allowance, allowance.toString());
			// 判断的原因是可能用户切换了token.
			if (this.symbol === symbol) {
				this.allowance = allowance; // wei;
			}
		},
		refresh() {
			clearInterval(timer);
			this.getInfo();
		},
		getLayer1NetworkItem(chainId) {
			return this.layer1NetworkList.find(
				(item) => item.chainId === chainId
			);
		},
		getHistory(refresh) {
			let params = {
				page_no: 0,
				page_size: 10,
				coin_name: this.curToken.symbol,
				transaction_type: "Deposit",
				transaction_status: "all",
				start_date: getUTCTime(new Date()) - 30 * 24 * 60 * 60 * 1000,
				end_date: getUTCTime(new Date()) + 24 * 60 * 60 * 1000,
				address: this.account,
				chain_id: this.chainId,
				layer: "L1",
			};
			let activeId = "";
			if (!refresh) {
				this.loading_list = false;
				this.data = [];
			} else {
				let checkItem = this.data.find((v) => v.expand) || {};
				activeId = checkItem.id;
				if (!timer) return;
			}
			getHistoryTransaction(params)
				.then((res) => {
					if (res.code == 200) {
						this.viewAllShow = res.data.total_number > 5;
						let networkL2 = getExplorer(this.chainId * 1);
						let networkL2Name = networkL2 + "_l2";
						let resList =
							res.data.transaction_history_list ||
							res.data.bridge_list;
						this.data = resList.map((item) => {
							item.networkL2Name = networkL2Name;
							return Object.assign(item, {
								expand: item.id == activeId,
							});
						});
						this.openTimeFunc();
					}
				}).catch(e => e)
				.finally((fina) => {
					this.loading_list = true;
				});
		},
		openTimeFunc() {
			let finishArrData = this.data.filter(
				(v) => v.status == "Finalized" || v.status == "Failure"
			);
			if (finishArrData.length != this.data.length && this.data.length) {
				this.refreshHistoryByTime();
			} else {
				clearInterval(timer);
			}
		},
		refreshHistoryByTime() {
			clearInterval(timer);
			timer = setInterval(() => {
				this.getHistory(true);
				this.getAllBalance(true);
			}, 6 * 1000);
		},
		toHistory() {
			this.$router.push({
				name: "history",
				query: {
					transaction_type: "Deposit_to_L2",
					coin_name: this.curToken.symbol,
					// symbol: this.curToken.symbol,
					chainId: this.chainId,
					layer: "L1",
					type: "ERC20",
				},
			});
		},

		copy() {
			this.$message.config({
				top: 290,
				closable: true,
			});
			this.$message.success({
				duration: 3,
				content: this.$t("wallet_common_copied"),
			});
		},
	},
	destroyed() {
		sessionStorage.removeItem("searchSymbol");
		clearInterval(timer);
	},
};
