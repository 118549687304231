//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { downloadAPK } from '../../api/common/index'
import { download } from '../../utils/index'
import { conf } from '../../config/index'
export default {
    name: 'download',
    data() {
        return {
            type: 'Android',
            url: ''
        }
    },
    methods: {
        setAppstore(type) {
            this.type = type
        },
        downloadFile() {
            let url = `${conf.sitePath}iswallet/apk/iswallet_v1.0_beta.apk`
            this.url = url
            // downloadAPK(url).then((res) => {
            //     let _this = this;
            //     const fileReader = new FileReader();
            //     fileReader.readAsText(res.data);
            //     fileReader.onload = function () {
            //         try {
            //             const result = JSON.parse(this.result);
            //             if (result && result["code"]) {
            //                 _this.$Powx.toast.show(result.message);
            //             } else {
            //                 download(res);
            //             }
            //         } catch (e) {
            //             download(res);
            //         }
            //     }
            // })
        }
    }
}
