//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { utils } from "ethers";
import ChainSelect from "../component/ChainSelect.vue";
import TokenSelect from "../component/TokenSelect.vue";
import futuresConfig from "./../../../config/futures";

import {
  fetchPerpetualOverview,
  fetchPerpetualBalance,
  fetchPerpetualPositions,
  fetchPerpetualAssets,
} from "../../../api/wallet/index";
import { toFloor, filterL2Balance } from "../../../utils/index";

export default {
  name: "FuturesOverview",
  props: {
    type: {
      type: String,
      default: ''
    },
    networkChainLayer: {
      type: String,
      default: ''
    }
  },
  components: {
    ChainSelect,
    TokenSelect,
  },
  data() {
    return {
      hiddenBalance: false,

      balanceSymbol: futuresConfig.balanceSymbol,

      // futureTotalAmount: 0,
      // futureAvailableAmount: 0,
      // futureMarginAmount: 0,

      futuresTotalBalanceWei: 0, // futures
      futuresAvailableBalanceWei: 0, // futures
      futuresMarginBalanceWei: 0, // futures
      futuresLockedBalanceWei: 0, // futures

      chainIdAndLayer: null, // 格式：5-2
      tabId: "positions", // positions,assets

      positionColumns: [
        {
          width: 0,
        },
        {
          title: this.$t("wallet_common_asset"),
          // dataIndex: "symbol",
          key: "symbol",
          width: 160,
          scopedSlots: { customRender: "asset" },
        },
        {
          title: this.$t("trade_perpetual_market_size"),
          // dataIndex: "size",
          key: "size",
          // width: 120,
          scopedSlots: { customRender: "size" },
        },
        {
          title: this.$t("trade_perpetual_entry_price"),
          // dataIndex: "avg_price",
          key: "avg_price",
          // width: 120,
          scopedSlots: { customRender: "avg_price" },
        },
        {
          title: this.$t("trade_perpetual_mark_price"),
          // dataIndex: "mark_price",
          key: "mark_price",
          // width: 120,
          scopedSlots: { customRender: "mark_price" },
        },
        {
          title: this.$t("trade_perpetual_liq_price"),
          // dataIndex: "liq_price",
          key: "liq_price",
          // width: 120,
          scopedSlots: { customRender: "liq_price" },
        },
        {
          title: this.$t("trade_perpetual_margin_ratio"),
          // dataIndex: "marginRatio",
          key: "marginRatio",
          // width: 120,
          scopedSlots: { customRender: "marginRatio" },
        },
        {
          title: this.$t("trade_perpetual_margin"),
          // dataIndex: "margin",
          key: "margin",
          // width: 120,
          scopedSlots: { customRender: "margin" },
        },
        {
          title: this.$t("trade_perpetual_pnl"),
          // dataIndex: "pnl",
          key: "pnl",
          // width: 120,
          scopedSlots: { customRender: "pnl" },
        },
      ],
      positionLoading: false,
      positionList: [],

      assetColumns: [
        {
          width: 0,
        },
        {
          title: this.$t("wallet_common_asset"),
          dataIndex: "symbol",
          key: "symbol",
          // width: 120,
        },
        {
          title: this.$t("wallet_balance_title"),
          // dataIndex: "future_total_amount",
          key: "balance",
          // align: "right",
          scopedSlots: { customRender: "balance" },
        },
        {
          title: this.$t("wallet_perpetual_unrealized_pnL"),
          // dataIndex: "pnl",
          key: "pnl",
          // align: "right",
          scopedSlots: { customRender: "pnl" },
        },
        {
          title: this.$t("wallet_perpetual_margin_balance"),
          // dataIndex: "future_margin_amount",
          key: "margin_balance",
          // align: "right",
          scopedSlots: { customRender: "margin_balance" },
        },
        {
          title: this.$t(
            "wallet_perpetual_maximized_withdraw_amount"
          ),
          // dataIndex: "maxAmount",
          key: "maxAmount",
          scopedSlots: { customRender: "maxAmount" },
          // align: "right",
        },
        {
          title: this.$t("wallet_common_action"),
          // dataIndex: "action",
          key: "action",
          width: 150,
          // align: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      assetLoading: false,
      assetsList: [],
      marginMap: {
        50000: {maxLeverage: '125x', marginRate: '0.004'},
        250000: {maxLeverage: '100x', marginRate: '0.005'},
        1000000: {maxLeverage: '50x', marginRate: '0.01'},
        10000000: {maxLeverage: '20x', marginRate: '0.025'},
        20000000: {maxLeverage: '10x', marginRate: '0.05'},
        50000000: {maxLeverage: '5x', marginRate: '0.1'},
        100000000: {maxLeverage: '4x', marginRate: '0.125'},
        200000000: {maxLeverage: '3x', marginRate: '0.15'},
        300000000: {maxLeverage: '2x', marginRate: '0.25'},
        max: {maxLeverage: '1x', marginRate: '0.50'}
      }
    };
  },
  computed: {
    account() {
      return this.$store.getters.account || '';
    },
    currency() {
      return this.$store.state.user.currency;
    },
    // walletChains() {
    // 	return this.$store.getters.walletNetworks.map((item) => {
    // 		return {
    // 			label: item.name,
    // 			value: item.chainId,
    // 		};
    // 	});
    // },
    networks() {
      return this.$store.getters.networksOverview.filter(
        (item) => item.layer === "L2"
      );
    },
    chainId() {
      return this.chainIdAndLayer
        ? this.chainIdAndLayer.split("-")[0]
        : null;
    },
    layer2Config() {
      return this.$store.state.siteConfig.layer2Map[this.chainId];
    },
    balanceTokenDetail() {
      return this.$store.getters.commonTokenDetail(
        this.balanceSymbol,
        this.chainId
      ) || {};
    },

    // balance
    decimals() {
      return this.balanceTokenDetail && this.balanceTokenDetail.decimals;
    },
    futuresTotalBalance() {
      return utils.formatUnits(
        this.futuresTotalBalanceWei,
        this.decimals
      );
    },
    futuresAvailableBalance() {
      return utils.formatUnits(
        this.futuresAvailableBalanceWei,
        this.decimals
      );
    },
    futuresMarginBalance() {
      // const total = new BNP(this.futuresMarginBalanceWei).plus(new BNP(this.futuresLockedBalanceWei)).toString();
      // return utils.formatUnits(total, this.decimals);
      return utils.formatUnits(
        this.futuresMarginBalanceWei,
        this.decimals
      );
    },
    futuresLockedBalance() {
      return utils.formatUnits(
        this.futuresLockedBalanceWei,
        this.decimals
      );
    },

    formatPositionList() {
      return this.positionList.map((item) => {
        item.pnl = this.calcPnl(item);
        // item.marginRatio =
        // 	(item.margin * 1 + item.pnl) /
        // 	(item.size * item.mark_price);
        item.marginRatio = this.calcMarginRatio(item).toFixed(2) + '%'
        return item;
      });
    },
    unrealizedPnL() {
      return this.formatPositionList.reduce((acc, cur) => {
        return acc + cur.pnl;
      }, 0);
      // return this.positionList.reduce((item) => {
      // 	return (
      // 		item.size / item.avgPrice -
      // 		item.size / this.tradeVolume.mark_price
      // 	);
      // }, 0);
    },
    formatAssetsList() {
      return this.assetsList.map((item) => {
        item.pnl = (item.position_list ?? []).reduce((acc, cur) => {
          const pnl = this.calcPnl(cur);
          return acc + pnl;
        }, 0);

        item.maxAmount =
          item.future_total_amount -
          item.pnl -
          item.future_margin_amount;
        return item;
      });
    },
    toAccountAddress() {
      let address = this.account;//this.walletData.getAddress()
      return address && this.$store.state.wallet.create2Data[address] && this.$store.state.wallet.create2Data[address][this.chainId] && this.$store.state.wallet.create2Data[address][this.chainId].cachedAddress
    },
    maximizedWithdrawAmount() {
      return utils.formatUnits(
        this.futuresTotalBalanceWei - this.futuresLockedBalanceWei - this.futuresMarginBalanceWei + '',
        this.decimals
      );
    }
    // tokenDecimalsMap() {
    // 	let result = {};
    // 	this.tokens.forEach(item => {
    // 		result[item.symbol] = item['decimals'];
    // 	});
    // 	return result;
    // }
  },
  created() {
    if (!this.type) {
      this.chainIdAndLayer = this.$store.state.wallet.walletChainId + "-2";
      this.init();
    }
  },
  methods: {
    toFloor,
    init() {
      // this.fetchPerpetualOverview();
      this.fetchFuturesBalance();
      // positions
      this.fetchPerpetualPositions();

      // assets
      this.fetchPerpetualAssets();
    },
    toggleBalance() {
      this.hiddenBalance = !this.hiddenBalance;
    },
    formatBalance(val) {
      if (this.hiddenBalance) {
        return "******";
      } else {
        return this.formatUSDT(val);
      }
    },
    formatUSDT(value) {
      return toFloor(value, this.balanceTokenDetail.show_scale);
    },

    // async fetchPerpetualOverview() {
    // 	const params = {
    // 		address: this.account,
    // 		chain_id: this.chainId,
    // 		currency: this.currency.name,
    // 	};
    // 	await fetchPerpetualOverview(params)
    // 		.then((res) => {
    // 			if (res.data) {
    // 				this.futureTotalAmount = res.data.future_total_amount;
    // 				this.futureAvailableAmount =
    // 					res.data.future_available_amount;
    // 				this.futureMarginAmount = res.data.future_margin_amount;
    // 			}
    // 		})
    // 		.catch((e) => e);
    // },
    async fetchFuturesBalance() {
      const restUrl = this.layer2Config.rest;
      const account = await this.getAccountAddress()
      const params = {
        url: restUrl,
        address: account,
      };

      // reset balance
      this.futuresTotalBalanceWei = 0;
      this.futuresAvailableBalanceWei = 0;
      this.futuresMarginBalanceWei = 0;
      this.futuresLockedBalanceWei = 0;

      this.loadingBalance = true;
      await fetchPerpetualBalance(params)
        .then((res) => {
          if (res.status == "success") {
            this.futuresTotalBalanceWei =
              filterL2Balance(
                res.result,
                "committed",
                this.balanceSymbol
              ) || "0";
            this.futuresAvailableBalanceWei =
              filterL2Balance(
                res.result,
                "available",
                this.balanceSymbol
              ) || "0";
            this.futuresMarginBalanceWei =
              filterL2Balance(
                res.result,
                "margin",
                this.balanceSymbol
              ) || "0";
            this.futuresLockedBalanceWei =
              filterL2Balance(
                res.result,
                "locked",
                this.balanceSymbol
              ) || "0";
          }
        })
        .catch((e) => e);
      this.loadingBalance = false;
    },

    refreshData(networkAndLayer) {
      console.log('refreshData:', networkAndLayer)
      this.chainIdAndLayer = networkAndLayer
      this.init()
    },

    onChangeChain() {
      this.init();
    },
    onChangeTab(tabId) {
      this.tabId = tabId;
    },

    calcPnl(position) {
      // "side": 1 //方向 0：做多（买）1：做空（卖）;
      // 未實現盈虧 = 持倉數量 * 開倉方向 * （最新價格 - 開倉價格);
      // 開倉方向: 買單爲 1；賣單爲 -1;
      // update market_price => mark_price
      const sideFactor = Number(position.side) === 1 ? -1 : 1;
      return (
        position.size *
        sideFactor *
        (position.mark_price - position.avg_price)
      );
    },

    // positions
    async fetchPerpetualPositions() {
      const account = await this.getAccountAddress()
      const params = {
        address: account,
        chain_id: this.chainId,
        symbol: this.symbol,
      };
      this.positionList = [];
      if (!account) return
      this.assetLoading = true;
      await fetchPerpetualPositions(params)
        .then((res) => {
          if (res.data) {
            this.positionList = res.data;
          }
        })
        .catch((e) => e);
      this.assetLoading = false;
    },
    formatSize(position) {
      const sideFactor = Number(position.side) === 1 ? -1 : 1;
      return position.size * sideFactor;
    },
    formatMarginRatio(value) {
      return toFloor(value * 100, 2) + "%";
    },

    // assets
    async fetchPerpetualAssets() {
      const account = await this.getAccountAddress()
      const params = {
        address: account,
        chain_id: this.chainId,
        // symbol: this.symbol,
      };
      this.assetsList = [];
      if (!params.address) return
      this.assetLoading = true;
      await fetchPerpetualAssets(params)
        .then((res) => {
          if (res.data) {
            this.assetsList = res.data;
          }
        })
        .catch((e) => e);
      this.assetLoading = false;
    },
    calcMarginRatio(item) {
      let marginValBalance = (item.size * item.avg_price)
      let marginIndex = 'max'
      let marginVal = Object.keys(this.marginMap)
      marginVal.map(v => {
        if (marginValBalance < v && marginIndex == 'max') {
          marginIndex = v
        }
      })
      let res = (marginValBalance * this.marginMap[marginIndex].marginRate) / (item.margin + item.pnl * 1) * 100
      return res
    },
    goUrl(url) {
      this.$router.push(url);
    },
    goTransfer(item) {
      console.log(item);
      this.$router.push(
        `/wallet/future/transfer?symbol=${item.symbol}&chainId=${this.chainId}`
      );
    },
    async getAccountAddress() {
      let perpetualAccount = await this.$store.dispatch('getPerpetualAccount', this.chainId)
      console.log('perpetualAccount:', perpetualAccount)
      let account = perpetualAccount && perpetualAccount.create2Address || ''
      return account
    },
    tokenItemDetail(item) {
      return this.$store.getters.commonTokenDetail(
        item.base_symbol,
        this.chainId
      ) || {};
    },
  },
};
