//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FaucetSelect from "./component/FaucetSelect";
import AmountSelect from "./component/AmountSelect";
import NetworkTab from "./component/NetworkTab";
import NetworkSelect from "./component/NetworkSelect.vue";
import { conf } from "../../config";
import {
	getLayer2Balance as fetchLayer2Balance,
	getLayer2Config,
	getFaucetConfig,
	submitFaucet,
	getBridgeToken,
    getMinterAddress
} from "../../api/wallet/index";
import { ethers } from "ethers";
import {
	addZoreFormat,
	getWalletNetWorkConfig,
	getLayer1RpcUrl,
	getChainLayer2Config,
	getChainId,
	isTokenETH,
	filterL2Balance,
    formatFiatCommify,
    transferL2,
} from "../../utils/index";
import { emitEvents } from "../../../../utils";
// const abi = require('./abi.json')
// const PRIVATE_KEY = ('0x72e10fc29436e93f1c974564c6cbec3f2873ded6d9261af06c87be85dad320e4').slice(2);
// const MINTER = '0x6Da573EEc80f63c98b88cED15D32CA270787FB5a';
// import Web3 from "web3"
// const Tx = require('lester-ethereumjs-tx').Transaction;
const addZero = (decimal) => {
	return new Array(decimal).fill(0).join("");
};
export default {
	name: "Faucet",
	components: {
		FaucetSelect,
		AmountSelect,
		NetworkTab,
        NetworkSelect
	},
	provide() {
		return {
			refresh: this.refresh,
		};
	},
	data() {
		return {
			chainName: "",
			bread: [
				{
					path: "/faucet",
					name: "Tools",
				},
				{
					path: "/faucet",
					name: this.$t("faucet_title"),
				},
			],
			coinValue: {
				name: "",
				symbol: "",
                balance: '0.0'
			},
			layer1Balance: "",
			layer2Balance: "",
			loading: false,
			loadingOne: false,
			chainId: "",
			faucetConfigList: [],
			userAddress: "",
			coinData: {},
			isLogined: false,
			userInfo: null,
			amountValue: "",
			amountSelectList: [],
			submitLoading: false,
			errMsg: "",
			addressPlaceholder: this.$t("faucet_address_placeholder"),
			coinSelectLoading: false,
			loadLayer1Failed: false,
			chainTokenArr: {},
			defaultTokenArr: [],
            minter: ''
		};
	},
	computed: {
		layer1NetworkList() {
			return this.$store.getters.layer1NetworkList;
		},
		walletChainId() {
			return this.$store.state.wallet.walletChainId;
		},
		networks() {
			const chainIds = [
				...new Set(this.faucetConfigList.map((item) => item.chainId)),
			];
			return this.layer1NetworkList.filter((item) => {
                item.chainName = item.name
                return chainIds.includes(item.chainId)
            });
		},
		account() {
			return this.$store.getters.account || '';
		},
        faucetType() {
            return this.$store.state.siteConfig.faucetType
        }
	},
	watch: {
		coinValue: function (val, oldVal) {
            if (!oldVal || val.symbol != oldVal.symbol) {
                this.watchCoinValueFunc(val);
            }
		},
		"$store.state.wallet.wallet": {
			handler: function (wallet) {
				this.userAddress = wallet.address;
			},
			deep: true,
		},
		userAddress: function (address) {
			this.isLogined = Boolean(address);
			if (this.isLogined) {
				this.getAllBalance();
			}
		},
	},
	created() {
		this.coinSelectLoading = true;
		getFaucetConfig()
			.then(async (res) => {
				if (res.code === "200") {
					this.faucetConfigList = res.data.config_list;
					this.chainId =
						this.walletChainId || this.networks[0].chainId;
					this.chainName = this.networks[0].name;
                    this.minter = res.data.address
					this.initCoinList();
				}
			}).catch(e => e)
			.finally(() => {
				this.coinSelectLoading = false;
			});
		this.initLoginStatus();
	},
	methods: {
		getLayer1NetworkItem(chainId) {
			return this.layer1NetworkList.find(
				(item) => item.chainId === chainId
			);
		},
		watchCoinValueFunc(val) {
			const currentConfig = this.faucetConfigList.find(
				(config) =>
					config.coinSymbol === val.symbol &&
					parseInt(config.chainId) === parseInt(this.chainId)
			);
			if (currentConfig) {
                let moreHoursConfig = currentConfig.defineDays.split(',').map(v => {
                    return {
                        label: `${v.split('-')[1]} ${this.coinValue.symbol} / ${v.split('-')[0]} days`,
                        value: v.split('-')[1],
                        hours: v.split('-')[0] * 24
                    }
                })

				this.amountSelectList = [
					{
						label: `${currentConfig.eightHours} ${this.coinValue.symbol} / 8 hours`,
						value: currentConfig.eightHours,
						hours: 8,
					},
					{
						label: `${currentConfig.oneDay} ${this.coinValue.symbol} / 1 day`,
						value: currentConfig.oneDay,
						hours: 24,
					},
					{
						label: `${currentConfig.threeDays} ${this.coinValue.symbol} / 3 days`,
						value: currentConfig.threeDays,
						hours: 72,
					},
				].concat(moreHoursConfig).filter(v => v.value);
				const firstData = this.amountSelectList[0];
				this.amountValue = firstData.label;
				this.applyCount = firstData.value;
				this.freezeHours = firstData.hours;
                this.getCoinBalance()
			} else {
				this.amountSelectList = [];
			}
		},
		initCoinList() {
			this.networks.forEach(async (network) => {
				const filterByNetWork = this.faucetConfigList
					.filter(
						(config) =>
							parseInt(config.chainId) ===
							parseInt(network.chainId)
					)
					.map((item) => item.coinSymbol);
				// let data = await this.$store.dispatch('getLayer2TokenList', network.chainId)
				let data = await this.$store.dispatch("getCommonTokenList");
				let dataArr = await this.$store.dispatch("getContratMap");
				console.log("dataArr", dataArr);
				this.coinData[network.chainId] = data[network.chainId].filter((v) => {
					let iscurrentNetwork = filterByNetWork.includes(v.symbol);
					if (iscurrentNetwork) {
						v.address = v.contract_address;
						v.contract_address = v.address;
						v.decimals = v.contract_decimals;
					}
					return iscurrentNetwork;
				});
                console.log('this.coinData:', this.coinData)
				if (network.chainId == this.chainId) {
					this.watchCoinValueFunc(this.coinValue);
				}
			});
		},
		async getCoinListData(chainId) {
			const bridgeHost =
				window.site_config &&
				window.site_config.site_common_bridge_api_url;
			/**
			 * @description bridge接口变动，新增参数coin_type，bridge为 ERC20
			 * @time 2021/11/09
			 * @author Jevin
			 */
			const query = {
				chain_id: chainId,
				coin_type: "ERC20",
				bridgeHost,
			};
			let res = await getBridgeToken(query);
			let resVal = [];
			if (res.code == 200) {
				let resData = res.data && res.data.bridges_token;
				console.log("resData", resData);
				resVal =
					resData &&
					resData.map((v) => {
						let coinDetail = this.getCoinTokenDetail(v.symbol);
						Object.assign(v, coinDetail);
						return v;
					});
			}
			console.log("bridgeToken", resVal);
		},
		getCoinTokenDetail(symbol) {
			return this.defaultTokenArr.find((v) => v.symbol == symbol);
		},
		initLoginStatus() {
			const userInfo = this.walletData.getSyncWallet();
			if (userInfo) {
				this.userInfo = userInfo;
				// this.userAddress = this.walletData.getAddress();
				this.userAddress = this.account;
			}
		},
		changeCoin(item) {
			this.coinValue = item;
			this.errMsg = "";
			this.isLogined && this.getAllBalance();
		},
		async changeChain(chainId) {
			if (this.chainId === chainId) return;
			this.chainId = chainId;
			console.log("chainId", this.chainId);
			this.$store.commit("setCheckDataChanId", this.chainId);
			//   this.getAllBalance()
		},
		async getLayer2Config(chainId) {
			const config = getChainLayer2Config(chainId) || [];
			const reqUrl = config.rest;
			const layer2Config = await getLayer2Config(reqUrl);
			return layer2Config && layer2Config.result;
		},
		// async getLayer1Balance() {
		//   const netConfig = getLayer1RpcUrl(this.chainId) || [];
		//   const web3j = netConfig[0].rpc_url;
		//   try {
		//     this.loadingOne = true;
		//     this.layer1Balance = 0;
		//     const curDataInFaucetList = this.faucetConfigList.find(
		//       item => item.coinSymbol ===
		//         this.coinValue.symbol && item.chainId === parseInt(this.chainId)
		//     )
		//     this.contract = await this.walletUtil.dealWeb3QueryContract(
		//       web3j,
		//       curDataInFaucetList.contractAddress
		//     );
		//     const address = this.walletData.getAddress();
		//     const tx = await this.contract.methods
		//       .balanceOf(address)
		//       .call();
		//     this.layer1Balance = numberFormat(
		//       ethers.utils.formatUnits(
		//         tx.toString(),
		//         this.coinValue.decimals || 18
		//       ),
		//       this.coinValue.show_scale
		//     );
		//     this.loadingOne = false;
		//   } catch (error) {
		//     this.loadingOne = false;
		//   }
		// },
		async getLayer1Balance() {
			let netConfig = getLayer1RpcUrl(this.chainId) || [];
			let web3j = netConfig[0].rpc_url;
			this.loadLayer1Failed = false;
            console.log('faucet:getLayer1Balance', web3j)
			try {
				this.loadingOne = true;
				this.layer1Balance = 0;
				let isTokenNative = await this.$store.dispatch(
					"isTokenNative",
					{
						contractAddress: this.coinValue.contract_address,
						chainId: this.chainId,
					}
				);
				if (
					this.coinValue.token_type === "NATIVE" ||
					isTokenNative
				) {
					let balance = await this.walletUtil.getWeb3Erc20Amount(web3j).then((res) => {
                        this.loadLayer1Failed = false;
                        return res;
                    }).catch((e) => {
                        console.error("getLayer1Balance e1:", e);
                        this.loadLayer1Failed = true;
                    });
					this.layer1Balance = formatFiatCommify(
						ethers.utils.formatUnits(
							balance.toString(),
							this.coinValue.decimals || 18
						),
						this.coinValue.show_scale
					);
					console.log("balance", this.layer1Balance);
				} else {
					console.log('contractAddress', this.coinValue.contract_address);
					this.contract = await this.walletUtil.dealWeb3QueryContract(
						web3j,
						this.coinValue.contract_address
					);
					// let address = this.walletData.getAddress();
					let address = this.account;
					let tx = await this.contract.methods
						.balanceOf(address)
						.call()
						.then((res) => {
							this.loadLayer1Failed = false;
							return res;
						})
						.catch((e) => {
							console.error("getLayer1Balance e2:", e);
							this.loadLayer1Failed = true;
						});

                    console.log('tx:', tx, this.coinValue)
					this.layer1Balance = formatFiatCommify(
						ethers.utils.formatUnits(
							tx.toString(),
							this.coinValue.decimals || 18
						),
						this.coinValue.show_scale
					);
					console.log("totalBalance", this.layer1Balance);
				}
				this.loadingOne = false;
			} catch (error) {
				this.loadingOne = false;
			}
		},
        async getCoinBalance() {
            console.log('getCoinBalance:', this.coinData, this.chainId)
            if (this.chainId) {
                if (this.faucetType == 'L2') {
                    let txBalance = await this.$store.dispatch('getL2Balance', {chainId: this.chainId, address: this.getMinter()})
                    let availableBalances = txBalance.available && txBalance.available.balances || {}
                    console.log('balanceL2:', availableBalances)
                    this.coinData[this.chainId].map((v,i) => {
                        let balance = availableBalances[v.symbol] && formatFiatCommify(
                        ethers.utils.formatUnits(
                            availableBalances[v.symbol].toString(),
                            v.decimals || 18
                        ),
                        v.show_scale) || '0.00'
                        if (this.coinValue.symbol == v.symbol) {
                            this.$set(this.coinValue, 'balance', balance)
                            this.coinValue = Object.assign({}, this.coinValue)
                        }
                        this.coinData[this.chainId].splice(i, 1, Object.assign(v, {balance}))
                    })
                    // console.log('this.coinData:', this.coinData)
                } else {
                    this.coinData[this.chainId].map(async (v,i) => {
                        let balance = await this.getMinterBalance(v).catch(e => 0)
                        if (this.coinValue.symbol == v.symbol) {
                            this.$set(this.coinValue, 'balance', balance)
                            this.coinValue = Object.assign({}, this.coinValue)
                        }
                        this.coinData[this.chainId].splice(i, 1, Object.assign(v, {balance}))
                        console.log('getCoinBalance:', v.symbol, balance, this.coinData)
                    })
                }

            }
        },
		async getMinterBalance(coinDetail) {
			let netConfig = getLayer1RpcUrl(this.chainId) || [];
			let web3j = netConfig[0].rpc_url;
            let minterAddr = this.getMinter()
            if (!minterAddr) return
            let balance = 0
            let isTokenNative = await this.$store.dispatch("isTokenNative",
                {contractAddress: coinDetail.contract_address, chainId: this.chainId}
            );
            if (coinDetail.token_type === "NATIVE" || isTokenNative) {
                balance = await this.walletUtil.getWeb3Erc20Amount(web3j, minterAddr).then((res) => {
                    return res;
                }).catch((e) => {
                    console.error("getMinterBalance e1:", e);
                });
                balance = formatFiatCommify(
                    ethers.utils.formatUnits(
                        balance.toString(),
                        coinDetail.decimals || 18
                    ),
                    coinDetail.show_scale
                );
                console.log("tx-minter:balance", balance);
                return balance
            } else {
                let contract = await this.walletUtil.dealWeb3QueryContract(
                    web3j,
                    coinDetail.contract_address
                );
                let address = minterAddr;
                let tx = await contract.methods
                    .balanceOf(address)
                    .call()
                    .then((res) => {
                        return res;
                    })
                    .catch((e) => {
                        console.error("getLayer1Balance e2:", e);
                    });

                console.log('tx-minter:', tx)
                balance = formatFiatCommify(
                    ethers.utils.formatUnits(
                        tx.toString(),
                        coinDetail.decimals || 18
                    ),
                    coinDetail.show_scale
                );
                console.log("totalBalance", balance);
                return balance
            }
		},
		refresh() {
            setTimeout(() => {
                this.getAllBalance();
                this.getCoinBalance()
            }, 1000)
		},
		async getAllBalance() {
			this.getLayer1Balance();
			this.getLayer2Balance();
		},
		getLayer2Balance() {
			const config = getChainLayer2Config(this.chainId) || [];
			const reqUrl = config.rest;
			const queryLayer2Param = {
				url: reqUrl,
				// address: this.walletData.getAddress(),
				address: this.account,
				type: "balances",
			};
            console.log('faucet:getLayer2Balance', this.chainId, config, queryLayer2Param)
			this.loading = true;
			this.layer2Balance = "";
			if (!queryLayer2Param.address || !queryLayer2Param.url) return;
			fetchLayer2Balance(queryLayer2Param)
				.then((res) => {
					if (res.status == "success") {
						const {
							decimals,
							show_scale: showScale,
							symbol,
						} = this.coinValue;
						const totalBalance = filterL2Balance(
							res.result,
							"committed",
							symbol
						);

						this.layer2Balance = totalBalance
							? formatFiatCommify(
									ethers.utils.formatUnits(
										totalBalance,
										decimals
									),
									showScale
							  )
							: addZoreFormat(showScale);
					}
				}).catch(e => e)
				.finally(() => {
					this.loading = false;
				});
		},
		handleConnectWallet() {
			if (!this.isLogined) {
				emitEvents("linkWallet", () => {
					this.initLoginStatus();
					this.getAllBalance();
				});
			}
		},
		amountChanged(selected) {
			this.amountValue = selected.label;
			this.applyCount = selected.value;
			this.freezeHours = selected.hours;
		},
		async submitClicked() {
			await this.$store.dispatch("fetchNetworkList");
			let netConfig = this.$store.getters.filterNetworkItem(this.chainId, 'L1');
			let web3j = netConfig.rpcUrl;
			let isTokenNative = await this.$store.dispatch("isTokenNative", {
				contractAddress: this.coinValue.contract_address,
				chainId: this.chainId,
			});
            const config = getChainLayer2Config(this.chainId) || [];
			const jsrpc = config.jsrpc;
			const payload = {
                type: this.faucetType,
				chain_id: this.chainId,
				coin_symbol: this.coinValue.symbol,
				coin_name: this.coinValue.name,
				user_address: this.userAddress.toLocaleLowerCase(),
				apply_count: this.applyCount,
				freeze_hours: this.freezeHours,
				contract_address: isTokenNative
					? conf.contractAddressZero
					: this.coinValue.contract_address,
				chain_url: web3j,
                jsrpc_url: jsrpc,
				contract_decimals: isTokenNative
					? 18
					: this.coinValue.contract_decimals,
			};
            console.log('payload', payload)
            // transferL2(payload.jsrpc_url, payload.chain_url, payload.user_address, payload.apply_count, payload.coin_symbol, payload.contract_decimals)
			// // this.transferNativeToken(payload.chain_id, payload.user_address, payload.apply_count, payload.chain_url)
			// return
			this.submitLoading = true;
			submitFaucet(payload)
				.then((res) => {
					if (res.code === "200") {
						if (res.message === "success") {
							this.$message.success(
								this.$t("wallet_deposit_wallet_success")
							);
							this.refresh();
						} else {
							this.errMsg = res.message;
						}
					}
				})
				.catch(() => {
					this.$message.error(this.$t("wallet_unknown_error"));
				})
				.finally(() => {
					this.submitLoading = false;
				});
		},
		async transferNativeToken(
			chainId,
			receiptAddress,
			applyCount,
			chainUrl
		) {
			let web3j = chainUrl.replace("https", "http");
			console.log(chainId, receiptAddress, applyCount, chainUrl);
			const web3 = new Web3(web3j);
			const txCount = await web3.eth
				.getTransactionCount(MINTER)
				.then((txCount) => txCount).catch(e => e);
			const txData = {
				nonce: txCount,
				gasPrice: web3.utils.toHex(web3.utils.toWei("50", "gwei")),
				gasLimit: web3.utils.toHex(91000),
				value: web3.utils.toHex(
					web3.utils.toWei(applyCount + "", "ether")
				),
				to: receiptAddress,
				//   data: _data,
			};

			const tx = new Tx(txData, { chain: chainId });

			const privateKey = Buffer.from(PRIVATE_KEY, "hex");

			tx.sign(privateKey);

			const serializedTx = "0x" + tx.serialize().toString("hex");

			await web3.eth
				.sendSignedTransaction(serializedTx)
				.on("receipt", (receipt) => {
					console.log(receipt);
				});
		},
        changeChain(chainId) {
            this.chainId = chainId;
        },
        getMinter() {
            return this.minter
        }
	},
};
