//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    getChainLayer2Config,
} from "../../utils/index";
import {
    getLayer2Balance,
    snedL2SecretAuth
} from "../../api/wallet/index";
import wallet from '../../plugin/Wallet/walletUtil';
import { ethers } from 'ethers';
export default {
    name: 'Security',
    data() {
        return {
            defaultPublicKey: "sync:0000000000000000000000000000000000000000",
            networks: [],
            activeModal: false,
            curItem: null,

            activeChainId: null,
            activeFeeWei: null,
            activeFeeSymbol: null,

            activing: false,
            tabId: "L2",
            futuresAddress: '',
            activeL2Secret: -1
        };
    },
    computed: {
      themeMode () {
        return this.$store.getters.themeOfRoute(this.$route.name)
      },
        account() {
			return this.$store.getters.account || '';
		},
        supportPerpetual() {
            return this.$store.getters.supportPerpetual;
        },
		layer1NetworkList() {
			return this.$store.getters.layer1NetworkList;
		},
        networkList() {
            return this.networks.map(item => {
                const chainId = Number(item.chainId);
                const layer1Network = this.$store.getters.filterNetworkItem(chainId, 'L1');
                return Object.assign({}, item, {
                    chainId,
                    shortName: layer1Network.shortName,
                })
            })
        },
        modalTitle() {
            if (!this.curItem) return '';
            if (this.getPubKeyHash(this.curItem) === null) return this.$t('wallet_common_create_layer2_account');
            if (this.getPubKeyHash(this.curItem) === this.defaultPublicKey) return this.$t('wallet_withdraw_withdraw_active_account');
        },
        modalContent() {
            if (!this.curItem) return '';
            if (this.activeFeeSymbol) return (`
                <p>${this.$t('wallet_account_active_layer2_account', {type: this.tabId == 'Create2Data' && this.$t('trade_perpetual_futures') || 'L2'})}</p>
                <p>${this.$t('wallet_history_transaction_fee')}: ${this.activeFee} ${this.activeFeeSymbol}</p>
            `);
            let type = this.tabId == 'L2' ? 'L2' : this.$t('wallet_common_futures')
            let text = this.tabId == 'L2' ? this.$t('wallet_common_create_layer2_account_desc', {type}) : this.$t('wallet_common_create_fucture_account_desc')
            return text
        },
        modalSubmitText() {
            if (!this.curItem) return '';
            if (this.activeFeeSymbol) {
                return this.$t('wallet_common_confirm');
            }
            return this.tabId == 'L2' ? this.$t('wallet_common_deposit') : this.$t('wallet_common_transfer');
        },
        tokenDetail() {
            if (!this.activeChainId) return '';
            if (!this.activeFeeSymbol) return '';
            return this.$store.getters['commonTokenDetail'](this.activeFeeSymbol, this.activeChainId);
        },
        activeFee() {
            if (!this.tokenDetail) return '';
            return ethers.utils.formatUnits(this.activeFeeWei, this.tokenDetail.contract_decimals);
        },
        chainId() {
            return this.$store.state.chainId
        },
        accountIsCustody() {
            return this.$store.getters.accountIsCustody
        },
    },
    created() {
        this.$store.dispatch('getCommonTokenList');
        this.initNetwork();
        $Vue.$bus.$on('refresh', events => {
            events.map(type => {
                if (type.includes('activeAccount')) {
                    this.fetchAllPublicKey()
                }
            })
        })
    },
    methods: {
        async getActiveAccountFeeInfo() {
            let syncWallte = this.tabId == 'Create2Data' ? (await this.$store.dispatch('create2SyncWallet', this.chainId)) : null
            await wallet.getActiveAccountFeeInfo(syncWallte).then(res => {
                this.activeFeeWei = res.totalFee;
                this.activeFeeSymbol = res.symbol;
            }).catch(e => {});
        },
        initNetwork() {
            try {
                const networks = JSON.parse(window.site_config.networks);
                this.networks = networks.map(item => Object.assign({}, item, {
                    rest: getChainLayer2Config(item.chainId).rest,
                    // pubKeyHash: null,
                    mainAccountPubKeyHash: null,
                    subAccountPubKeyHash: null,
                    loading: true,
                }));
                this.fetchAllPublicKey();
            } catch (error) {

            }
        },
        async getAccountAddress(chainId) {
            let address = this.account; //this.walletData.getAddress()
            this.futuresAddress = ''
            if (this.tabId == 'Create2Data') {
                let perpetualAccount = await this.$store.dispatch('getPerpetualAccount', chainId)
                address = perpetualAccount && perpetualAccount.create2Address
                this.futuresAddress = address
            }
            return address
        },
        async fetchAllPublicKey() {
            console.log('fetchAllPublicKey:')
            let isMain = this.tabId == 'L2'
            this.networks.map(v => {
                // v.pubKeyHash = null,
                v[isMain ? 'mainAccountPubKeyHash' : 'subAccountPubKeyHash'] = null,
                v.loading = true
            })
            this.$set(this, 'networks', this.networks)
            this.networks.forEach(async (item, index) => {
                let address = await this.getAccountAddress(item.chainId)
                const query = {
                    url: item.rest,
                    address,
                    type: "committed",
                };
                if(!query.address) {
                    this.networks.splice(index, 1, Object.assign({}, this.networks[index], {
                        loading: false,
                    }));
                    return
                }
                // getLayer2Balance有改动
                this.$store.dispatch('getL2AccountIdHash', {
                    chainId: item.chainId,
                    address: address && address.toLowerCase(),
                }).then((res) => {
                // getLayer2Balance(query).then((res) => {
                    if (res) {
                        this.networks.splice(index, 1, Object.assign({}, this.networks[index], {
                            // pubKeyHash: res.result && res.result.pubKeyHash,
                            [isMain ? 'mainAccountPubKeyHash' : 'subAccountPubKeyHash']: res.pubKeyHash,
                            // [isMain ? 'mainAccount' : 'subAccount']: res.result && res.result.address
                            [isMain ? 'mainAccount' : 'subAccount']: address
                        }));
                    } else {
                        this.networks.splice(index, 1, Object.assign({}, this.networks[index], {
                            // pubKeyHash: null,
                            [isMain ? 'mainAccountPubKeyHash' : 'subAccountPubKeyHash']: null,
                            [isMain ? 'mainAccount' : 'subAccount']: ""
                        }));
                    }
                })
                .catch(e => e)
                .finally(res => {
                    this.networks.splice(index, 1, Object.assign({}, this.networks[index], {
                        loading: false,
                    }));
                });
            })
        },
        formatPublicKey(value = '') {
            return (value || '').replace(/^sync\:/, '');
        },
        async onActive(item) {
            if (this.tabId == 'L2') {
                // 走全局激活弹窗,去中心化判断钱包和链是否一致
                if (!this.accountIsCustody) {
                    let walletChainId = await this.$store.state.wallet.walletChainId;
                    console.log("walletChainId:", walletChainId);
                    if (walletChainId != item.chainId) {
                        this.$bus.$emit("changeBridgeNetWork", item.chainId);
                        return;
                    }
                }
                this.$bus.$emit('activeLayer2Account', {
                    // pubKeyHash: item.pubKeyHash,
                    pubKeyHash: this.getPubKeyHash(item)
                });
            } else {
                // futures 保持现在的不变。
                this.activeFeeWei = null;
                this.activeFeeSymbol = null;
                // if (item.pubKeyHash !== null) {
                    // 链不一致，需要切换链。
                    let walletChainId = await this.$store.state.wallet.walletChainId;
                    console.log("walletChainId:", walletChainId);
                    if (walletChainId != item.chainId) {
                        this.$bus.$emit("changeBridgeNetWork", item.chainId);
                        return;
                    }
                // }
                this.activeChainId = item.chainId;
                this.getActiveAccountFeeInfo();
                this.curItem = item;
                this.activeModal = true;
            }
        },
        onActiveSubmit() {
            console.log('onActiveSubmit');
            if (this.activeFeeSymbol) {
                this.activeWalletAccount();
            } else {
                this.activeModal = false;
                if (this.tabId == 'L2') {
                    this.$router.push('/wallet/deposit');
                } else {
                    this.$router.push('/wallet/future/transfer');
                }
            }
        },
        async activeWalletAccount() {
            
            if (this.activing) return;
            this.activing = true;
            // 获取一次最新的fee.
            let syncWallte = this.tabId == 'Create2Data' ? (await this.$store.dispatch('create2SyncWallet', this.chainId)) : null
            // TODO: 修改为全局统一的激活方法。
            await this.getActiveAccountFeeInfo();
            await wallet.activeAccount(activeAccount => {
                console.log('activeAccount:', activeAccount);
                if (activeAccount) {
                    this.fetchAllPublicKey();
                    this.$message.success({
                        content: this.$t('wallet_account_active_success', {type: this.tabId == 'Create2Data' && this.$t('trade_perpetual_futures') || 'L2'}),
                        duration: 5,
                        closable: true
                    });
                } else {
                    this.$message.error({
                        content: this.$t('wallet_account_active_fail', {type: this.tabId == 'Create2Data' && this.$t('trade_perpetual_futures') || 'L2'}),
                        duration: 5,
                        closable: true
                    });
                }
            }, this.activeFeeWei, this.activeFeeSymbol, syncWallte).catch(err => {
                console.log('err', err)
                let errMsg = err && err.data && err.data.message
                if (!errMsg) {
                    errMsg = err.message && err.message.substring(err.message.indexOf(':') + 1, err.message.length)
                }
                this.$message.error({
                    content: errMsg,
                    duration: 5,
                    closable: true
                })
            }).finally(res => {
                this.activeModal = false;
            });
            this.activing = false;
        },
        async snedL2Secret(item, index) {
            // 切换链，链不一致要切换
            if (!this.accountIsCustody) {
                let walletChainId = await this.$store.state.wallet.walletChainId;
                console.log("walletChainId:", walletChainId);
                if (walletChainId != item.chainId) {
                    this.$bus.$emit("changeBridgeNetWork", item.chainId);
                    return;
                }
            }
            console.log('snedL2Secret:', item)
            // 签名
            const Signer = this.walletData.getSyncWallet()
            const L2Secret = Signer?.signer?.signMessagePersonal?.signature
            if (!L2Secret) return
            this.activeL2Secret = index
            snedL2SecretAuth({
                layer2_secret: ethers.utils.sha256(L2Secret),
                chain_id: item.chainId
            }).then(res => {
                this.$Message.success({
                    content: this.$t("wallet_common_activate_l2_secret_successfully"),
				    duration: 3,
				    closable: true
                })
                console.log('res:snedL2Secret', res)
                console.log('res-snedL2SecretAuth:', res)
            }).catch(err => {
                this.$Message.error({
                    content: this.$t("wallet_common_activate_l2_secret_failure"),
				    duration: 3,
				    closable: true
                })
            }).finally(fina => {
                // item.loading = false
                this.activeL2Secret = -1
            })
        },
        getPubKeyHash(item) {
            return this.tabId == 'L2' ? item.mainAccountPubKeyHash : item.subAccountPubKeyHash
        },
        changeTab(key) {
            this.tabId = key;
            this.fetchAllPublicKey();
        }
    }
}
