//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CoinSelect from "./component/CoinSelect";
import CoinChangeBox from "./component/CoinChangeBox";
import BridgeComfirm from "./component/BridgeComfirm";
import NftBridgeComfirm from "./component/NftBridgeComfirm";
import BridgeApprove from "./component/BridgeApprove";
import BridgeHistory from "./component/BridgeHistory";
// import BridgeAddNetwork from './component/BridgeAddNetwork'
import iconDown from "../../assets/img/icon-down.png";
import iconUp from "../../assets/img/icon-up.png";
import {
	emitEvents,
	isUserCenter,
	emitLoading,
	getChainId,
	getLayer1RpcUrl,
	getConfigNetWorks,
	numberFormat,
	addZoreFormat,
    getUTCTime,
    nftTokenURI,
	formatTokenCommify,
} from "../../utils/index";
import { ethers } from "ethers";
import { getHistoryTransaction, sendTransactionRecord } from "../../api/wallet/index";
import { conf } from "../../config/index";
import CommonSelect from "./component/CommonSelect.vue";
import { getBridgeToken, getNftSource } from "../../api/wallet/index";
// import { getQueryChainId } from "../../utils/index";
import _ from "lodash";
let firstInput = false;
export default {
	components: {
		CoinSelect,
		BridgeComfirm,
		CoinChangeBox,
		BridgeApprove,
        BridgeHistory,
		CommonSelect,
		NftBridgeComfirm,
	},
	data() {
		return {
			currentTab: "token",
			showActive: false,
			// showAddNetworkBox: false,
			CoinSelectShow: false,
			viewAllShow: false,
			showNetWorkError: false,
			bread: [
				{
					path: "/wallet",
					name: this.$t("wallet_common_wallet"),
				},
				{
					path: "",
					name: this.$t("wallet_common_bridge"),
				},
			],
			coinValue: {
				name: "Ethereum",
				symbol: "",
			},
			totalBalance: "",
			availableBalance: "",
			lockedBalance: "",
			fee: "",
			data: [],
			curAmount: null,
			amountError: null,
			submitDis: true,
			loading: false,
			decimals: 0,
			amountDecimals: 0,
			disableAmount: false,
			userCenter: isUserCenter(),
			available_balance_loading: true,
			balance_loading: false,
			loading_list: false,
			curAddress: "",
			slectFromWork: {},
			fromList: [],
			slectToWork: {},
			toList: [],
			bridgeObj: {},
			timer: null,
			dealFromObj: {},
			netWork: {},
			defaultChainIdArr: [1, 3, 4, 5, 42],
			walletChanged: false,
			linkArr: {}, // est link
			changeChainIdArr: [], // 当前切换链存储的链id

			// ******** NFT Start ********
			nftContractCollectionsLoading: true,
			nftContractCollectionsExpand: false,
			nftCollectionsLoading: true,
			nftCollectionsExpand: false,
			defaultNftContract: [], // contract-map.json中的NFT部分
			nftContractCollections: [], // NFT合约下拉
			nftContractId: "", // 选中的nft合约id
			nftCollections: [], // 当前合约下的nft list
			nftId: "", // 选中的nft id
			currentNft: {}, // 当前选中的NFT
			showNftToast: false, // NFT Allowance弹窗显示状态
			currentNftApproved: false, // 当前的nft是否已授权
			nftloading: false, // 授权NFTloading
			nftChainArr: {}, // nft链列表
			nftFromList: [], // nft 跨链源list
			nftToList: [], // nft 跨链目标list
			nftSelectFrom: {}, // nft当前选中的源
			nftSelectTo: {}, // nft当前选中的目标
			nftNetworkFee: 0, // nft 跨链network fee
			nftNetworkFeeSymbol: "", // network fee symbol
			nftNetworkFeeStatus: true, // 跨链需要的fee是否满足
			showNftDialog: false, // 显示nft确认弹窗
			nftBridgeObj: {}, // nft 确认框数据
			nftCurrentSwitchChainId: "", // 交换链后当前链id
			nftFromContract: null,
			nftSubmitLoading: false, // nft submit loading
			nftBalance: 0,
			// ******** NFT End ********
			walletChainId: '',
            refreshHistory: '',
            popShowTime: this.$store.state.popShowTime || 2
		}
	},
	computed: {
		/**
		 * 当前选中FNT显示状态
		 */
		showCurrentNft() {
			return !_.isEmpty(this.currentNft);
		},
        uid() {
            let userInfo = this.$store.state.user.userInfo
            let wallet = this.$store.state.wallet.wallet || {}
            return userInfo && userInfo.id && Object.keys(wallet).length || userInfo && userInfo.uid || ''
        },
        supportNFT() {
            return this.$store.getters.supportNFT;
        },
		account() {
			return this.$store.getters.account || '';
		},
	},
	watch: {
		"$store.state.wallet.walletChainId"(val) {
			if (Object.keys(val).length) {
				this.changeCoinCallBack();
			}
		},
		"$store.state.wallet.walletChanged"(val) {
			this.walletChanged = val;
		},
        curAmount(value) {
            if (!firstInput && value.length > 0) {
                firstInput = true;
            }
            if (firstInput) {
                this.checkAmount();
            }
        },
		availableBalance() {
            if (firstInput) {
                this.checkAmount();
            }
		},
        uid(val) {
            console.log('uidchange------')
            this.init()
            this.changeCoinCallBack();
        }
	},
	async created() {
        this.init()
	},
	methods: {
        async init() {
			// 解决sentry报错，wallet可能为undefined问题
			const wallet = await this.web3Wallet.get();
			if (!wallet || !wallet.eth) {
				console.log('wallet', wallet);
				return;
			}
            this.walletChainId = await wallet.eth.getChainId();
            if (!this.walletChainId) return
            this.walletChanged = false;
            let searchBridge = sessionStorage.getItem('searchBridge')
            let searchInfo = searchBridge && JSON.parse(searchBridge) || {}
            if (Object.keys(searchInfo).length) {
                this.currentTab = searchInfo.type || 'token'
                this.coinValue.name = this.$route.query.name || searchInfo.symbol || '';
                // this.curAddress = this.walletData.getAddress()
                this.curAddress = this.account
                this.changeChainIdArr = searchInfo.changeChainIdArr.filter(v => v) || []
                this.changeCurrentTab(this.currentTab, searchInfo.nftCurrentSwitchChainId, searchInfo.nftContractId)
            } else {
                this.coinValue.name = this.$route.query.name || ''
                // this.curAddress = this.walletData.getAddress()
                this.curAddress = this.account
            }
        },
		async initData(token) {
			this.amountError = "";
			this.curAmount = "";
            this.disableAmount = false;
            // this.$nextTick(() => {
            //     this.$refs.bridgeApprove.getAllowance()
            // })
			if (!this.walletData.getSyncWallet()) {
				this.walletData.init({
					successFn: async () => {
						this.getBalance(token);
						this.refreshHistory = +new Date()
						this.getNetWork();
					},
					loading: () => {},
					loadingEnd: () => {},
				});
			} else {
				let walletChainId = getChainId();

				if (this.slectFromWork.chain_id == walletChainId) {
					this.getBalance(token);
				} else {
					this.getChainBalance(token);
				}
				this.getNetWork();
                this.refreshHistory = +new Date()
			}
		},

		async getChainBalance(token) {
			console.log("getChainBalance", token);
			this.getChainWalletBalance();
		},
		async getBalance(token, fn) {
			console.log("getBalance");
			this.available_balance_loading = true;
            this.balance_loading = true
			// this.curAddress = this.walletData.getAddress()
			if (!Object.keys(this.slectFromWork).length) {
				return;
			}

			this.$store.dispatch("linkBridge", {
					contractAddress: this.slectFromWork.coin_token,
				})
				.then(async (contract) => {
                    this.getChainBalance(token);
				})
				.catch((err) => {
					console.log("linkerr", err);
					this.available_balance_loading = false;
                    this.balance_loading = false
				})
		},
		getL1Balance() {
			this.totalBalance = this.availableBalance;
			this.balance_loading = false;
		},
		disableAmountFun(val) {
			this.disableAmount = val;
		},
		// changeCoin
		changeCoin(item) {
            this.disableAmount = false;
            this.amountError =  ''
            this.curAmount = "";
            firstInput = false;
			this.changeCoinCallBack(item);
		},
		changeCoinCallBack(item) {
			item = item || this.coinValue;
			if (this.coinValue.name != item.name) {
				this.available_balance_loading = true;
                this.balance_loading = true;
				this.availableBalance = 0;
				this.lockedBalance = 0;
			}
			this.coinValue = item;
			this.fromList = [];
			this.toList = [];
			this.linkArr = {};
            let currentChainId = this.$store.state.chainId
			item.bridge_resources &&
				item.bridge_resources.map((v) => {
					let isDestinationChain = currentChainId != v.chain_id
					let fromIcon = this.getNetworkIcon(v.chain_id);
					let toIcon = this.getNetworkIcon(v.destination_chain_id)
						.icon;
					let fromItem = {
						originChainId: v.chain_id, // 记录原始链 id 用来区别跨链费。
						symbol: !isDestinationChain ? v.token : v.refer_token,
						icon_url: !isDestinationChain ? fromIcon : toIcon,
						network: !isDestinationChain
							? v.network
							: v.refer_network,
						chain_id: !isDestinationChain
							? v.chain_id
							: v.destination_chain_id,
						resource_id: v.resource_id,
						bridge_address: !isDestinationChain
							? v.bridge_contact_address
							: v.bridge_refer_contact_address,
						coin_token: !isDestinationChain
							? v.token_contact_address
							: v.refer_token_contact_address,
						handler_address: !isDestinationChain
							? v.handler_address
							: v.refer_handler_address,
						bridge_fee: v.bridge_fee,
						refer_bridge_fee: v.refer_bridge_fee,
						destination_chain_id: !isDestinationChain
							? v.destination_chain_id
							: v.chain_id,
						token_type: this.coinValue.token_type,
						type: "from",
                        resource_id: v.resource_id
					};
					let toItem = {
						originChainId: v.chain_id,
						symbol: !isDestinationChain ? v.refer_token : v.token,
						icon_url: !isDestinationChain ? toIcon : fromIcon,
						network: !isDestinationChain
							? v.refer_network
							: v.network,
						chain_id: !isDestinationChain
							? v.destination_chain_id
							: v.chain_id,
						resource_id: v.resource_id,
						bridge_address: !isDestinationChain
							? v.bridge_refer_contact_address
							: v.bridge_contact_address,
						coin_token: !isDestinationChain
							? v.refer_token_contact_address
							: v.token_contact_address,
						handler_address: !isDestinationChain
							? v.refer_handler_address
							: v.handler_address,
						bridge_fee: v.bridge_fee,
						refer_bridge_fee: v.refer_bridge_fee,
						destination_chain_id: !isDestinationChain
							? v.chain_id
							: v.destination_chain_id,
						token_type: this.coinValue.token_type,
						type: "to",
                        resource_id: v.resource_id
					};
					if (!this.linkArr[fromItem.network]) {
						this.linkArr[fromItem.network] = [toItem.network];
					} else {
						if (
							this.linkArr[fromItem.network].indexOf(
								toItem.network
							) == -1
						) {
							this.linkArr[fromItem.network].push(toItem.network);
						}
					}

					if (!this.linkArr[toItem.network]) {
						this.linkArr[toItem.network] = [fromItem.network];
					} else {
						if (
							this.linkArr[toItem.network].indexOf(
								fromItem.network
							) == -1
						) {
							this.linkArr[toItem.network].push(fromItem.network);
						}
					}

                    let hasFrom = this.fromList.some(v => v.network == fromItem.network)
                    if (!hasFrom) this.fromList.push(fromItem);

                    let toFrom = this.toList.some(v => v.network == toItem.network)
                    if (!toFrom) this.toList.push(toItem);

				});
            console.log('this.fromList:',  this.fromList)
            console.log('this.toList:',  this.toList)
			this.defaultSelectFunc();
			this.initData(item.symbol);
			this.getAmoutDecimals(item);
		},
        getNetworkByChainId(chainId, layer) {
            let networks = this.$store.state.coin.networkList || []
            let currentNetwork = networks.find(v => v.chainId == chainId  &&  (layer  ? layer ==  v.layer : !layer)) || {}
            return  currentNetwork
        },
        getNetworkIcon(chainId) {
            let currentNetwork = this.getNetworkByChainId(chainId)
            return currentNetwork?.networkLogo
        },
		defaultSelectFunc() {
			// console.log('linkArr:', JSON.stringify(this.linkArr));
			// this.changeChainIdArr = [
			// 	this.slectFromWork.chain_id,
			// 	this.slectToWork.chain_id,
			// ];
			if (!this.changeChainIdArr.length) {
				let defNet = Object.keys(this.linkArr)[0];
				this.slectFromWork = this.fromList.find((v) => {
					return v.network == defNet;
				});
                // todo: update 切换链不存在交易对时需要处理
				// https://zstarter.sentry.io/issues/4240999003/?project=4505084419833856&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=14d&stream_index=24
				// 处理this.linkArr[defNet]没有情况
				const arr = this.linkArr[defNet];
				if (arr && Array.isArray(arr) && arr.length) {
					let defToNet = arr[0];
					this.slectToWork = this.toList.find((v) => {
						return v.network == defToNet;
					});
				}
			} else {
				let linkList = [].concat(this.fromList, this.toList);
				this.slectFromWork = linkList[0];
				this.slectToWork = linkList[1];
			}
            this.setSearchKey();
		},
		// 切换链
		switchChain() {
			[this.slectFromWork, this.slectToWork] = [
				this.slectToWork,
				this.slectFromWork,
			];
            firstInput = false
			this.amountError = "";
			this.curAmount = "";
            this.disableAmount = false;
			this.changeChainId = this.slectFromWork.chain_id;
			this.changeChainIdArr = [
				this.slectFromWork.chain_id,
				this.slectToWork.chain_id,
			];
			this.initData(this.coinValue.symbol);
            this.setSearchKey()
		},
		async changeBridgeNetWork() {
			let chainId = this.slectFromWork.chain_id
			this.$bus.$emit("changeBridgeNetWork", chainId);
		},
		changeChainIndex(obj) {
			let { type, item } = obj;
            let netWorks = [].concat(this.fromList, this.toList);
			if (
				(this.slectFromWork.network == item && type == "from") ||
				(this.slectToWork.network == item && type == "to")
			)
				return;
            // todo网络判断 update 更新切换整理
            // 右侧
            if (type == 'to') {
                let fromNetwork = this.slectFromWork.network
                if (fromNetwork == item) {
                    [this.slectFromWork, this.slectToWork] = [this.slectToWork, this.slectFromWork]
                } else {
                    this.slectToWork = netWorks.find((v) => {
                        return v.network == item;
                    })
                }
            }
            // 左侧
            if (type == 'from') {
                let toNetwork = this.slectToWork.network
                if (toNetwork == item) {
                    [this.slectFromWork, this.slectToWork] = [this.slectToWork, this.slectFromWork]
                } else {
                    this.slectFromWork = netWorks.find((v) => {
                        return v.network == item;
                    })
                }
            }

            // if (this.slectFromWork.network == item && type == "to" || this.slectToWork.network == item && type == "from") {
            //     let from = type == "from" ? "slectFromWork" : "slectToWork";
            //     let to = type !== "from" ? "slectFromWork" : "slectToWork";

            //     let fromNetwork = this[from].network
            //     let toNetwork = this[to].network
            //     this[from] = netWorks.find((v) => {
            //         return v.network == item;
            //     });
            //     let defToNet = this.linkArr[item][0];
            //     this[to] = netWorks.find((v) => {
            //         return v.network == defToNet;
            //     });
            //     this.changeChainIdArr = [
            //         this.slectFromWork.chain_id,
            //         this.slectToWork.chain_id,
            //     ];
            // } else {
            //     let updateKey = type == "from" ? "slectFromWork" : "slectToWork";
            //     // let updateToKey = type == "to" ? "slectToWork" : "slectFromWork";
            //     this[updateKey] = netWorks.find((v) => {
            //         return v.network == item;
            //     })
            //     this.changeChainIdArr = [
            //         this.slectFromWork.chain_id,
            //         this.slectToWork.chain_id,
            //     ]
            // }

			this.initData(this.coinValue.symbol);
            this.setSearchKey()
			this.getNetWork();
		},
		async getChainWalletBalance() {
			let chainId = this.getChainFromWorkId();
			let config = getLayer1RpcUrl(chainId) || [];
			console.log("config", config);
            
            if (!config.length) {
                const networkDetail = this.getNetworkByChainId(chainId,  'L1')
                console.log('networkDetail:', networkDetail)
                networkDetail.rpc_url = networkDetail.rpcUrl
                config = [networkDetail]
            }
			let web3j = config[0].rpc_url;
			let coinDetail = await this.$store.dispatch(
				"getContratTokenDetailBySymbol",
				{
					chainId,
					symbol: this.coinValue.symbol,
				}
			);
			let dealCoinValue = Object.assign({}, this.coinValue, {
				token_type: coinDetail.token_type,
			});
			this.slectFromWork.token_type = coinDetail.token_type;
			try {
				this.available_balance_loading = true;
                this.balance_loading = true;
				this.availableBalance = 0;
				if (
					dealCoinValue.token_type == "NATIVE"
                    // ||
					// (this.defaultChainIdArr.indexOf(chainId) !== -1 &&
					// 	this.coinValue.symbol == "ETH")
				) {
					let balance = await this.walletUtil.getWeb3Erc20Amount(
						web3j
					);
					// this.availableBalance = ethers.utils.formatUnits(
					// 	balance.toString(),
					// 	dealCoinValue.contract_decimals || 18
					// );
					this.availableBalance =
						(balance.toString() &&
							formatTokenCommify(
								ethers.utils.formatUnits(
                                    balance.toString(),
                                    dealCoinValue.contract_decimals
                                ),
								dealCoinValue.show_scale
							));
					console.log("availableBalance", this.availableBalance);
				} else {
					this.contract = await this.walletUtil.dealWeb3QueryContract(
						web3j,
						this.slectFromWork.coin_token
					);
					// let address = this.walletData.getAddress();
					let address = this.account;
					let tx = await this.contract.methods
						.balanceOf(address)
						.call();
					// this.availableBalance = ethers.utils.formatUnits(
					// 	tx.toString(),
					// 	dealCoinValue.contract_decimals || 18
					// );
                    // console.log('this.availableBalance', this.availableBalance)
					this.availableBalance =
						(tx.toString() &&
							formatTokenCommify(
                                ethers.utils.formatUnits(
                                    tx.toString(),
                                    dealCoinValue.contract_decimals
                                ),
                                dealCoinValue.show_scale
							));
					console.log("totalBalance", this.availableBalance);
				}
				this.available_balance_loading = false;
				this.balance_loading = false;
			} catch (error) {
				console.log("error", error);
				this.available_balance_loading = false;
				this.balance_loading = false;
			}
		},
		getAmoutDecimals(item) {
			this.amountDecimals = item.show_scale;
		},
		curAmountInput(e) {
			this.amountError = null;
			let num = this.amountDecimals;
			let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
			let value = e.target.value;
			this.curAmount = value.replace(reg, "$1.$2");
			// e.target.value = this.curAmount;
		},
		checkAmount() {
			let curAmount = Number(this.curAmount);
			let fee = this.slectFromWork.fee;
			let availableBalance = (this.availableBalance + "").replace(/\,/g, "") * 1;
            if (!this.curAmount) {
                this.amountError = this.$t("wallet_withdraw_message_error_amountError");
            } else if (!(Number(this.curAmount) > 0)) {
                this.amountError = this.$t("wallet_withdraw_message_error_invalid");
            } else if (Number(curAmount) > availableBalance) {
				this.amountError = this.$t(
					"wallet_transfer_message_error_insufficientError"
				);
			} else if (Number(curAmount) < fee) {
				this.amountError = this.$t(
					"wallet_bridge_transfer_msg_low_fee"
				);
			}
			// else if(Number(curAmount) > availableBalance || this.slectFromWork.chain_id == this.testVmChainId && (Number(curAmount) + Number.EPSILON > availableBalance - fee) || Number(curAmount) < fee ) {
			//     this.amountError = `Insufficient ${this.coinValue.symbol} balance`;
			// }
			else {
				this.amountError = null;
			}
			if (!this.amountError) {
				this.setsubmitDis();
			}
		},
		setsubmitDis() {
			let fee = this.slectFromWork.fee;
			let availableBalance =
				(this.availableBalance + "").replace(/\,/g, "") * 1;
			if (
				Number(this.curAmount) > availableBalance ||
				Number(this.curAmount) < fee
			) {
				// if(this.slectFromWork.chain_id == this.testVmChainId && ((Number(this.curAmount) + Number.EPSILON > availableBalance - fee) || this.curAmount < this.slectFromWork.fee)) {
				// this.$Message.error({
				//     content: this.$t('wallet_withdraw_message_error_insufficientError', {symbol : this.symbol}), // 带参数的模板
				//     duration: 10,
				//     onClose: () => {
				//     },
				//     closable: true
				// })
				this.submitDis = true;
				return;
			}
			this.submitDis = false;
		},
		async goSubmitActive() {
			let chainId = this.slectFromWork.chain_id;
			let walletChainId = await this.web3Wallet.get().eth.getChainId();
			if (walletChainId != chainId) {
				return this.changeBridgeNetWork();
			}
			if (this.submitDis || !this.disableAmount) {
				this.showActive = !true;
			} else {
				this.bridgeObj = {
					amount: this.curAmount,
					symbol: this.coinValue.symbol,
					fee: this.slectFromWork.fee,
					decimals: this.coinValue.show_scale,
					markTip: this.defaultChainIdArr.indexOf(this.slectFromWork.chain_id) >= 0 ? "ERC20" : this.slectFromWork.network,
				};
				this.showActive = true;
				this.loading = false;
			}
		},
		goSubmit() {
			if (this.submitDis && !this.disableAmount) {
				return;
			}
			let chain_id = this.slectToWork.chain_id;
			let params = {
				address: this.curAddress,
				amount: this.curAmount,
				token: this.coinValue.symbol,
				chain_id: chain_id,
				contract_decimals: this.coinValue.contract_decimals,
			};
			console.log("params", params);
			if (!this.walletData.getSyncWallet()) {
				this.walletData.init({
					successFn: () => {
						this.transferBridge(params);
						// this.getHistoryTransaction()
					},
					loading: () => {},
					loadingEnd: () => {},
				});
			} else {
				this.transferBridge(params);
			}
		},
		async activeAccount() {
			let syncWallet = this.walletData.getSyncWallet();
			const changePubkey = await syncWallet.setSigningKey({
				ethAuthType: "ECDSA",
				feeToken: "ETH",
			});
			await changePubkey.awaitReceipt();
			this.showActive = false;
		},
		async isActive() {
			let syncWallet = this.walletData.getSyncWallet();
			let isActive = false;
			if (!(await syncWallet.isSigningKeySet())) {
				// if ((await syncWallet.getAccountId()) == undefined) {
				// 	this.$router.push({
				// 		path: "/walletconnect/deposit",
				// 	});
				// }
				isActive = false;
			} else {
				isActive = true;
			}
			return isActive;
		},
		async transferOrActive() {
			let activeAcount = await this.isActive();
			if (activeAcount) {
				this.transferBridge();
				// this.$store.dispatch("getCommonTokenList").then((res) => {
				// 	let dealCoin = this.$store.getters["commonTokenDetail"](
				// 		this.symbol
				// 	);
				// 	this.decimals = dealCoin.contract_decimals;
				// 	this.transferBridge();
				// });
			} else {
				emitEvents("linkLogin", { type: "active_account" });
			}
		},
		async transferBridge(param) {
			if (this.loading) return;
			this.loading = true;
			console.log("deposite", param);
			param.contract_decimals = this.coinValue.contract_decimals;
			let payResult = await this.web3EthUtil.depositToSyncFromEthereum(
				param,
				this.slectFromWork
			);
            let tx = await payResult.wait();
            console.log('payResult--=--', tx)
			if (tx && Object.keys(tx).length) {
				this.$Message.success({
					content: this.$t("wallet_bridge_transfer_msg_success"),
					duration: this.popShowTime,
					closable: true,
				});
				this.loading = false;
				this.showActive = false;
				this.getRefreshBalance();
                this.saveRecordData(tx, this.curAmount)
                this.curAmount = "";
			} else {
				this.$Message.error({
					content: this.$t("wallet_bridge_transfer_msg_fail"),
					duration: this.popShowTime,
					closable: true,
				});
				this.loading = false;
				this.showActive = false;
			}
		},
        // save--
        async saveRecordData(receipt, amount) {
            // let address = this.walletData.getAddress()
            let address = this.account
            amount = amount || this.curAmount
            let coinDetail = this.$store.getters["commonTokenDetail"](this.coinValue.symbol, this.slectFromWork.chain_id);
            if (!receipt.transactionHash) return
            let blockNumber = receipt.blockNumber
            let params = {
                submit_block: blockNumber,
                from_address: address,
                from_chain_id: this.slectFromWork.chain_id,
                layer: 'L1',
                from_network: this.slectFromWork.network,
                from_tx_hash: receipt.transactionHash,
                to_address: address,
                to_chain_id: this.slectToWork.chain_id,
                to_network: this.slectToWork.network,
                token_address: this.coinValue.contract_address,
                token_type: coinDetail.token_type == 'NATIVE' ? 'ERC20' : coinDetail.token_type,
                status: 'Pending',
                type: 'Bridge',
                date: getUTCTime(new Date()),
                symbol: this.coinValue.symbol,
                amount: amount * 1,
                action_symbol: '-'
            }
            this.$store.dispatch('sendTransactionRecord', {
                params,
                callback: () => {
                    this.refreshHistory = +new Date()
                    // console.log('saveData--success')
                }
            })
        },
		getRefreshBalance() {
			clearTimeout(this.timer);
			this.timer =
				this.lockedBalance &&
				setTimeout(function() {
					this.getBalance(this.coinValue.symbol, () => {
						this.getL1Balance();
					});
					this.getRefreshBalance();
				}, 10 * 1000);
			if (!this.lockedBalance) {
				clearTimeout(this.timer);
				this.timer = null;
				this.getBalance();
			}
		},
		toAll() {
			let num = this.amountDecimals;
			let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
			this.curAmount =
				(this.availableBalance + "")
					.replace(/\,/g, "")
					.replace(reg, "$1.$2") * 1;
			this.checkAmount();
		},
		toHistory() {
			this.$router.push({
				path: "history",
				query: {
					transaction_type: "withdraw",
					coin_name: this.coinValue.name,
				},
			});
		},
		copy() {
			this.$Message.config({
				top: 290,
				duration: this.popShowTime,
			});
			this.$Message.success({
				content: this.$t("wallet_common_copied"),
				duration: this.popShowTime,
				closable: true,
			});
		},
		getChainFromWorkId() {
			// return this.slectFromWork.chain_id == this.testVmChainId
			// 	? this.testVmChainId
			// 	: this.slectFromWork.chain_id;
            return this.slectFromWork.chain_id
		},
		async getNetWork() {
			this.netWork = await this.walletUtil.getNetwork();
			this.slectFromWork.fee = (this.slectFromWork.originChainId === this.slectFromWork.chain_id ? this.slectFromWork.bridge_fee : this.slectFromWork.refer_bridge_fee) || '0.0';
			console.log("this.slectFromWork.fee", this.slectFromWork.fee);
		},
		async addNetWork() {
			this.$bus.$emit("addNetWorkVm", this.slectFromWork.destination_chain_id)
		},

		// ************ NFT Start ************
		/**
		 * 切换当前tab
		 */
		changeCurrentTab(val, chainId, contractId) {
			this.currentTab = val;
			if (val === "token") {
				this.clearNftCache(true);
                this.nftContractCollectionsExpand = false;
                this.nftCollectionsExpand = false;
			} else {
				this.nftCurrentSwitchChainId = chainId || window.ethereum && parseInt(window.ethereum.chainId);
                this.nftContractId = contractId
				this.getNftConllections();
			}
            this.setSearchKey()
		},
		/**
		 * 获取NFT Collections
		 */
		getNftConllections() {
			this.nftContractCollectionsLoading = true;
			const chainId = this.nftCurrentSwitchChainId || getChainId();
			console.log("chainId", chainId, typeof chainId);
			const bridgeHost = window.site_config && window.site_config.site_common_bridge_api_url;
			const params = {
				chain_id: chainId,
				coin_type: "ERC721",
				bridgeHost,
			};
			getBridgeToken(params)
				.then((res) => {
					console.log("res", res);
					const { code, data } = res;
					if (code === "200") {
						const { bridges_token } = data;
						const list = [];
						let defaultNftContract =
							this.$store.state.coin.contratMap[chainId] ||
							[];
						defaultNftContract = defaultNftContract.filter(
							(o) => o.token_type === "ERC721"
						);
						this.defaultNftContract = defaultNftContract;
						_.forEach(bridges_token, (item, index) => {
							const { bridge_resources, symbol } = item;
							const detail = this.getNftContractDetail(symbol);
							list.push({
								id: index + 1,
								icon_url: detail && detail.icon_url || "",
								name: symbol,
								bridge_resources,
							});
						});
						this.nftContractCollections = list;

						const nftFromList = [],
							nftToList = [],
							nftChainArr = {};
						const netWorkList = [];
						_.forEach(list, (item) => {
							_.forEach(item.bridge_resources, (itemBridge) => {
								const {
									chain_id,
									destination_chain_id,
								} = itemBridge;
								const owned = this.checkAlreadyOwned(
									chain_id,
									destination_chain_id,
									netWorkList
								);
								if (!owned) {
									const isDestinationChain =
										(window.ethereum &&
											parseInt(window.ethereum.chainId) ==
												itemBridge.destination_chain_id) ||
										false;
									const fromIcon = this.getNetworkIcon(
										itemBridge.chain_id
									);
									const toIcon = this.getNetworkIcon(
										itemBridge.destination_chain_id
									);
									const fromItem = {
										originChainId: itemBridge.chain_id,
										fee: !isDestinationChain ? itemBridge.bridge_fee : itemBridge.refer_bridge_fee,
										symbol: !isDestinationChain
											? itemBridge.token
											: itemBridge.refer_token,
										icon_url: !isDestinationChain
											? fromIcon
											: toIcon,
										network: !isDestinationChain
											? itemBridge.network
											: itemBridge.refer_network,
										chain_id: !isDestinationChain
											? itemBridge.chain_id
											: itemBridge.destination_chain_id,
										resource_id: itemBridge.resource_id,
										bridge_address: !isDestinationChain
											? itemBridge.bridge_contact_address
											: itemBridge.bridge_refer_contact_address,
										coin_token: !isDestinationChain
											? itemBridge.token_contact_address
											: itemBridge.refer_token_contact_address,
										handler_address: !isDestinationChain
											? itemBridge.handler_address
											: itemBridge.refer_handler_address,
										bridge_fee: itemBridge.bridge_fee,
										destination_chain_id: !isDestinationChain
											? itemBridge.destination_chain_id
											: itemBridge.chain_id,
										token_type: this.coinValue.token_type,
										type: "from",
									};
									const toItem = {
										originChainId: itemBridge.chain_id,
										fee: !isDestinationChain ? itemBridge.refer_bridge_fee : itemBridge.bridge_fee,
										symbol: !isDestinationChain
											? itemBridge.refer_token
											: itemBridge.token,
										icon_url: !isDestinationChain
											? toIcon
											: fromIcon,
										network: !isDestinationChain
											? itemBridge.refer_network
											: itemBridge.network,
										chain_id: !isDestinationChain
											? itemBridge.destination_chain_id
											: itemBridge.chain_id,
										resource_id: itemBridge.resource_id,
										bridge_address: !isDestinationChain
											? itemBridge.bridge_refer_contact_address
											: itemBridge.bridge_contact_address,
										coin_token: !isDestinationChain
											? itemBridge.refer_token_contact_address
											: itemBridge.token_contact_address,
										handler_address: !isDestinationChain
											? itemBridge.refer_handler_address
											: itemBridge.handler_address,
										bridge_fee: itemBridge.bridge_fee,
										destination_chain_id: !isDestinationChain
											? itemBridge.chain_id
											: itemBridge.destination_chain_id,
										token_type: this.coinValue.token_type,
										type: "to",
									};
									netWorkList.push(fromItem);
									netWorkList.push(toItem);

									if (!nftChainArr[fromItem.network]) {
										nftChainArr[fromItem.network] = [
											toItem.network,
										];
									} else {
										if (
											nftChainArr[
												fromItem.network
											].indexOf(toItem.network) == -1
										) {
											nftChainArr[fromItem.network].push(
												toItem.network
											);
										}
									}

									if (!nftChainArr[toItem.network]) {
										nftChainArr[toItem.network] = [
											fromItem.network,
										];
									} else {
										if (
											nftChainArr[toItem.network].indexOf(
												fromItem.network
											) == -1
										) {
											nftChainArr[toItem.network].push(
												fromItem.network
											);
										}
									}
								}
							});
						});
						console.log("netWorkList", netWorkList);

						_.forEach(netWorkList, (item) => {
							if (item.type === "from") {
								nftFromList.push(item);
							}
							if (item.type === "to") {
								nftToList.push(item);
							}
						});
						this.nftChainArr = nftChainArr;
						this.nftFromList = nftFromList;
						this.nftToList = nftToList;

						_.forEach(nftFromList, (item) => {
							if (item.chain_id == chainId) {
								this.nftSelectFrom = item;
							} else if(item.destination_chain_id == chainId)(
                                this.nftSelectTo = item
                            )
						});
						_.forEach(nftToList, (item) => {
                            if (item.chain_id == chainId) {
								this.nftSelectFrom = item;
							} else if(item.destination_chain_id == chainId)(
                                this.nftSelectTo = item
                            )
						});
						this.setNftNetWorkFeeSymbol();
						this.nftContractCollectionsLoading = false;
					}
				})
				.catch((err) => {
					console.error(err);
					this.nftContractCollectionsLoading = false;
				}).finally(fina => {
                    if (this.nftContractCollections.length && this.nftContractId) {
                        let data = this.nftContractCollections.find(v => {
                            return v.id == this.nftContractId
                        })
                        if (data) {
                            this.selectNftContract(data)
                        }
                    }
                })
		},
		/**
		 * set nft network fee symbol
		 */
		setNftNetWorkFeeSymbol() {
			const { chain_id } = this.nftSelectFrom;
			const tokenList =
				this.$store.state.coin.contratMap[chain_id] || [];
			const token_type = _.find(
				tokenList,
				(o) => o.token_type === "NATIVE"
			);
			this.nftNetworkFeeSymbol = (token_type && token_type.symbol) || "";
		},
		/**
		 * 判定是否已有跨链对
		 */
		checkAlreadyOwned(chain_id, destination_chain_id, netWorkList) {
			let status = false;
			_.forEach(netWorkList, (item) => {
				if (
					item.chain_id === chain_id &&
					item.destination_chain_id === destination_chain_id
				) {
					status = true;
				}
			});
			return status;
		},
		/**
		 * 获取nft合约详情
		 */
		getNftContractDetail(symbol) {
			return this.defaultNftContract.find((o) => o.symbol === symbol);
		},
		/**
		 * 选择NFT合约
		 */
		async selectNftContract(data) {
			this.nftNetworkFeeStatus = true;
			this.nftCollectionsLoading = true;
			console.log("data--===---12345", data);
			// 切换NFT合约 初始化数据
			this.clearNftCache();

			// 链不一致，需要切换链。
			const { bridge_resources } = data;
			if (this.walletChainId === this.nftSelectFrom.chain_id) {
				// 切换合约，处理nftSelectFrom、nftSelectTo里的resource_id、coin_token
				_.forEach(bridge_resources, (item) => {
					if (item.chain_id === this.nftSelectFrom.chain_id && item.destination_chain_id === this.nftSelectTo.chain_id) {
						this.nftSelectFrom.resource_id = item.resource_id;
						this.nftSelectFrom.coin_token =
							item.token_contact_address;
                        this.nftSelectFrom.symbol = item.token;
                        this.nftSelectFrom.coin_name = data.name
						this.nftSelectTo.resource_id = item.resource_id;
						this.nftSelectTo.coin_token =
							item.refer_token_contact_address;
                        this.nftSelectTo.coin_name = data.name
					}
					if (
						item.chain_id === this.nftSelectTo.chain_id && item.destination_chain_id === this.nftSelectFrom.chain_id
					) {
						this.nftSelectFrom.resource_id = item.resource_id;
						this.nftSelectFrom.coin_token =
							item.refer_token_contact_address;
                        this.nftSelectFrom.symbol = item.token;
                        this.nftSelectFrom.coin_name = data.name
						this.nftSelectTo.resource_id = item.resource_id;
						this.nftSelectTo.coin_token =
							item.token_contact_address;
                        this.nftSelectTo.coin_name = data.name
					}
				});
				// 当前账户链与跨出链相同的逻辑
				const { coin_token, symbol } = this.nftSelectFrom;
				const params = {
					contractAddress: coin_token,
					symbol,
				};
				console.log("params", params);
				this.checkNftFromContract(params);
			} else {
				// 合约，处理nftSelectFrom、nftSelectTo里的resource_id、coin_token
				_.forEach(bridge_resources, (item) => {
					if (item.chain_id === this.nftSelectFrom.chain_id && item.destination_chain_id === this.nftSelectTo.chain_id) {
						this.nftSelectFrom.resource_id = item.resource_id;
						this.nftSelectFrom.coin_token =
							item.token_contact_address;
                        this.nftSelectFrom.symbol = item.token;
                        this.nftSelectFrom.coin_name = data.name
						this.nftSelectTo.resource_id = item.resource_id;
						this.nftSelectTo.coin_token =
							item.refer_token_contact_address;
                        this.nftSelectTo.coin_name = data.name
					}
					if (
						item.chain_id === this.nftSelectTo.chain_id && item.destination_chain_id === this.nftSelectFrom.chain_id
					) {
						this.nftSelectFrom.resource_id = item.resource_id;
						this.nftSelectFrom.coin_token =
							item.refer_token_contact_address;
                        this.nftSelectFrom.coin_name = data.name
                        this.nftSelectFrom.symbol = item.token;
						this.nftSelectTo.resource_id = item.resource_id;
						this.nftSelectTo.coin_token =
							item.token_contact_address;
                        this.nftSelectTo.coin_name = data.name
					}
				});
				// 当前账户链与跨出链不一致的逻辑
				const { chain_id, coin_token, symbol } = this.nftSelectFrom;
				const fromChainId = chain_id;
				const config = getLayer1RpcUrl(fromChainId) || [];
				// console.log("config", config);
				const web3j = config[0].rpc_url;
				// console.log("当前账户链与跨出链不一致coin_token", coin_token);
				this.nftFromContract = await this.walletUtil.dealWeb3QueryContract(
					web3j,
					coin_token,
					"ERC721"
				);
				// console.log("contract", this.nftFromContract);
				this.checkNftTokensFromContract(this.nftFromContract, {
					symbol,
					contractAddress: coin_token,
				});
			}
            this.setSearchKey()
		},
		/**
		 * check nft from contract
		 */
		checkNftFromContract(data) {
			const { contractAddress, symbol } = data;
			this.$store
				.dispatch("linkBridge", {
					contractAddress,
					contractType: "IERC721Enumerable",
				})
				.then(async (contract) => {
					console.log("contract", contract);
					// const address = this.walletData.getAddress();
					const address = this.account;
					this.checkNftTokensFromContract(contract, {
						contractAddress,
						symbol,
					});
				}).catch(e => e);
		},
		/**
		 * check NFT Tokens from contract
		 */
		async checkNftTokensFromContract(contract, { contractAddress, symbol}) {
			console.log("contract", contract);
			// const address = this.walletData.getAddress();
			const address = this.account;
			const tx = await contract.methods.balanceOf(address);
			const res = await tx.call();
			console.log("res", res);
			let nftCollections = [];
			try {
				for (let i = 0; i < res; i++) {
					const tokenId = await contract.methods
						.tokenOfOwnerByIndex(address, i)
						.call();
					const tokenURI = await contract.methods
						.tokenURI(tokenId)
						.call();
                    let imageUrl = nftTokenURI(tokenURI)
					// console.log("tokenURI", i, tokenURI, imageUrl);
					nftCollections.push({
						id: tokenId,
						tokenId,
						name: tokenId,
						contractName: symbol,
						contractAddress,
						icon_url: imageUrl,
					});
				}
				nftCollections = _.sortBy(nftCollections, (item) =>
					Number(item.tokenId)
				);
				nftCollections.map((item) => (item.name = `#${item.name}`));
				this.nftCollections = nftCollections;
				this.nftCollectionsLoading = false;
			} catch (err) {
				console.log(err);
				this.nftCollectionsLoading = false;
			}
		},
		/**
		 * 选择nft
		 */
		async selectNft({ icon_url, contractAddress, contractName, tokenId, bridge_fee }) {
			this.currentNft = {
				url: icon_url,
				contractName,
				contractAddress,
				tokenId,
                bridge_fee: this.nftSelectFrom.fee
			};
			this.getNftCoinBalance();
			// 检查是否授权
			this.checkCurrentNftApproved();
		},
		/**
		 * 清除NFT缓存，分为切换tab和切换合约
		 */
		clearNftCache(changeTab = false) {
			if (changeTab) {
				this.nftContractCollections = [];
				this.nftContractId = "";
			}
			this.nftCollections = [];
			this.nftId = "";
			this.currentNft = {};
			this.currentNftApproved = false;
		},
		/**
		 * 验证当前选择的NFT是否授权
		 */
		async checkCurrentNftApproved() {
			if (_.isEmpty(this.currentNft)) return;
			// 链不一致，需要切换链。
			const { tokenId, contractAddress } = this.currentNft;
			let res;
			if (this.walletChainId === this.nftSelectFrom.chain_id) {
				// console.log(
				// 	"当前跨出链是否与账户链地址一致contractAddress",
				// 	contractAddress
				// );
				res = await this.web3EthUtil.checkERC721Approved({
					tokenId,
					contractAddress,
				});
			} else {
				// console.log("this.nftFromContract", this.nftFromContract);
				res = await this.nftFromContract.methods
					.getApproved(tokenId)
					.call();
			}
			const { handler_address } = this.nftSelectFrom;
			// console.log(
			// 	"Check NFT Approve...",
			// 	typeof res,
			// 	res,
			// 	handler_address
			// );
			// 获取账户地址，做授权比对
			let status = false;
			if (res == handler_address) {
				status = true;
			}
			this.currentNftApproved = status;
			console.log("NFT Approve Result...", status);
		},
		/**
		 * NFT授权
		 */
		async approveNft() {
			if (this.walletChainId !== this.nftSelectFrom.chain_id) {
				this.$bus.$emit(
					"changeBridgeNetWork",
					this.nftSelectFrom.chain_id
				);
				return;
			}
			const { tokenId, contractAddress } = this.currentNft;
			let syncWallet = this.walletData.getSyncWallet();
			if (syncWallet) {
				this.nftloading = true;
				emitLoading({
					loading: true,
					fn: () => {
						this.nftloading = false;
					},
				});
				try {
					const { handler_address } = this.nftSelectFrom;
					// let res;
					let res = await this.web3EthUtil.approveERC721TokenDeposits(
						{
							contractAddress,
							tokenId,
							toAddress: handler_address,
						}
					);
					emitLoading({
						loading: false,
					});
					this.nftloading = false;
					console.log("res", res);
					if (!res) return;
					this.currentNftApproved = true;
				} catch (error) {
					emitLoading({
						loading: false,
					});
					this.nftloading = false;
				}
			} else {
				$Vue.$bus.$emit("linkWallet");
			}
		},
		/**
		 * 切换nft链
		 */
		async changeNftChainIndex(obj) {
			this.nftNetworkFeeStatus = true;
			let { type, item } = obj;
			if (
				(this.nftSelectFrom.network == item && type == "from") ||
				(this.nftSelectTo.network == item && type == "to")
			)
				return;
			let netWorks = [].concat(this.nftFromList, this.nftToList);
			let from = type == "from" ? "nftSelectFrom" : "nftSelectTo";
			let to = type !== "from" ? "nftSelectFrom" : "nftSelectTo";
			this[from] = netWorks.find((v) => {
				return v.network == item;
			});

			let defToNet = this.nftChainArr[item][0];
			this[to] = netWorks.find((v) => {
				return v.network == defToNet;
			});
			this.nftContractId = "";
			this.clearNftCache();
			this.nftCurrentSwitchChainId = this.nftSelectFrom.chain_id;
			this.setNftNetWorkFeeSymbol();
		},
		/**
		 * 交换链，需要重新查询 history 数据
		 */
		switchNftChain() {
			if (_.isEmpty(this.nftSelectFrom) || _.isEmpty(this.nftSelectTo))
				return;
			this.nftNetworkFeeStatus = true;
			let temp = Object.assign({}, this.nftSelectFrom);
			this.nftSelectFrom = Object.assign({}, this.nftSelectTo);
			this.nftSelectTo = Object.assign({}, temp);
			temp = Object.assign({});
			this.nftContractId = "";
			this.clearNftCache();
			this.nftCurrentSwitchChainId = this.nftSelectFrom.chain_id;
            this.getNftConllections()
            // this.refreshHistory = +new Date()
			// this.setNftNetWorkFeeSymbol();
		},
		/**
		 * nft 跨链 next 逻辑
		 */
		nftNext() {
			if (!this.currentNftApproved || !this.nftNetworkFeeStatus) return;
			// 验证跨出链是否为当前账户链
			const fromChainId = this.nftSelectFrom.chain_id;
			if (this.walletChainId !== fromChainId) {
				this.$bus.$emit("changeBridgeNetWork", fromChainId);
				return;
			}
			const {
				contractAddress,
				contractName,
				tokenId,
				url,
                bridge_fee
			} = this.currentNft;
			const bridgeObj = {
				nftContractAddress: contractAddress,
				nftContractName: contractName,
				nftTokenId: tokenId,
				nftUrl: url,
                fee: bridge_fee,
                symbol: this.nftNetworkFeeSymbol
			};
			this.nftBridgeObj = bridgeObj;
			this.showNftDialog = true;
		},
		/**
		 * 提交NFT Bridge
		 */
		submitNftBridge() {
			if (this.walletChainId !== this.nftSelectFrom.chain_id) {
				this.$bus.$emit(
					"changeBridgeNetWork",
					this.nftSelectFrom.chain_id
				);
				return;
			}
			if (!this.currentNftApproved || !this.nftNetworkFeeStatus) return;
			let chain_id = this.nftSelectTo.chain_id
				// this.nftSelectTo.chain_id == 4 ? 1 : this.nftSelectTo.chain_id;
			try {
				let params = {
					address: this.curAddress,
					tokenId: Number(this.nftId),
					chain_id: chain_id,
				};
				if (!this.walletData.getSyncWallet()) {
					this.walletData.init({
						successFn: () => {
							this.transferNftBridge(params);
						},
						loading: () => {},
						loadingEnd: () => {},
					});
				} else {
					this.transferNftBridge(params);
				}
			} catch (err) {
				console.error(err);
			}
		},
		/**
		 * NFT bridge
		 */
		async transferNftBridge(params) {
			if (this.nftSubmitLoading) return;
			this.nftSubmitLoading = true;
			if (!this.walletData.getSyncWallet()) {
				this.walletData.init({
					successFn: async () => {
						const res = await this.web3EthUtil.depositNftToSyncFromEthereum(
							params,
							this.nftSelectFrom
						);
                        this.saveNftRecordData(Object.assign(params, res))
						this.nftBridgeCallBack(res);
					},
					loading: () => {},
					loadingEnd: () => {},
				});
			} else {
				const res = await this.web3EthUtil.depositNftToSyncFromEthereum(
					params,
					this.nftSelectFrom
				);
                this.saveNftRecordData(Object.assign(params, res))
				this.nftBridgeCallBack(res);
			}
		},
        // -- saveNft
        async saveNftRecordData(payResult) {
			// let address = this.walletData.getAddress();
            let address = this.account;
            if (!payResult.hash) return
            let blockNumber = await this.walletUtil.getTransactionDetail()
            let params = {
                submit_block: blockNumber,
                from_address: address,
                from_chain_id: this.nftSelectFrom.chain_id,
                layer: 'L1',
                from_network: this.nftSelectFrom.network,
                from_tx_hash: payResult.hash,
                to_address: address,
                to_chain_id: this.nftSelectTo.chain_id,
                to_network: this.nftSelectTo.network,
                token_address: this.nftBridgeObj.nftContractAddress,
                token_type: 'ERC721',
                token_id: this.nftBridgeObj.nftTokenId,
                status: 'Pending',
                type: 'Bridge',
                date: getUTCTime(new Date()),
                symbol: this.nftSelectFrom.symbol,
                contract_name: this.nftBridgeObj.nftContractName,
                amount: 1,
                action_symbol: ''
            }
            console.log('saveNftRecordData', params)
            this.$store.dispatch('sendTransactionRecord', {
                params,
                callback: () => {
                    this.refreshHistory = +new Date()
                    // console.log('saveNftData--success')
                }
            })
        },
		/**
		 * nft bridge callback
		 */
		nftBridgeCallBack(res) {
			this.nftSubmitLoading = false;
			console.log("bridge result...", res);
			if (!res) return;
			this.showNftDialog = false;
			window.setTimeout(() => {
				this.reCheckNft();
			}, 1000);
		},
		/**
		 * 跨链完成后重新调用查询当前合约下的nft
		 */
		reCheckNft() {
			const currenContract = _.find(
				this.nftContractCollections,
				(o) => o.id === this.nftContractId
			);
			currenContract && this.selectNftContract(currenContract);
		},
		async getNftCoinBalance() {
			const chainId = this.nftSelectFrom.chain_id;
			const config = getLayer1RpcUrl(chainId) || [];
			const web3j = config[0].rpc_url;
			console.log('web3j', web3j)
			const balance = await this.walletUtil.getWeb3Erc20Amount(
				web3j
			);
			const coinTokenObj = this.$store.state.coin.contratMap[chainId]
			const dealCoinValue = coinTokenObj.find((o) => o.token_type === 'NATIVE');
			try {
				console.log('dealCoinValue', dealCoinValue)
				const nftBalance = ethers.utils.formatUnits(
					balance.toString(),
					dealCoinValue.contract_decimals || 18
				);
				console.log('nftBalance', nftBalance)
				this.nftBalance = Number(nftBalance).toFixed(dealCoinValue.show_scale);
				this.nftNetworkFeeStatus = Number(this.nftBalance) > this.nftSelectFrom.fee;
			} catch (err) {
				console.error(err)
			}
		},
        setSearchKey() {
            sessionStorage.setItem('searchBridge', JSON.stringify({
                type: this.currentTab,
                changeChainIdArr: [
                    this.slectFromWork && this.slectFromWork.chain_id || '',
                    this.slectFromWork && this.slectToWork.chain_id || '',
			    ],
                nftCurrentSwitchChainId: this.nftCurrentSwitchChainId || this.nftSelectFrom.chain_id || '',
                nftContractId: this.nftContractId,
                symbol: this.coinValue.name
            }))
        }
		// ************ NFT End ************
	},
	destroyed() {
        sessionStorage.removeItem('searchBridge')
		clearTimeout(this.timer);
		this.changeChainIdArr = [];
	}
};
