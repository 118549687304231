var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wallet-entry",class:['theme-' + _vm.themeMode]},[_c('div',{staticClass:"wallet-header"},[_c('div',{staticClass:"visible-pc"},[_c('Header',{attrs:{"showMiddleInfo":true}})],1),_c('div',{class:[
                  'visible-h5',
                  {
                      'dis-visible-h5': !_vm.showMenu && _vm.h5MenuHideTop.includes(_vm.routeName),
                      'visible-menu': _vm.showMenu && !_vm.showSmallIcon
                  } ]},[_c('Top',{staticClass:"top",attrs:{"color":_vm.color,"showSmallIcon":_vm.showSmallIcon}})],1)]),_c('div',{class:['user-layout-container', _vm.isHideMenu && 'user-layout-container-no-menu']},[(!_vm.isHideMenu)?_c('div',{staticClass:"user-layout-menu"},[_c('PortfolioMenu',{attrs:{"menuType":_vm.menuType}})],1):_vm._e(),_c('div',{staticClass:"user-layout-main"},[(
                      _vm.networkList.length > 0 && (_vm.isLogined && _vm.userId  || _vm.userId || _vm.notNeedLogined)
                  )?_c('router-view',{staticClass:"wallet-view"}):_c('div',{staticClass:"loading-wrap"},[_c('a-spin')],1)],1)]),_c('div',{staticClass:"visible-pc"},[(!_vm.version)?_c('Footer',{staticClass:"footer",attrs:{"type":"fixed"}}):_c('footer-wrap')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }