//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    utils
} from "ethers";
import ChainSelect from "../component/ChainSelect.vue";
import TokenSelect from "../component/TokenSelect.vue";
import {
    fetchSequenceId
} from "../../../api/common/index";
import {
    getLayer2Balance,
    getSignleSymbolPrice,
    fetchPerpetualBalance,
    fetchPerpetualTransfer,
    fetchPerpetualHistory,
} from "../../../api/wallet/index";

import {
    emitEvents,
    toFloor,
    filterL2Balance,
    getFee,
    getExplorer,
    formatTokenCommify,
    formatUTCTime,
    formatToken,
    sleepProcess,
    toFutresI18n
} from "../../../utils/index";
import BNP from "bignumber.js";

import futuresConfig from "./../../../config/futures";
import Web3 from "web3"
// const h = this.$createElement;
export default {
    name: "FutureTransfer",
    components: {
        ChainSelect,
        TokenSelect,
    },
    data() {
        return {
            chainId: null,
            symbol: null,
            tokens: [],

            loadingBalance: false,
            totalBalanceWei: 0, // layer 2
            availableBalanceWei: 0, // layer 2
            // lockedBalanceWei: 0, // layer 2

            loadingFuturesBalance: false,
            futuresTotalBalanceWei: 0, // futures
            futuresAvailableBalanceWei: 0, // futures
            futuresMarginBalanceWei: 0, // futures
            futuresLockedBalanceWei: 0, // futures

            accountList: [{
                    label: this.$t("wallet_common_wallet_balance"),
                    value: "wallet",
                },
                {
                    label: this.$t("wallet_perpetual_futures_balance"),
                    value: "futures",
                },
            ],
            fromAccount: "wallet",
            toAccount: "futures",
            amount: null,

            txFeeWei: 0,
            priceMap: {},

            // formError: null,
            loading: false,

            breadcrumb: [{
                    path: "/wallet",
                    name: this.$t("wallet_common_wallet"),
                },
                {
                    path: "/wallet?tabId=futures",
                    name: this.$t("wallet_common_futures"),
                },
                {
                    path: "/wallet/transfer",
                    name: this.$t("wallet_perpetual_transfer"),
                },
            ],

            // history
            columns: [{
                    title: this.$t("wallet_common_date"),
                    // dataIndex: "createdAt",
                    key: "createdAt",
                    // width: 210,
                    scopedSlots: {
                        customRender: "createdAt"
                    },
                },
                {
                    title: this.$t("wallet_common_asset"),
                    // dataIndex: "token",
                    key: "token",
                    // width: 80,
                    scopedSlots: {
                        customRender: "token"
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    // dataIndex: "amount",
                    key: "amount",
                    // width: 100,
                    scopedSlots: {
                        customRender: "amount"
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    scopedSlots: {
                        customRender: "status"
                    },
                },
                {
                    title: this.$t("wallet_history_type"),
                    // dataIndex: "txType",
                    key: "txType",
                    // width: 120,
                    scopedSlots: {
                        customRender: "txType"
                    },
                },
            ],
            loadingHistory: false,
            historyList: [],
            historyMoreVisible: true,
            clipboard: "",
            toFutresI18n
        };
    },
    computed: {
        account() {
            return this.$store.getters.account || '';
        },
        walletNetworks() {
            return this.$store.getters.walletNetworks;
        },
        layer2Config() {
            return this.$store.state.siteConfig.layer2Map[this.chainId];
        },
        curToken() {
            return (
                this.tokens.find((item) => item.symbol === this.symbol) || {}
            );
        },
        tokenIdMap() {
            let result = {};
            this.tokens.forEach((item) => {
                result[item.id] = item;
            });
            return result;
        },

        // balance
        decimals() {
            return this.curToken.decimals;
        },
        showScale() {
            return this.curToken.show_scale;
        },
        totalBalance() {
            return utils.formatUnits(this.totalBalanceWei, this.decimals);
        },
        availableBalance() {
            return utils.formatUnits(this.availableBalanceWei, this.decimals);
        },
        futuresTotalBalance() {
            return utils.formatUnits(
                this.futuresTotalBalanceWei,
                this.decimals
            );
        },
        futuresAvailableBalance() {
            return utils.formatUnits(
                this.futuresAvailableBalanceWei,
                this.decimals
            );
        },
        futuresMarginBalance() {
            // const total = new BNP(this.futuresMarginBalanceWei).plus(new BNP(this.futuresLockedBalanceWei)).toString();
            // return utils.formatUnits(total, this.decimals);
            return utils.formatUnits(
                this.futuresMarginBalanceWei,
                this.decimals
            );
        },
        futuresTransferableBalance() {
            // TODO：目前暂时 AvailableBalance 就是 TransferableBalance
            return this.futuresAvailableBalance;
            // const value = new BNP(this.futuresAvailableBalanceWei).minus(new BNP(this.futuresLockedBalanceWei)).toString();
            // return utils.formatUnits(value, this.decimals);
        },
        futuresLockedBalance() {
            return utils.formatUnits(
                this.futuresLockedBalanceWei,
                this.decimals
            );
        },

        availableAmount() {
            // 受账户选择影响。
            if (this.fromAccount === "wallet") {
                return this.availableBalance;
            } else {
                return this.futuresTransferableBalance;
            }
        },
        maxAmount() {
            if (new BNP(this.availableAmount).gt(this.txFee)) {
                return new BNP(this.availableAmount)
                    .minus(this.txFee)
                    .toString();
            }
            return 0;
        },
        txFee() {
            return utils.formatUnits(this.txFeeWei, this.decimals);
        },
        currency() {
            return this.$store.state.user.currency;
        },
        txFeeFiat() {
            const price = this.priceMap[this.symbol];
            if (!price) return null;
            return new BNP(this.txFee).times(price).toFixed(6);
        },

        formInvalid() {
            return (
                this.fromAccount &&
                this.toAccount &&
                this.amount &&
                !this.amountError &&
                !this.loading
            );
        },
        amountError() {
            // 收入金额大于(可用金额-手续费)
            if (new BNP(this.amount).gt(this.maxAmount)) {
                return this.$t(
                    "wallet_transfer_message_error_insufficientError"
                );
            }
            return null;
        },

        layer1NetworkList() {
            return this.$store.getters.layer1NetworkList;
        },
        blockExplorerUrl() {
            // 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
            const privateUrl = window.site_config.block_explorer_url;
            if (privateUrl) {
                return privateUrl.endsWith("/") ?
                    `${privateUrl}${this.chainId}/L2/` :
                    `${privateUrl}/${this.chainId}/L2/`;
            } else {
                const url = this.$store.getters.filterNetworkItem(
                    this.chainId,
                    "L2"
                ).blockExplorerUrl;
                return url.endsWith("/") ? url : url + "/";
            }
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            const {
                chainId,
                symbol
            } = this.$route.query;
            if (chainId) {
                this.chainId = Number(chainId);
            } else {
                this.chainId = this.$store.state.chainId;
            }
            this.symbol = symbol;

            this.fetchTokens();
        },
        isFromAccount(str) {
            return ('0x' + str).toLowerCase() === this.account.toLocaleLowerCase();
        },
        async fetchTokens() {
            const tokens = await this.$store.dispatch(
                "getLayer2TokenList",
                this.chainId
            ).catch(e => []);
            // 前端过滤出对应币种。
            const symbols = futuresConfig.symbols;
            this.tokens = tokens.filter((item) =>
                symbols.includes(item.symbol)
            );
            // 如果 symbol 存在，则判断是否在 tokens 列表中，如果不存在则设置为第一个。
            if (this.symbol) {
                if (!this.tokens.find((item) => item.symbol === this.symbol)) {
                    if (this.tokens.length > 0) {
                        this.symbol = this.tokens[0].symbol;
                    }
                }
            } else {
                if (this.tokens.length > 0) {
                    this.symbol = this.tokens[0].symbol;
                }
            }
            this.onChangeToken();
        },
        onChangeChain() {
            this.fetchTokens();
        },
        async onChangeToken(refresh) {
            this.fetchTokenPrice();
            this.fetchHistory(refresh);
            this.fetchGasFee();
            this.fetchL2Balance(refresh);
            await this.getPerpetualAccount();
            this.fetchFuturesBalance(refresh);
        },

        async getPerpetualAccount() {
            this.perpetualAccount = null;
            await this.$store.dispatch('getPerpetualAccount', this.chainId).then(res => {
                this.perpetualAccount = res && res.create2Address;
            }).catch(e => e)
            console.log("this.perpetualAccount:", this.perpetualAccount);
        },

        async fetchL2Balance(refresh) {
            const restUrl = this.layer2Config.rest;
            const params = {
                url: restUrl,
                address: this.account,
                type: "balances",
            };
            if (this.loadingBalance) return
            this.loadingBalance = true;
            refresh && await sleepProcess(2 * 1000)
            await getLayer2Balance(params)
                .then((res) => {
                    console.log('res:', res)
                    if (res.status == "success") {
                        let totalBalance = filterL2Balance(res.result, "committed", this.symbol) || "0";
                        if (totalBalance == this.totalBalanceWei && this.totalBalanceWei != '0' && refresh) {
                            this.loadingBalance = false;
                            this.fetchL2Balance(refresh)
                        }
                        this.totalBalanceWei = totalBalance;
                        this.availableBalanceWei = filterL2Balance(res.result,"available",this.symbol) || "0";
                        this.lockedBalanceWei = filterL2Balance( res.result, "locked", this.symbol) || "0";
                    }
                })
                .catch((e) => e);
            this.loadingBalance = false;
        },
        async fetchFuturesBalance(refresh) {
            const account = this.perpetualAccount;
            if (!account) {
                this.futuresTotalBalanceWei = 0
                this.futuresAvailableBalanceWei = 0
                this.futuresMarginBalanceWei = 0
                this.futuresLockedBalanceWei = 0
                return
            }
            const restUrl = this.layer2Config.rest;
            const params = {
                url: restUrl,
                address: account,
            };
            this.loadingFuturesBalance = true;
            refresh && await sleepProcess(2 * 1000)
            await fetchPerpetualBalance(params)
                .then((res) => {
                    if (res.status == "success") {
                        let futuresTotalBalanceWei = filterL2Balance(res.result, "committed", this.symbol) || "0";
                        if (futuresTotalBalanceWei == this.futuresTotalBalanceWei && this.futuresTotalBalanceWei != '0' && refresh) {
                            this.fetchL2Balance(refresh)
                        }
                        this.futuresTotalBalanceWei = futuresTotalBalanceWei;
                        this.futuresAvailableBalanceWei = filterL2Balance(res.result, "available", this.symbol) || "0";
                        this.futuresMarginBalanceWei = filterL2Balance(res.result, "margin", this.symbol) || "0";
                        this.futuresLockedBalanceWei = filterL2Balance(res.result, "locked", this.symbol) || "0";
                    }
                })
                .catch((e) => e);
            this.loadingFuturesBalance = false;
        },
        onAll() {
            this.amount = toFloor(this.maxAmount, this.showScale);
        },
        formatInput() {
            this.amount = toFloor(this.amount, this.decimals);
            if (this.amount * 1 === 0) {
                this.amount = 0;
            }
        },
        onChangeAccount(type) {
            if (type === "from") {
                this.toAccount =
                    this.toAccount === "wallet" ? "futures" : "wallet";
            } else {
                this.fromAccount =
                    this.fromAccount === "wallet" ? "futures" : "wallet";
            }
            this.fetchGasFee();
        },

        switchType() {
            [this.toAccount, this.fromAccount] = [this.fromAccount, this.toAccount]
        },

        async fetchTokenPrice() {
            getSignleSymbolPrice({
                fsym: this.currency.name,
                tsyms: this.symbol,
            }).then((res) => {
                // console.log("fetchTokenPrice res:", res);
                // 注意价格转换，接口获取的是 1 个USD 能购买多少 ETH。
                for (const key in res) {
                    const price = 1 / res[key];
                    this.$set(this.priceMap, key, price);
                }
            }).catch(e => e);
        },

        checkChainId() {
            // 链不一致，需要切换链。
            const walletChainId = this.$store.state.wallet.walletChainId;
            if (walletChainId != this.chainId) {
                this.$bus.$emit("changeBridgeNetWork", this.chainId);
                return false;
            }
            return true;
        },

        async goSubmit() {
            if (this.loading) return
            this.loading = true;
            try {
                const isMainAccountTransfer = this.fromAccount === "wallet"
                const restUrl = this.layer2Config.rest;
                const accountId = await this.$store.dispatch("getLayer2AccountId");
                const address = this.account;
                const walletMain = this.walletData.getSyncWallet()
                const walletPerpetualL2 = await this.$store.dispatch('create2SyncWallet', this.chainId)

                // 获取 sequenceid
                // const sequenceId = await fetchSequenceId({
                // 	url: restUrl,
                // });
                // if (!sequenceId) {
                // 	console.error("fetchSequenceId failed.");
                // 	this.$message.error(this.$t("wallet_transfer_fail"));
                // 	this.loading = false;
                // 	return;
                // }

                const amountWei = utils.parseUnits(this.amount, this.decimals).toString();
                const amountFormat = formatToken(amountWei, this.curToken.decimals)
                const fromAccount = isMainAccountTransfer ? "Main Account" : "Futures Subaccount"
                const toAccount = !isMainAccountTransfer ? "Main Account" : "Futures Subaccount"
                const fromAddress = isMainAccountTransfer ? address : walletPerpetualL2.cachedAddress
                const toAddress = !isMainAccountTransfer ? address : walletPerpetualL2.cachedAddress

                // const message =
                // 	`Transaction type: Transfer\n` +
                // 	`From: ${from}\n` +
                // 	`To: ${to}\n` +
                // 	`Amount: ${amountFormat} ${this.symbol}\n` +
                // 	`SequenceId: ${sequenceId}`;
                const nonce = isMainAccountTransfer && (await walletMain.getNonce()) || (await walletPerpetualL2.getNonce())
                // const nonce = await this.getNonce(fromAddress)
                console.log(walletMain, walletPerpetualL2)
                const message =
                    `Authorize to transfer from your ${fromAccount.toLocaleLowerCase()} to your ${toAccount.toLocaleLowerCase()}.\n` +
                    `Transaction type: Transfer\n` +
                    `From ${fromAccount}: ${fromAddress.toLocaleLowerCase()}\n` +
                    `To ${toAccount}: ${toAddress.toLocaleLowerCase()}\n` +
                    `Amount: ${amountFormat} ${this.symbol}\n` +
                    `Fee: ${this.txFee} ${this.symbol}\n` +
                    `Nonce: ${nonce}`;

                console.log("message:", JSON.stringify(message));
                const signer = this.$store.state.wallet.ethWallet;
                const signature = await signer.signMessage(message).catch(
                    (e) => null
                );
                if (!signature) {
                    console.error("signMessage failed.");
                    this.$message.error(this.$t("wallet_transfer_fail"));
                    this.loading = false;
                    return;
                }

                let account_id = isMainAccountTransfer ? (await walletMain.getAccountId()) : (await walletPerpetualL2.getAccountId())
                console.log('nonce-accountId:', account_id, nonce)
                // TODO 有调整
                const transfer = {
                    accountId: account_id,
                    from: fromAddress.toLocaleLowerCase(),
                    to: toAddress.toLocaleLowerCase(),
                    token: this.curToken.id,
                    fee: this.txFeeWei.toString(),
                    nonce: nonce,
                    amount: amountWei
                }

                let tx = await this.walletUtil.transferPerpetual(transfer, isMainAccountTransfer ? null : walletPerpetualL2).catch(err => {
                    console.log('transferPerpetual:err', err)
                })
                console.log('%O', tx)
                delete tx.tokenId
                console.log('perpetual-transfer:', tx)
                // const params = {
                // 	url: restUrl,
                // 	data: {
                // 		mainAccountId: accountId,
                // 		token: this.curToken.id,
                // 		amount: amountWei,
                //         from: isMainAccountTransfer ? address : walletPerpetualL2.cachedAddress,
                // 		to: !isMainAccountTransfer ? address: walletPerpetualL2.cachedAddress,
                //         salt: walletPerpetualL2._ethSigner.salt,
                //         fee: fee.toString(),
                // 		signature,
                // 		sequenceId: sequenceId + "",
                //         account_type: isMainAccountTransfer ? 'perpetual' : 'main'
                // 	},
                // };
                const params = Object.assign({
                    mainAccountId: accountId,
                    salt: walletPerpetualL2._ethSigner.salt,
                    ethSignature: signature,
                    account_type: isMainAccountTransfer ? 'perpetual' : 'main'
                }, {
                    tx: tx
                })
                console.log("params:", params);
                // return ;

                await fetchPerpetualTransfer({
                        url: restUrl,
                        data: params
                    })
                    .then((res) => {
                        if (res.status == "success") {
                            this.amount = null;
                            this.$message.success(
                                this.$t("wallet_transfer_success")
                            );
                            this.onChangeToken('refresh');
                        } else {
                            this.$message.error(this.$t("wallet_transfer_fail"));
                        }
                    })
                    .catch((e) => {
                        this.$message.error(this.$t("wallet_transfer_fail"));
                    });
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async fetchGasFee() {
            const address = this.fromAccount === "wallet" ? this.account : this.perpetualAccount;
            console.log("[fetchGasFee] address:", address);
            if (!address) {
                this.txFeeWei = 0;
                // return ;
            }
            await this.$store.dispatch("getL2TxFee", {
                chainId: this.chainId,
                txType: "Transfer",
                address,
                symbol: this.symbol,
            }).then((res) => {
                console.log("fetchGasFee res:", res);
                this.txFeeWei = res;
            }).catch(e => e);
        },
        async fetchHistory(refresh) {
            const restUrl = this.layer2Config.rest;
            let perpetualAccount = await this.$store.dispatch('getPerpetualAccount', this.chainId)
            if (!perpetualAccount || !perpetualAccount.create2Address) return
            const params = {
                url: restUrl,
                account: perpetualAccount.create2Address,
                data: {
                    from: "latest",
                    txType: "transfer",
                    token: this.symbol,
                    offset: null,
                    limit: 20,
                    createdStartAt: null, // 13位时间戳
                    createdEndAt: null, // 13位时间戳
                    direction: "older", // older/newer
                },
            };
            this.loadingHistory = true;
            this.historyList = [];
            refresh && await sleepProcess(2 * 1000)
            await fetchPerpetualHistory(params)
                .then((res) => {
                    this.historyList = res.result.list;
                })
                .catch((e) => e);
            this.loadingHistory = false;
        },
        formatUTCTime(date) {
            return formatUTCTime(date);
        },
        formatToken(id) {
            return this.tokenIdMap[id].symbol;
        },
        formatAmount(value, decimals = this.curToken.show_scale) {
            return formatTokenCommify(value, decimals);
        },
        formatUnits(value) {
            return utils.formatUnits(value, this.decimals);
        },
        toHistory() {
            this.$router.push("/wallet/transaction-history?tabId=futures");
        },
        onToggleExpand(record) {
            record.expand = !record.expand;
        },
        onCopy(record) {
            this.clipboard = record.txid || "";
            this.$nextTick(function () {
                this.$refs.copyBtn.click();
            });
        },
        copySuccess() {
            this.$message.success(this.$t("wallet_common_copied"));
        },
        async isActive() {
            let syncWallet = this.walletData.getSyncWallet();
            let address = this.account
            if (this.toAccount === "wallet") {
                syncWallet = await this.$store.dispatch('create2SyncWallet', this.chainId)
                address = syncWallet && syncWallet.cachedAddress || ''
            }
            let isActive = false;
            if (!syncWallet || !address ||!((await this.$store.dispatch('isL2Active', {
                chainId: this.chainId,
                address: address && address.toLowerCase(),
            })))) {
                isActive = false;
            } else {
                isActive = true;
            }
            return isActive;
        },
        async transfer() {
            if (!this.checkChainId()) return;
            let activeAcount = await this.isActive();
            let syncWallet = this.walletData.getSyncWallet();
            if (this.toAccount === "wallet") {
                syncWallet = await this.$store.dispatch('create2SyncWallet', this.chainId)
            }
            if (activeAcount) {
                this.goSubmit();
            } else {
                emitEvents("linkLogin", {
                    type: "active_account",
                    fn: () => {
                        this.goSubmit()
                    },
                    syncWallet: (this.toAccount === "wallet" ? syncWallet : null)
                })
            }
        },
        async getNonce(address) {
            let netConfig = this.$store.getters.filterNetworkItem(this.chainId, 'L1');
            let web3j = netConfig.rpcUrl;
            const web3 = new Web3(web3j);
            const txCount = await web3.eth
                .getTransactionCount(address)
                .then((txCount) => txCount);
            return txCount
        }
    },
};
