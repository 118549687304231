//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import { downloadExcel, getHistoryTransaction, fetchTransactionTypes, fetchTransactionStatus, getTokenList } from "../../api/wallet/index";
import iconDown from "../../assets/img/icon-down.png";
import iconUp from "../../assets/img/icon-up.png";
import {
    download,
    getChainId,
    getQueryChainId,
    getExplorer,
    getUTCTime,
    getLayer1RpcUrl,
    getChainLayer2Config,
    historyStatus,
    getHashBlockUrl,
    formatTxTypeKey,
} from "../../utils/index";
import List from "./component/List";
import NftHistory from './history-nft.vue';
import FuturesHistory from './futures/history.vue';

import MAP from "../../utils/map.js";

const transactionTypeDict = {
    'deposit': 'L2',
    'bridge': 'L1',
    'withdraw': 'L2',
    'transfer': 'L2',
    'swap': 'L2',
    'updatepublickey': 'L2',
}

// 处理展开前的 From address: / To address:
const txIsFrom = {
    "supply": true,
    "burnt": true,
    "repay": true,
}
function getTxAddressLabel(transactionType) {
    return txIsFrom[transactionType.toString().toLowerCase()] ? "From address: " : "To address: "
}


// 用来处理展开后的数据类型:
// 1: address: "", txid: ""
// 2: bridge
// 其他
const txInfoDict = {
    "deposit_to_l2": 1,
    "deposit": 1,
    "supply": 1,
    "withdraw": 1,
    "borrow": 1,
    "repay": 1,
    "mint": 1,
    "burnt": 1,
    "bridge": 2,
    "send": 1,
}
function getTxInfoType(transactionType) {
    return txInfoDict[transactionType.toString().toLowerCase()];
}

let hisWidth = window.innerWidth
export default {
    name: "history",
    components: {
        List,
        NftHistory,
        FuturesHistory,
    },
    data() {
        return {
            tabId: 'token', // token,nft,futures
            showToast: false,
            bread: [
                {
                    path: "/wallet",
                    name: this.$t("wallet_common_wallet"),
                },
                {
                    path: "/wallet/transaction-history",
                    name: this.$t("wallet_history_transaction_history"),
                },
            ],
            clipboard: "",
            searchInfo: {
                transaction_type: "All",
                coin_name: "All",
                time: [],
                transaction_status: "All"
            },
            showNetWorkChangeBtn: false,
            dropList: {
                unit: [],
                // tokenType: [
                //     {
                //         value: "ERC20",
                //         label: this.$t('wallet_history_token'),
                //     },
                //     {
                //         value: "NFT",
                //         label: this.$t('wallet_history_nft')
                //     }
                // ],
            },
            columns: [
                {
                    title: this.$t("wallet_history_type"),
                    key: "type",
                    customRender: (text, record) => {
                        let type = record.type;
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            this.$t(`wallet_common_${formatTxTypeKey(type)}`)
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_date"),
                    key: "time",
                    minWidth: 210,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {
                                style: { "white-space": "nowrap" },
                            },
                            `${record.time || record.date}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_withdraw_coin"),
                    key: "symbol",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h("span", {
                            // style: { "white-space": "nowrap" }
                        }, record.token_type == 'NFT' ?  (record.contract_name  + ' #' + record.token_id):`${record.symbol}`);
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    minWidth: "150",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {
                                style: { "white-space": "nowrap" }
                            },
                            `${record.amount != 0 ? record.action_symbol: " "}  ${record.amount ? record.amount : '1'}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    minWidth: "120",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h("span", {}, historyStatus(record.status));
                    },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "key5",
                    width: (hisWidth > 1600 ? '' : 500),
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let {expand, from_tx_hash = '', from_chain_id, from_address = '', to_tx_hash = '', to_chain_id = this.chainId, from_network = '', to_network = '', to_address = '', address, txid, type, status, } = record
                        if (expand) {
                            const _type = getTxInfoType(type);
                            console.log('_type:', _type, type);
                            if (_type === 1) {
                                let {txid, chain_id, eth_hash} = record
                                // ↓↓↓3行↓↓↓处理lending部分的交易记录
                                chain_id = chain_id || record.from_chain_id || this.chainId;
                                address = address || record.from_address;
                                eth_hash = eth_hash || record.from_tx_hash;

                                let fromUrl = getHashBlockUrl(eth_hash, chain_id, 'L1', this.$store);
                                let toUrl = txid && getHashBlockUrl(txid, chain_id, 'L2', this.$store) || '';
                                let information = [
                                    h("p", {class: { information: true }, style: {display: 'flex'}},[ h("span", {style: {'display': 'inline-block', 'flex-basis': '90px'}}, `${this.$t('wallet_common_address')}: `), h("span", {style: {'display': 'inline-block', flex: '1'}}, address)]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}}, [
                                        h("span", {style: {'display': 'inline-block', 'flex-basis': '90px', 'white-space': 'nowrap'}}, `${this.$t('wallet_history_information_l1_txid')}: `),
                                        h("a", { attrs: { href: fromUrl, target: "_target" }, style: { lineHeight: "1em", display: "inline-flex", flex: '1' } },
                                            [h("span", {style: {display: "inline-block", lineSize: "fit-content", wordBreak: "break-word"}},
                                                [
                                                    eth_hash || '',
                                                    h("img", {style: {display: "inline-block", width: "20px", height: "20px", verticalAlign: "-4px", marginLeft: ".2em" }, attrs: { src: require("../../assets/img/share-link.png")}})
                                                ]
                                            )]
                                        )
                                    ]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}}, [
                                        h("span", {style: {'display': 'inline-block', 'flex-basis': '90px', 'white-space': 'nowrap'}}, `${this.$t('wallet_history_information_l2_txid')}: `),
                                        h("a", { attrs: { href: toUrl, target: "_target" }, style: { lineHeight: "1em", display: "inline-flex", flex: '1' } },
                                            [h("span", {style: {display: "inline-block", lineSize: "fit-content", wordBreak: "break-word"}},
                                                [
                                                    txid || '',
                                                    h("img", {style: {display: "inline-block", width: "20px", height: "20px", verticalAlign: "-4px", marginLeft: ".2em" }, attrs: { src: require("../../assets/img/share-link.png")}})
                                                ]
                                            )]
                                        )
                                    ])
                                ]
                                if (!txid) information.pop()
                                return h("div", {}, information);
                            } else if (_type === 2) { // bridge
                                let fromUrl = getHashBlockUrl(from_tx_hash, from_chain_id, 'L1', this.$store)
                                let toUrl = getHashBlockUrl(to_tx_hash, to_chain_id, 'L1', this.$store)
                                console.log('toUrl', toUrl)
                                let information = [
                                    h("p", {class: { information: true }},[ h("span", {style: {'display': 'inline-block', width: '100px'}}, `${this.$t('wallet_history_information_from_chain')}: `), h("span", {style: {'display': 'inline-block'}}, record.from_network)]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}},[ h("span", {style: {'display': 'inline-block', 'flex-basis': '100px'}}, `${this.$t('wallet_history_information_from_address')}: `), h("span", {style: {'display': 'inline-block', flex: 1}}, record.from_address)]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}}, [
                                        h("span", {style: {'display': 'inline-block', 'flex-basis': '100px', 'white-space': 'nowrap'}}, `${this.$t('wallet_history_information_from_txid')}: `),
                                        h("a", { attrs: { href: fromUrl, target: "_target" }, style: { lineHeight: "1em", display: "inline-flex", flex: '1' } },
                                            [h("span", {style: {display: "inline-block", lineSize: "fit-content", wordBreak: "break-word"}},
                                                [
                                                    from_tx_hash || '',
                                                    h("img", {style: {display: "inline-block", width: "20px", height: "20px", verticalAlign: "-4px", marginLeft: ".2em" }, attrs: { src: require("../../assets/img/share-link.png")}})
                                                ]
                                            )]
                                        )
                                    ]),
                                    h("p", {class: { information: true }},[ h("span", {style: {'display': 'inline-block', width: '100px'}}, `${this.$t('wallet_history_information_to_chain')}: `), h("span", {style: {'display': 'inline-block'}}, record.to_network)]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}},[ h("span", {style: {'display': 'inline-block', 'flex-basis': '100px'}}, `${this.$t('wallet_history_information_to_address')}: `), h("span", {style: {'display': 'inline-block', flex: 1}}, record.to_address)]),
                                    h("p", {class: { information: true }, style: {display: 'flex'}}, [
                                        h("span", {style: {'display': 'inline-block', 'flex-basis': '100px', 'white-space': 'nowrap' }}, `${this.$t('wallet_history_information_to_txid')}: `),
                                        h("a", { attrs: { href: toUrl, target: "_target" }, style: { lineHeight: "1em", display: "inline-flex", flex: '1' } },
                                            [h("span", {style: {display: "inline-block", lineSize: "fit-content", wordBreak: "break-word"}},
                                                [
                                                    to_tx_hash || '',
                                                    h("img", {style: {display: "inline-block", width: "20px", height: "20px", verticalAlign: "-4px", marginLeft: ".2em" }, attrs: { src: require("../../assets/img/share-link.png")}})
                                                ]
                                            )]
                                        )
                                    ])
                                ]
                                if (!to_tx_hash) information.pop()
                                return h("div", {}, information);

                            } else {

                                let btns = [h("p", { class: { action: true} },
                                    [
                                        h("span", {}, this.$t("wallet_common_address") + ":"),
                                        h("span", {}, record.address),
                                    ]
                                )]
                                if (txid) {
                                    let historyTxid = txid.indexOf("sync-tx:") >= 0 ? txid.substring(8) : txid;
                                    const blockExplorerUrl = this.getBlockExplorerUrl({
                                        transactionType: record.type,
                                    })
							        const url = blockExplorerUrl + `tx/${historyTxid}`
                                    btns.push(
                                        h("p",{ class: {  action: true }},
                                        [h( "a",
                                            {
                                                attrs: { href: url, target: "_target" },
                                                style: {lineHeight: "1em", display: "flex" }
                                            },
                                            [
                                                h("span", { style: { display: "inline-block" } }, this.$t("wallet_history_txid") + ":"),
                                                h("span", { style: { display: "inline-block", lineSize: "fit-content", wordBreak: "break-word" }},
                                                        [ record.txid,
                                                          h("img", {
                                                            style: { display:"inline-block", width: "20px",height: "20px",verticalAlign:"-4px", marginLeft: ".2em" },
                                                            attrs: {src: require("../../assets/img/share-link.png")},
                                                          }),
                                                        ]
                                              )
                                            ]
                                        )])
                                    )
                                }
                                if (record.gas_fee) {
                                    btns.push(h("p",{ class: { action: true } },
                                            [
                                                h("span", {}, this.$t( "wallet_history_swap_fee") + ":"),
                                                h("span", {}, record.gas_fee),
                                            ]
                                        )
                                    )
                                }
                                if (record.transaction_fee) {
                                    btns.push(h("p",{ class: { action: true } },
                                            [
                                                h("span", {}, this.$t("wallet_history_transaction_fee") + ":"),
                                                h("span", {}, record.transaction_fee),
                                            ]
                                        )
                                    )
                                }
                                return h("div", {}, btns);
                            }
                        } else {
                            return h("div", [
                                h("p", { class: { information: true }, style: {display: 'flex'}},
                                    [
                                        h("span", {style: {'display': 'inline-block', 'flex-basis': '90px', 'white-space': 'nowrap'}}, this.$t('wallet_common_address')+ ": "),
                                        h("span", {style: {"display": 'inline-block', 'min-width': '240px', 'margin-left': '10px'}}, from_address || address),
                                    ]
                                ),
                            ])
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "",
                    width: 100,
                    align: "center",
                    customRender: (text, record, index) => {
                        let copyFn = this.copy;
                        let icon = record.expand ? iconUp : iconDown;
                        let h = this.$createElement
                        let btn = h("img", {
                            style: {
                                cursor: "pointer",
                            },
                            attrs: {
                                width: "20px",
                                height: "20px",
                                src: icon,
                            },
                            on: {
                                click: () => {
                                    this.data[index].expand = !this.data[index].expand;
                                },
                            },
                        });
                        let copy = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        this.clipboard = record.txid;
                                        this.$nextTick(function () {
                                            this.$refs.btn.click();
                                        });
                                    },
                                },
                            },
                            this.$t("wallet_common_copy")
                        );
                        let btns = [btn];
                        // if (record.expand) {
                        //     if (record.txid) {
                        //         btns.push(copy);
                        //     }
                        // }
                        return h("div", btns);
                    },
                },
            ],
            data: [],
            pages: {
				pageNo: 1,
				pageSize: 20,
                total: 0,
            },
            loading: true,
            chainInitId: "",
            chainName: '',
            chainLayer: '',
            searchQuery: {},
            searchFirst: false,
            popShowTime: this.$store.state.popShowTime || 2
        };
    },
    computed: {
		// account() {
		// 	return this.$store.state.user.userInfo?.wallet_address;
		// },
        account() {
			return this.$store.getters.account || '';
		},
        supportNFT() {
            return this.$store.getters.supportNFT;
        },
        supportPerpetual() {
            return this.$store.getters.supportPerpetual;
        },
        exportText: function () {
            if (this.searchInfo.transaction_type == "deposit") {
                return this.$t("wallet_history_deposit_history");
            } else if (this.searchInfo.transaction_type == "withdraw") {
                return this.$t("wallet_history_withdraw_history");
            } else {
                return this.$t("wallet_history_export");
            }
        },
        networksOverview() {
            return this.$store.getters.networksOverview;
        },
        networksValues() {
            return this.networksOverview.map(item => item.value);
        },
		chainId() {
			const [chainId, layer ] = this.chainLayer.split('-');
			return chainId;
		},
		layer() {
			const [chainId, layer ] = this.chainLayer.split('-');
			return layer;
		},
		txTypeList() {
			if (this.layer) {
				const name = "txTypeLayer" + this.layer;
            	return this.$store.getters[name]();
			}
			return [];
		},
        txStatus() {
			if (this.layer) {
            	return MAP["txStatusLayer"+ this.layer];
			}
			return [];
        },
        hisWidth() {
            return window.innerWidth
        }
    },
    watch: {
        chainLayer(val, oldVal) {
            if(val != oldVal) {
                this.onChangeChain();
            }
        },
        'searchInfo.transaction_type'() {
            this.queryHistory();
        },
        'searchInfo.token_type'(val, oldVal) {
            this.queryHistory();
        },
        'searchInfo.transaction_status'(val, oldVal) {
            this.queryHistory();
        },
        'searchInfo.coin_name'(val, oldVal) {
            this.queryHistory();
        }
    },
    created() {
        this.searchQuery = this.$route.query || {};

        this.tabId = this.searchQuery.tabId || 'token';
        this.init();
        // this.fetchTransactionTypes();
        // this.fetchTransactionStatus();
    },
    methods: {
        formatTxTypeKey,
        async init() {
            this.searchFirst = true;
            let { transaction_type, symbol, coin_name, chainLayer, } = this.searchQuery
            this.searchInfo.transaction_type = transaction_type || "All";
            this.searchInfo.coin_name = symbol || "All";
			if (chainLayer) {
				this.chainLayer = chainLayer;
			} else {
                console.log('networksOverview', this.networksOverview)
				// 设置为钱包网络
                let layer = this.networksOverview && this.networksOverview[0] && this.networksOverview[0].value
                let defaultVal = this.$store.state.chainId+ '-1';
                if (this.networksOverview.find(v => v.value == defaultVal)) {
                    this.chainLayer = defaultVal
                } else this.chainLayer = layer
			}

            this.setDefaultTime();
            await this.fetchTokenList();
            this.queryHistory();
        },
        getBlockExplorerUrl({
            transactionType,
        }) {
            // 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
            const layer = transactionTypeDict[transactionType.toLowerCase()];
            const privateUrl = window.site_config.block_explorer_url;
            if (privateUrl) {
                return privateUrl.endsWith("/") ? `${privateUrl}${this.chainId}/${layer}/` : `${privateUrl}/${this.chainId}/${layer}/`;
            } else {
				const url = this.$store.getters.filterNetworkItem(this.chainId, layer).blockExplorerUrl;
            	return url.endsWith("/") ? url : url + '/';
            }
        },
        async fetchTokenList() {
            if (this.layer == '1') {
                let tokenList = await this.$store.dispatch('getTokenByChainAndLayer', {chainLayer: this.chainLayer})
                this.dropList.unit = [{ name: "All", symbol: "All" }];
                this.dropList.unit = this.dropList.unit.concat(tokenList.map(v => {
                    return {
                        name: v.name,
                        symbol: v.symbol,
                        token_type: 'ERC20'
                    }
                }))
            } else if(this.layer == '2') {
                let tokenList = await this.$store.dispatch('getTokenByChainAndLayer', {chainLayer: this.chainLayer})
                this.dropList.unit = [{ name: "All", symbol: "All" }];
                this.dropList.unit = this.dropList.unit.concat(tokenList.map(v => {
                    return {
                        name: v.symbol,
                        symbol: v.symbol
                    }
                }))
            }
        },
        async onChangeChain() {
            await this.fetchTokenList();
            this.reset();
            this.queryHistory();
        },

        toI18n(val) {
            switch (val) {
                case "Commited":
                    return this.$t("wallet_history_commited");
                case "Pending":
                    return this.$t("wallet_history_pending");
                case "Failure":
                    return this.$t("wallet_history_failure");
                case "Finalized":
                    return this.$t("wallet_history_finalized");
                default:
                    return val;
            }
        },
        setDefaultTime() {
            this.searchInfo.time = [];
            this.searchInfo.time.push(
                dayjs().subtract(30, "days").format("YYYY-MM-DD")
            );
            this.searchInfo.time.push(dayjs().format("YYYY-MM-DD"));
        },
        dateChange(val) {
            this.searchInfo.time = val;
        },
        reset() {
            if (this.searchFirst) return;
            this.searchInfo.transaction_type = "All";
            this.searchInfo.coin_name = "All";
            this.searchInfo.transaction_status = "All";
            this.setDefaultTime();
        },
        onChangePages(page) {
			this.pages.pageNo = page;
            this.queryHistory();
        },
        queryHistory() {
            console.log("queryHistory");
            let params = Object.assign(
                {
                    address: this.account,
                    page_no: this.pages.pageNo - 1,
                    page_size: this.pages.pageSize,
                },
                this.getParams()
            );
            this.loading = true;
            console.log("queryParam", params);

            this.data = [];

            getHistoryTransaction(params)
                .then((res) => {
                    if (res.code == 200) {
                        this.pages.total = res.data.total_number;
                        let resList =
                            res.data.transaction_history_list ||
                            res.data.convert_list ||
                            res.data.bridge_list ||
                            [];
                        this.data =
                            resList.map((item) => {
                                return Object.assign({}, item, { expand: false });
                            }) || [];
                    }
                })
                .catch((e) => {})
                .finally((fina) => {
                    this.loading = false;
                    this.searchFirst = false
                });
        },
        refresh() {
            console.log("chainId", this.$store.state.wallet.checkDataChanId);
        },
        getParams() {
            let params = {
                transaction_type: this.searchInfo.transaction_type,
                coin_name: this.searchInfo.coin_name,
                token: this.searchInfo.token,
                start_date: getUTCTime(new Date(this.searchInfo.time[0])),
                end_date: getUTCTime(new Date(this.searchInfo.time[1])) + 24 * 60 * 60 * 1000,
                transaction_status: this.searchInfo.transaction_status,
                chain_id: this.chainId,
                // layer: layer,
                layer: MAP.layerMap[this.layer],
                token_type: "ERC20",
            };
            return params;
        },
        copy() {
            this.$Message.config({
                top: 290,
                duration: 10,
            });
            this.$Message.success({
                content: this.$t("wallet_common_copied"),
                duration: this.popShowTime,
                closable: true,
            });
        },
        down() {
            let params = this.getParams();
            let address = this.account;
            params.address = address;
            downloadExcel(params).then((res) => {
                console.log("downloadExcel data", res);
                let _this = this;
                const fileReader = new FileReader();
                fileReader.readAsText(res.data);
                fileReader.onload = function () {
                    try {
                        const result = JSON.parse(this.result);
                        if (result && result["code"]) {
                            _this.$Powx.toast.show(result.message);
                        } else {
                            download(res);
                        }
                    } catch (e) {
                        download(res);
                    }
                };
            }).catch(e => e);
        },
    },
};
