//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tabs from "./components/Tabs"
// import List from "./components/List"
import CoinSelect from "./components/CoinSelect"
import TokenSelect from '../home/component/TokenSelect';
import iconDown from '@/assets/img/wallet/icon-down.png'
import iconUp from '@/assets/img/wallet/icon-up.png'
import { ethers } from "ethers";
import {
    getFinance,
    getDeposit,
    getHistoryTransaction
} from "../../api/wallet/index"
import Address from './components/Address'
import ChainSelect from '../home/component/ChainSelect'
import {
    isTokenETH, formatTokenCommify, getUTCTime, toI18n, getHashBlockUrl, formatUTCTime
} from '../../utils/index'
import { getAddressByMemberId, getUserInfoById } from '../../api/common/index'
let timer

export default {
    components: {
        Tabs,
        // List,
        CoinSelect,
        Address,
        ChainSelect,
        TokenSelect,
    },
    computed: {
        uid() {
            return this.$store.state.user.userInfo?.uid
        },
        tokens() {
            return this.$store.getters.layer1TokenList(this.chainId) || [];
        },
        netWorkList() {
            let networkList = this.$store.getters.walletNetworks || []
            return networkList
        },
        showAvailableBalance: function () {
            if (this.balance == "0.00000000") {
                return false;
            } else {
                return true;
            }
        },
        layer1Balance() {
            return this.layer1BalanceWei && ethers.utils.formatUnits(
                this.layer1BalanceWei.toString(),
                this.curToken.decimals
            );
        },
        layer1BalanceString() {
            return this.layer1Balance && formatTokenCommify(
                this.layer1Balance,
                this.curToken.show_scale
            );
        },
        symbol() {
            return this.curToken.symbol
        }
    },
    watch: {
        account(val) {
            this.depositData[0].address = val || ''
            this.$set(this.depositData, 0, this.depositData[0])
        },
    },
    data() {
        return {
            chainId: null,
            clipboard: "",
            viewAllShow: false,
            confirmation: 0,
            depositData: [{
                address: ''
            }],
            bread: [{
                path: "/wallet",
                name: this.$t('wallet_common_wallet')
            },
            {
                path: "",
                name: this.$t('wallet_common_receive')
            },
            ],
            CoinSelectShow: false,
            curToken: {
                name: "Ethereum",
                symbol: ''
            },
            totalBalance: "",
            balance: "",
            columns: [
                {
                    title: this.$t("wallet_common_date"),
                    key: "date",
                    width: 250,
                    scopedSlots: { customRender: "date" },
                },
                {
                    title: this.$t("wallet_common_asset"),
                    key: "symbol",
                    scopedSlots: { customRender: "symbol" },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    width: 80,
                    scopedSlots: { customRender: "amount" },
                },
                {
                    title: this.$t("wallet_history_status"),
                    key: "status",
                    width: 100,
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "information",
                    width: 550,
                    scopedSlots: { customRender: "information" }
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "action",
                    align: "center",
                    width: 100,
                    scopedSlots: { customRender: "action" }
                },
            ],
            toI18n,
            data: [],
            curChain: {},
            layer1BalanceWei: 0,
            account: '',
            loading: false
        }
    },
    async created() {
        this.$store.commit('setChainIdAndLayerCache', this.chainId + '-1')
        const { symbol, chainId, } = this.$route.query;
        this.chainId = chainId ? Number(chainId) : this.$store.state.chainId;
        if (symbol) {
            this.curToken.symbol = symbol;
        }
        this.fetchTokens();
    },
    mounted() {
        this.$store.commit('setChainIdAndLayerCache', this.chainId + '-1')
    },
    methods: {
        async fetchTokens() {
            await this.$store.dispatch("getL1TokenList", this.chainId);
            if (!this.curToken.symbol) {
                // this.curToken.symbol = this.tokens[0].symbol;
                this.curToken = structuredClone(this.tokens[0])
            } else {
                // 检查选中的symbol是否在当前tokens中
                const symbols = this.tokens.map(item => item.symbol);
                if (!symbols.includes(this.symbol)) {
                    this.curToken = structuredClone(this.tokens[0])
                }
            }
            console.log('this.symbol:', this.symbol)
            const item =  this.tokens.find(v => v.symbol == this.symbol);
            this.changeCoin(item || {});
        },
        async getAccountAddress() {
            let id = this.$store.state.user.userInfo?.id
            let userInfoRes = await getUserInfoById(id)
            let address = userInfoRes.code == '200' && userInfoRes.data && userInfoRes.data.wallet_address
            this.account = address
            this.depositData[0].address = address || ''
            this.$set(this.depositData, 0, this.depositData[0])
            return address
        },
        toHistory() {
            this.$router.push({
                path: "history",
                query: {
                    transaction_type: 'deposit',
                    coin_name: this.curToken.name,
                }
            })
        },
        // 更改chains
        changeChain() {
            this.fetchTokens();
        },
        changeCoin(item) {
            if (!Object.keys(item).length) {
                return;
            }
            this.curToken = structuredClone(item);
            this.depositData[0] = this.curToken
            this.getInfo();
            // this.getHistoryTransaction()
            // this.refreshHistoryByTime()
        },
        change({
            confirmation
        }) {
            this.confirmation = confirmation;
        },
        async getInfo() {
            if (this.chainId) {
                let currentNet = this.netWorkList.find((v, i) => {
                    return v.chainId == this.chainId;
                });
                this.curChain = currentNet
                this.account = await this.getAccountAddress()
                this.getLayer1Balance(true);
            }
        },
        async getHistoryTransaction() {
            if (!this.account) {
                this.account = await this.getAccountAddress()
            }
            let params = {
                page_no: 0,
                page_size: 5,
                coin_name: this.curToken.symbol,
                transaction_type: 'Deposit',
                transaction_status: 'all',
                address: this.account,
                start_date: getUTCTime(new Date()) - 30 * 24 * 60 * 60 * 1000,
                end_date: getUTCTime(new Date()) + 24 * 60 * 60 * 1000,
                chain_id: this.chainId,
                layer: "L1",
            }
            this.loading = true
            getHistoryTransaction(params).then(res => {
                if (res.code == 200) {
                    console.log(res)
                    this.viewAllShow = res.data.total_number > 5;
                    this.data = res.data.transaction_history_list.map(item => {
                        return Object.assign(item, {
                            expand: false
                        })
                    });
                }
            }, res => {
                console.log('error-msg:', res)
            }).catch(e => e).finally(fina => {
                this.loading = false
            })
        },
        refreshHistoryByTime() {
            clearInterval(timer);
            timer = setInterval(() => {
                // this.getHistoryTransaction(true);
                this.getLayer1Balance(true);
            }, 20 * 1000);
        },
        async getLayer1Balance(hideLoading) {
            const rpcUrl = this.curChain.rpcUrl;
            const chainId = this.chainId;
            const tokenAddress = this.curToken.contract_address;
            const tokenType = this.curToken.token_type;
            if (!tokenAddress) return
            if (!hideLoading) {
                this.loading_one = true;
            }
            this.loadLayer1Failed = false;
            let balance;
            console.log('rpcUrl:', rpcUrl)
            if (isTokenETH(tokenAddress)) {
                balance = await this.walletUtil
                    .getWeb3Erc20Amount(rpcUrl, this.account)
                    .then((res) => res)
                    .catch((e) => {
                        console.log('e:', e)
                        this.loadLayer1Failed = true;
                        return "0";
                    });
                console.log('balance:', balance)
            } else {
                const contract = await this.walletUtil.dealWeb3QueryContract(
                    rpcUrl,
                    tokenAddress
                );
                balance = await contract.methods.balanceOf(this.account).call()
                    .catch((e) => {
                        console.error("[erc20]getLayer1Balance e:", e);
                        this.loadLayer1Failed = true;
                        return "0";
                    })
            }
            // 判断网络和币种是否切换
            if (chainId === this.chainId && tokenAddress === this.curToken.contract_address) {
                this.layer1BalanceWei = balance;
                console.log('this.layer1BalanceWei:', this.layer1BalanceWei)
            }
            this.loading_one = false;
        },
        copy() {
            this.$Message.config({
                top: 290,
                duration: 1.5
            });
            this.$Message.success({
                content: this.$t('wallet_common_copied'),
            })
        },
        showInfo(item, index, column) {
            item.expand = !item.expand
            this.$set(this.data, index, item)
        },
        goExplorer(txHash) {
            let url = getHashBlockUrl(txHash, this.chainId, 'L2', this.$store)
            // console.log('url:', url)
            window.open(url, 'receive-explorer')
        },
        formatUTCTime(date) {
            return formatUTCTime(date);
        }
    },
    beforeDestroy() {
        this.$store.commit('setChainIdAndLayerCache', '')
    },
    destroyed() {
        timer = null
        clearInterval(timer)
    }
}
