//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import brigdePage from '@cms/components/pageComponents/index/component/bridge-page.vue'
import { getHistoryTransaction } from "../../../api/wallet/index";
import { getUTCTime, historyStatus } from "../../../utils/index";
import copy from "clipboard-copy"
export default {
    name: 'bridge-history',
    components: {
        brigdePage
    },
    data() {
        return {
            historyColumns: [
                {
                    title: this.$t('wallet_common_date'),
                    key: "date",
                    scopedSlots: { customRender: "date" },
                },
                {
                    title: this.$t('wallet_bridge_coins_from'),
                    key: "from",
                    scopedSlots: { customRender: "from" },
                },
                {
                    title: this.$t('wallet_common_asset'),
                    key: "asset",
                    scopedSlots: { customRender: "assets" },
                },
                {
                    title: this.$t('wallet_common_amount'),
                    key: "amount",
                    scopedSlots: { customRender: "amount" },
                },
                {
                    title: this.$t('wallet_common_status'),
                    key: "status",
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: this.$t('wallet_common_information'),
                    key: "information",
                    scopedSlots: { customRender: "information" }
                }
            ],
            bridgeHistoryData: [],
            symbol: '',
            timer: null,
            loading: false,
            showInformationToast: false,
            informationData: {},
            informationi18nData: {
                'Asset': this.$t('wallet_common_asset'),
                'From': this.$t('wallet_bridge_coins_from'),
                'To': this.$t('wallet_bridge_coins_to'),
                'Address': this.$t('wallet_common_address'),
                'TXID': this.$t('wallet_history_txid'),
                'Transaction Amount': this.$t('wallet_history_transaction_amount') // Transaction Fee
            },
            bridgeDataMap: {
                symbol : 'Asset',
                date : 'Date',
                from_network : 'From',
                amount : 'Amount',
                status:  'Status'
            },
            historyStatus
        }
    },
    computed: {
        account() {
            return this.$store.getters.account || '';
        }
    },
    watch: {
        account(val, oldVal) {
            if(val != oldVal) {
                this.getHistoryTransaction()
            }
        }
    },
    mounted() {
        let queryData = this.$route.query || {}
        this.symbol = queryData.symbol
        this.coinName = queryData.name
        this.chainId = queryData.chainId
        this.getHistoryTransaction()
    },
    methods: {
        getBridgeData() {

        },
        checkDetail(item) {
            console.log('checkDetail:', item)
            this.informationData = {
                'Asset': item.symbol,
                'From': item.from_network,
                'To': item.to_network,
                'Address': item.to_address,
                'TXID': item.from_tx_hash,
                'Transaction Amount': item.amount + ' ' + item.symbol // Transaction Fee
            }
            this.showInformationToast = true
        },
        goBack() {
            this.$router.back()
        },
        requestParans() {
            let params = {
                coin_name: this.coinName || 'All',
                layer: 'L1',
                token_type: 'ERC20',
                status: 'All',
                type: 'All',
                token: this.symbol || '',
                address: this.account,
                transaction_type: 'Bridge',
                transaction_status: 'All',
                start_date: getUTCTime(new Date()) - 30 * 24 * 60 * 60 * 1000,
                end_date: getUTCTime(new Date()) + 24 * 60 * 60 * 1000,
                chain_id: this.chainId
            }
            return params
        },
        getHistoryTransaction(page_no = 0, refresh) {
            let params = {
                page_no: page_no,
                page_size: 10
            };
            params = Object.assign(params, this.requestParans())
            this.loading = !refresh && true || false;
            let activeId = ''
            if (refresh && this.bridgeHistoryData.length) {
                if(!this.timer) return
            }
            if (!params.chain_id) return
            getHistoryTransaction(params).then((res) => {
                if (res.code == 200) {
                    res.data.total_number = res.data.total_number || 0
                    this.bridgeHistoryData = res.data.bridge_list || []
                    this.openTimeFunc()
                }
            }).catch((e) => {}).finally((fina) => {
                this.loading = false;
            })
        },
        openTimeFunc() {
            let finishArrData = this.bridgeHistoryData.filter(v => v.status == 'Finalized' || v.status == 'Failure')
            if (finishArrData.length != this.bridgeHistoryData.length && this.bridgeHistoryData.length) {
                this.refreshHistoryByTime(6 * 1000)
            } else {
                clearInterval(this.timer)
                this.timer = null
            }
        },
        refreshHistoryByTime(time) {
            if (this.timer) return
            this.timer = setInterval(() => {
                this.getHistoryTransaction(0, true)
            }, time || 6 * 1000)
        },
        copyInfo(val) {
            copy(val)
            this.$message.success({
                content: this.$t('common_base_copied'),
                closeOnClick: true,
                duration: 5
            })
        },
        formatAccount(value) {
            if (!value) return "";
            return value.slice(0, 6) + " ****** " + value.slice(-6, value.length);
        }
    },
    destroyed() {
        clearInterval(this.timer)
        this.timer = null
    }
}
