//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Confirmation from "./component/Confirmation"
import { checkAddress, addAddress} from "../../api/wallet/index"
export default {
    components: {
        Confirmation
    },
    computed: {
        curInfo() {
            return {
                amount: this.$store.state.wallet.withdrawInfo.withdraw_amount,
                symbol: this.$store.state.wallet.withdrawInfo.symbol,
                address: this.$store.state.wallet.withdrawInfo.address,
                networkId: this.$store.state.wallet.withdrawInfo.network_id
            }
        }
    },
    data() {
        return {
            bread: [
                {
                    path: "/wallet",
                    name: this.$t('wallet_common_wallet')
                },
                {
                    path: "",
                    name: this.$t('wallet_withdraw_result')
                },
            ],
            isSave:false,
            saveFlag: false,
            curName: '',
            errorShow: false,
            errInfo: this.$t('Please enter name')
        }
    },
    created() {
        checkAddress({address:this.curInfo.address,network_id:this.curInfo.networkId,verify_repeat:true}).then(res=>{
            this.isSave = !res.data;
        },res=> {
            this.isSave = true;
        }).catch(e => e)
    },
    watch:{
        curName(val,oldVal){
            if (!this.curName){
                this.errorShow = true;
            } else {
                this.errorShow = false;
            }
        }
    },
    methods: {
        goHistory(){
            this.$router.push({
                path: "history",
                query: {
                    transaction_type: 'withdraw',
                    coin_name: '',
                }
            })
        },
        showSave(){
            this.saveFlag = true;
        },
        save(){
            if (!this.curName){
                this.errorShow = true;
                return;
            }
            let params = Object.assign({},{
                    network_id: this.curInfo.networkId,
                    address: this.curInfo.address,
                    name: this.curName
                });
            addAddress(params).then(res => {
                this.isSave = true;
                this.hide();
            },res=> {

            }).catch(e => e)
        },
        hide(){
            this.saveFlag = false;
            this.curName = '';
        },
    }
}
