//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'bridge-history',
    props: {
        showItem: {
            type: Array,
            default: () => {
                return ['header', 'content', 'footer']
            }
        }
    },
    data() {
        return {

        }
    }
}
