//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./component/List";
import CoinSelect from "./component/CoinSelect";
import NftSelect from "./component/NftSelect.vue";
import NetworkSelect from "./component/NetworkSelect.vue";
import ChainSelect from "./component/ChainSelect.vue";
import TokenSelect from "./component/TokenSelect.vue";
import NftNetworkSelect from "./component/NftNetworkSelect.vue";
import Confirmation from "./component/Confirmation";
import iconDown from "../../assets/img/icon-down.png";
import iconUp from "../../assets/img/icon-up.png";
import {
    getLayer2Balance,
    getSubmitTokens,
    getHistoryTransaction,
    getSignleSymbolPrice,
    getRestFee,
    fetchNftList,
    // 新版2层中心化相关
    l2CommonWithdraw,
    l2AccountActive,
    withdrawUnavailable,
} from "../../api/wallet/index";
import securityVerif from "../../components/securityVerif.vue";
import { ethers, utils, BigNumber } from "ethers";
import {
    onEvents,
    emitEvents,
    isUserCenter,
    getWalletCoinFee,
    emitLoading,
    accAddSub,
    getChainId,
    getWalletNetWorkConfig,
    getChainLayer2Config,
    checkEthAddress,
    getExplorer,
    parseNFTData,
    getL2NetworkList,
    formatArrStringNum,
    filterL2Balance,
    formatTokenCommify,
    formatFiatCommify,
} from "../../utils/index";
import NetworkTab from "./component/NetworkTab";
import content_hashUtils from "../../utils/content-hash";
import { uploadFile, uploadJson, IPFS_PREVIEW_URL } from "../../utils/ipfs.js";
import axios from "axios";
import wallet from '../../plugin/Wallet/walletUtil';
import * as VE from "@vigo.technology/v-engine";
import BNP from "bignumber.js";
let firstInput = false;
export default {
    name: 'Withdraw',
    components: {
        List,
        CoinSelect,
        Confirmation,
        securityVerif,
        NetworkTab,
        NetworkSelect,
        NftSelect,
        NftNetworkSelect,
        ChainSelect,
        TokenSelect,
    },
    data() {
        return {
            accountTipVisible: false,
            showActive: false,
            feeToken: '',
            canWithdrawal: false,
            showToast: false,
            submitError: null,
            clipboard: "",
            viewAllShow: false,
            confirmation: {},
            symbol: "",
            countTime: 0,
            curNotice: "",
            tabId:'token',
            bread: [
                {
                    path: "/wallet",
                    name: this.$t("wallet_common_wallet"),
                },
                {
                    path: "/walletconnect/withdraw/index",
                    name: this.$t("wallet_common_withdraw"),
                },
            ],
            CoinSelectShow: false,
            coinValue: {
                name: "Ethereum",
                symbol: "",
            },
            totalBalance: "",
            balance: "",
            availableBalanceWei: 0,
            lockedBalance: "",
            isTokenAvailble: true,
            columns: [
                {
                    title: this.$t("wallet_common_date"),
                    key: "time",
                    dataIndex: "time",
                    width: 210
                },
                {
                    title: this.$t("wallet_withdraw_coin"),
                    key: "symbol",
                    // width: 80,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.symbol}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    // width: 120,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.action_symbol} ${record.amount}`
                        )
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    // width: 120,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_completed = this.$t("wallet_history_completed");
                        let st_processing = this.$t("wallet_history_processing");
                        if (record.status == 'Completed') {
                            return h("span", {}, st_completed);
                        } else if (record.status == 'Processing') {
                            let netArr = record.network_confirmation.split("/");
                            let start = netArr.shift();
                            let end = "/ " + netArr.shift();
                            return h(
                                "div",
                                {
                                    class: {
                                        status: true,
                                    },
                                },
                                [
                                    h("p", {}, st_processing),
                                    h(
                                        "p",
                                        {
                                            class: {
                                                processing: true,
                                            },
                                        },
                                        [
                                            h("span", "( "),
                                            h(
                                                "span",
                                                {
                                                    class: {
                                                        already: true,
                                                    },
                                                },
                                                `${start} `
                                            ),
                                            h("span", `${end} )`),
                                        ]
                                    ),
                                ]
                            );
                        } else {
                            return h("span", {}, record.status);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "key5",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_address = this.$t("wallet_common_address");
                        if (record.expand) {
                            let btns = [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        ),
                                    ]
                                ),
                            ];

                            if (record.txid) {
							let txid = record.txid.indexOf("sync-tx:") >= 0 ? record.txid.substring(8) : record.txid;
							const url = this.blockExplorerUrl + `tx/${txid}`
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "a",
                                                {
                                                    attrs: {
                                                        href: url,
                                                        target: "_target",
                                                    },
                                                    style: {
                                                        lineHeight: "1em",
                                                        display: "flex",
                                                    },
                                                },
                                                [
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display:
                                                                    "inline-block",
                                                            },
                                                        },
                                                        this.$t(
                                                            "wallet_history_txid"
                                                        ) + ":"
                                                    ),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display: "inline-block",
                                                                lineSize: "fit-content",
                                                                wordBreak: "break-word"
                                                            },
                                                        },
                                                        [
                                                            record.txid,
                                                            h("img", {
                                                                style: {
                                                                    display:
                                                                        "inline-block",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    verticalAlign:
                                                                        "-4px",
                                                                    marginLeft:
                                                                        ".2em",
                                                                },
                                                                attrs: {
                                                                    src: require("../../assets/img/share-link.png"),
                                                                },
                                                            }),
                                                        ]
                                                    ),
                                                ]
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.transaction_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_deposit_transaction_fee"
                                                ) + ":"
                                            ),
                                            h(
                                                "span",
                                                {},
                                                record.transaction_fee
                                            )
                                        ]
                                    )
                                )
                            }
                            if (record.gas_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_history_swap_fee"
                                                ) + ":"
                                            ),
                                            h("span", {}, record.gas_fee),
                                        ]
                                    )
                                );
                            }
                            return h("div", {}, btns);
                        } else {
                            return h("div", [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        )
                                    ]
                                )
                            ]);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "",
                    width: 100,
                    align: "center",
                    customRender: (text, record, index) => {
                        let h = this.$createElement
                        let copyFn = this.copy;
                        let icon = record.expand ? iconUp : iconDown;
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_auditFailure = this.$t("wallet_history_audit_failure");
                        let btn = h("img", {
                            style: {
                                cursor: "pointer",
                            },
                            attrs: {
                                width: "20px",
                                height: "20px",
                                src: icon,
                            },
                            on: {
                                click: () => {
                                    this.data[index].expand = !this.data[index].expand;
                                },
                            },
                        });
                        let copy = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        this.clipboard = record.txid || "";
                                        this.$nextTick(function () {
                                            this.$refs.btn.click();
                                        });
                                    },
                                },
                            },
                            this.$t("wallet_common_copy")
                        );
                        let check = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        if (this.needTwoFA) {
                                            this.showToast = true;
                                        } else {
                                            this.getSubmitTokens({});
                                        }
                                    },
                                },
                            },
                            this.$t("wallet_common_check")
                        );
                        let btns = [btn];
                        if (record.expand) {
                            if (record.txid) {
                                btns.push(copy);
                            }
                            if (
                                record.type == st_withdraw &&
                                record.status == st_auditFailure
                            ) {
                                btns.push(check);
                            }
                        }
                        return h("div", btns);
                    },
                },
            ],
            nft_columns: [
                {
                    title: this.$t("wallet_common_date"),
                    key: "time",
                    dataIndex: "time",
                    width: 210
                },
                {
                    title: this.$t("wallet_withdraw_coin"),
                    key: "symbol",
                    width: 80,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.symbol}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    width: 120,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.action_symbol} 1`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    width: 120,
                    customRender: (text, record) => {
                        let st_completed = this.$t("wallet_history_completed");
                        let st_processing = this.$t(
                            "wallet_history_processing"
                        );
                        if (record.status == st_completed) {
                            return h("span", {}, st_completed);
                        } else if (record.status == st_processing) {
                            let netArr = record.network_confirmation.split("/");
                            let start = netArr.shift();
                            let end = "/ " + netArr.shift();
                            return h(
                                "div",
                                {
                                    class: {
                                        status: true,
                                    },
                                },
                                [
                                    h("p", {}, st_processing),
                                    h(
                                        "p",
                                        {
                                            class: {
                                                processing: true,
                                            },
                                        },
                                        [
                                            h("span", "( "),
                                            h(
                                                "span",
                                                {
                                                    class: {
                                                        already: true,
                                                    },
                                                },
                                                `${start} `
                                            ),
                                            h("span", `${end} )`),
                                        ]
                                    ),
                                ]
                            );
                        } else {
                            return h("span", {}, record.status);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "key5",
                    customRender: (text, record) => {
                        let st_address = this.$t("wallet_common_address");
                        let h = this.$createElement
                        if (record.expand) {
                            let btns = [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        ),
                                    ]
                                ),
                            ];

                            if (record.txid) {
							let txid = params.row.txid.indexOf("sync-tx:") >= 0 ? record.txid.substring(8) : record.txid;
							const url = this.blockExplorerUrl + `tx/${txid}`
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "a",
                                                {
                                                    attrs: {
                                                        href: url,
                                                        target: "_target",
                                                    },
                                                    style: {
                                                        lineHeight: "1em",
                                                        display: "flex",
                                                    },
                                                },
                                                [
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display:
                                                                    "inline-block",
                                                            },
                                                        },
                                                        this.$t(
                                                            "wallet_history_txid"
                                                        ) + ":"
                                                    ),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display: "inline-block",
                                                                lineSize: "fit-content",
                                                                wordBreak: "break-word"
                                                            },
                                                        },
                                                        [
                                                            params.row.txid,
                                                            h("img", {
                                                                style: {
                                                                    display:
                                                                        "inline-block",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    verticalAlign:
                                                                        "-4px",
                                                                    marginLeft:
                                                                        ".2em",
                                                                },
                                                                attrs: {
                                                                    src: require("../../assets/img/share-link.png"),
                                                                },
                                                            }),
                                                        ]
                                                    ),
                                                ]
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.transaction_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_deposit_transaction_fee"
                                                ) + ":"
                                            ),
                                            h(
                                                "span",
                                                {},
                                                record.transaction_fee
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.gas_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_history_swap_fee"
                                                ) + ":"
                                            ),
                                            h("span", {}, record.gas_fee),
                                        ]
                                    )
                                );
                            }
                            return h("div", {}, btns);
                        } else {
                            return h("div", [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        )
                                    ]
                                )
                            ]);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "",
                    width: 100,
                    align: "center",
                    customRender: (text, record, index) => {
                        let h = this.$createElement
                        let copyFn = this.copy;
                        let icon = record.expand ? iconUp : iconDown;
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_auditFailure = this.$t(
                            "wallet_history_audit_failure"
                        );
                        let btn = h("img", {
                            style: {
                                cursor: "pointer",
                            },
                            attrs: {
                                width: "20px",
                                height: "20px",
                                src: icon,
                            },
                            on: {
                                click: () => {
                                    this.nftWithdrawData[index].expand = !this.nftWithdrawData[index].expand;
                                },
                            },
                        });
                        let copy = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        this.clipboard = record.txid || "";
                                        this.$nextTick(function () {
                                            this.$refs.btn.click();
                                        });
                                    },
                                },
                            },
                            this.$t("wallet_common_copy")
                        );
                        let check = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        if (this.needTwoFA) {
                                            this.showToast = true;
                                        } else {
                                            this.getSubmitTokens({});
                                        }
                                    },
                                },
                            },
                            this.$t("wallet_common_check")
                        );
                        let btns = [btn];
                        if (record.expand) {
                            if (record.txid) {
                                btns.push(copy);
                            }
                            if (
                                record.type == st_withdraw &&
                                record.status == st_auditFailure
                            ) {
                                btns.push(check);
                            }
                        }
                        return h("div", btns);
                    },
                },
            ],
            data: [],
            depositData: [],

            curAddress: "",
            custAddressShow: false,
            preAddress: sessionStorage.getItem("WITHDRAWADDRESS"),
            custAddressError: null,
            timeOut: null,

            curAmount: null,
            amountError: null,
            submitDis: true,
            transaction_fee: "",
            loading: false,
            transaction_fee_value: 0,
            decimals: 0,
            amountDecimals: 0,
            userCenter: isUserCenter(),
            balance_loading: false,
            balance_list_loading: false,
            network_list: [],
            chainId: "",
            dealAll: false,


            //nft
            nftNetworkList:[],
            nftNetworkExpand:false,
            nftNetwork:'',
            nftChainId:null,
            nftLoading:false,
            withdrawNFTLoading:false,
            nftExpand:false,
            nftId:'',
            nftSelectShow:false,
            nftList:[],
            nftCoinValue:{
                name: "ETH",
                symbol: "",
            },
            nft_list_loading:false,
            nftWithdrawData:[],
            nftViewAllShow:false,
            popShowTime: this.$store.state.popShowTime || 2
        };
    },
    computed: {
		// account() {
		// 	return this.$store.state.wallet.wallet.address;
		// },
        // 链的数据，统一使用ChainSelect组件
        netWorkList() {
            let networkList = this.$store.getters.walletNetworks || []
            return networkList
        },
        tokens() {
			return this.$store.getters.chainLayerToken(`${this.chainId}-2`) || [];
		},
        // 判断是否需要2FA验证，可以在后台配置
        needTwoFA() {
            return this.$store.getters.needTwoFA;
        },
        account() {
			return this.$store.getters.account || '';
		},
        symbolMarket() {
            return this.$store.state.user.currency.symbol
        },
		layer1NetworkList() {
			return this.$store.getters.layer1NetworkList;
		},
        blockExplorerUrl() {
            // 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
            const privateUrl = window.site_config.block_explorer_url;
            if (privateUrl) {
                return privateUrl.endsWith("/") ? `${privateUrl}${this.chainId}/L2/` : `${privateUrl}/${this.chainId}/L2/`;
            } else {
				const url = this.$store.getters.filterNetworkItem(this.chainId, 'L2').blockExplorerUrl;
            	return url.endsWith("/") ? url : url + '/';
            }
        },
        supportNFT() {
            return this.$store.getters.supportNFT;
        },
        availableAmount() {
            return utils.formatUnits(this.availableBalanceWei, this.coinValue.decimals);
        },
		maxAmount() {
            let fee = this.isTokenAvailble ? this.transaction_fee : 0
			if (new BNP(this.availableAmount).gt(fee)) {
				return new BNP(this.availableAmount)
					.minus(fee)
					.toString();
			}
			return 0;
		},
        // 判断是否中心化
        accountIsCustody() {
            return this.$store.getters.accountIsCustody;
        },
    },
    created() {
        this.$nextTick(() => {
            onEvents("refresh", (events) => {
                if (!!~events.indexOf("currency")) {
                    this.getTokenFee(this.coinValue.symbol);
                }
            })
        })
    },
    watch: {
        transaction_fee(val) {
            if (val) {
                this.getSignleSymbolPrice();
            }
        },
        curAmount(value) {
            if (!firstInput && value.length > 0) {
                firstInput = true;
            }
            if (firstInput) {
                this.checkAmount();
            }
        },
		'$store.state.wallet.checkDataChanId': function (chainId) {
			this.chainId = chainId
			this.nftChainId = chainId
		},
        '$store.state.chainId': function (chainId) {
            this.init()
        },

        //nft
        nftNetwork(val){
            this.nftList = [];
            this.fetchNftList();
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
			const cacheTokenSymbol = sessionStorage.getItem("TokenSymbol");
			const { symbol, chainId, address, tab } = this.$route.query;
            this.tabId = tab || "token";
            this.symbol = symbol
			this.coinValue.symbol = symbol || cacheTokenSymbol;
            // this.chainId = chainId || this.$store.state.wallet.walletChainId;
            // 改为统一获取方式
			this.chainId = Number(chainId || this.$store.state.chainId);
            this.curAddress = address;
            // this.getInfo();

            this.matchWalletChainId();
            if (this.supportNFT) {
                this.setNftNetworkList();
                this.fetchNftList();
            }
            this.fetchTokens();
        },
        async fetchTokens() {
			await this.$store.dispatch("getTokenByChainAndLayer", {chainLayer: `${this.chainId}-2`}).catch(e => []);
            if (!this.symbol) {
				this.symbol = this.tokens[0] && this.tokens[0].symbol;
                this.coinValue.symbol = this.symbol
                this.coinValue = structuredClone(this.tokens[0]) || {};
			} else {
				// 检查选中的symbol是否在当前tokens中
				const symbols = this.tokens.map(item => item.symbol);
				if (!symbols.includes(this.symbol)) {
					this.symbol = this.tokens[0] && this.tokens[0].symbol;
                    this.coinValue = structuredClone(this.tokens[0]) || {};
				} else {
                    let coinValue = this.tokens.find(item => item.symbol == this.symbol);
                    this.coinValue = structuredClone(coinValue) || {};
                }
			}
			this.changeCoin(this.coinValue);
		},

        changeTab(key){
            this.tabId=key;
            if(key==='nft'){
                this.getNftWithdrawHistory();
            }
        },
        async getFeeFunc(symbol = "ETH") {
            let config = getChainLayer2Config(this.chainId) || [];

            let params = {
                url: config.rest,
                tokenLike: symbol,
                txType: "Withdraw",
                address: this.account,//this.walletData.getAddress(),
            };
            this.resetFee();
            getRestFee(params)
                .then((res) => {
                    if (res.result) {
                        let fee = res.result.totalFee;
                        // fee = "1857"
                         this.transactionFeeWei = VE.utils.closestPackableTransactionAmount(fee).toString();
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            this.coinValue.decimals || 18
                        );
                    }
                })
                .catch((err) => {
                    this.resetFee();
                });
        },
        async getTokenFee(symbol = this.symbol) {
            this.isTokenAvailble = await this.$store.dispatch('isTokenAvailable', {chainId: this.chainId, symbol})
            console.log('this.isTokenAvailble', this.isTokenAvailble)
            if (this.isTokenAvailble) {
                this.getFeeFunc(symbol)
                this.feeToken = symbol
            } else {
                this.$store.dispatch("getL2Fee", {
                    chainId: this.chainId,
                    address: this.account,
                    txType: "Transfer"
                }).then(async (res) => {

                    console.log('getL2Fee:', res)
                    if (res) {
                        this.feeToken = res.symbol;
                        let feeTokenDetail = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
                        let fee = res.totalFee;
                        this.transactionFeeWei = VE.utils.closestPackableTransactionAmount(fee).toString();
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            feeTokenDetail.decimals || 18
                        );
                        console.log("this.transaction_fee",
                            this.transactionFeeWei,
                            this.transaction_fee
                        );
                        this.checkAmount();
                    } else { // 没有返回有余额的币种, 显示当前手续费
                        this.getFeeFunc(symbol)
                        this.feeToken = symbol
                    }
                })
                .catch((err) => {
                    console.log('err:', err)
                    this.resetFee();
                });
            }
        },
        async getSignleSymbolPrice() {
            let feeTokenDetai = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
            console.log("this.$store.state.user", this.$store.state.user);
            getSignleSymbolPrice({
                fsym: this.feeToken,
                tsyms: this.$store.state.user.currency.name,
                // chain_id: this.chainId
            })
                .then((getFeeVal) => {
                    this.transaction_fee_value =  formatFiatCommify(
                            this.transaction_fee * getFeeVal[this.$store.state.user.currency.name], feeTokenDetai.show_scale
                        );
                })
                .catch((err) => {
                    console.log("err", err);
                    this.transaction_fee_value = formatFiatCommify(0, feeTokenDetai?.show_scale);
                });
        },
        changeCoin(item) {
            if (!Object.keys(item).length) {
                return this.balance_list_loading = false;
            }
            this.coinValue = item;
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
            sessionStorage.setItem("TokenSymbol", this.coinValue.symbol);
            this.clearPagesCache();
            this.getInfo();
            this.getTokenFee(item.symbol);
            this.getAmoutDecimals();
            this.setSearchKey()

        },
        getAmoutDecimals() {
            this.amountDecimals = this.coinValue.show_scale;
        },
        onChangeChain(item) {
            this.confirmation = Object.assign({}, item);
            this.chainId = item.chainId;
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
            this.$store.commit("setCheckDataChanId", item.chainId);
            this.fetchTokens();
        },
        dealChangeWallet() {
            let chainId = getChainId() + "";
            if (this.chainId != chainId) {
                this.$bus.$emit("changeBridgeNetWork", {
                    chainId: this.chainId,
                });
                return false;
            } else {
                return true;
            }
        },
        // address
        adrFocus() {
            this.preAddress && (this.custAddressShow = true);
        },
        addressBlur(value) {
            if (value) {
                this.checkAddress(value);
            } else {
                this.custAddressShow = false;
                this.custAddressError = this.$t(
                    "wallet_withdraw_message_error_addressBlurError"
                );
            }
        },
        checkAddress(value) {
            this.curAddress = value;
            this.custAddressError =
                (checkEthAddress(value) && this.$t("wallet_invalid_address")) ||
                "";
            this.setsubmitDis();
        },
        blurOrClick(e) {
            let nodeName = e.target.nodeName;
            this.timeOut && clearTimeout(this.timeOut);
            return (() => {
                this.timeOut = setTimeout(() => {
                    if (nodeName == "INPUT") {
                        this.custAddressShow = false;
                        this.addressBlur(e.target.value);
                    } else if (nodeName == "DIV") {
                        this.custAddressShow = false;
                        this.checkAddress(this.preAddress);
                    }
                }, 300);
            })();
        },
        clearCurAddress() {
            this.curAddress = "";
            this.checkAddress();
        },
        // amount
        curAmountInput(e) {
            // let num = this.amountDecimals;
            // let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            // let value = e.target.value;
            // this.curAmount = value.replace(reg, "$1.$2");
            // e.target.value = this.curAmount;

            let iptRegExp = /\d|\./g
            if (!iptRegExp.test(e.data) && e.inputType == 'insertText' || e.data== '.' && e.target.value.split('.').length > 2) {
                e.target.value = this.curAmount = this.curAmount.slice(0, this.curAmount.length)
                return
            }
            let value = e.target.value;
            let num = this.amountDecimals;
            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            this.curAmount = value.replace(reg, "$1.$2");
            this.dealAll = false
        },
        checkAmount() {
            if (!this.curAmount) {
                this.amountError = this.$t(
                    "wallet_withdraw_message_error_amountError"
                );
            } else if (!(Number(this.curAmount) > 0)) {
                this.amountError = this.$t(
                    "wallet_withdraw_message_error_invalid"
                );
            } else if (
                new BNP(this.curAmount).gt(this.maxAmount)
            ) {
                this.amountError = this.$t(
                    "wallet_withdraw_message_error_insufficientError"
                );
            } else {
                this.amountError = null;
            }
            this.setsubmitDis();
        },
        goAddress(routerName = 'address') {
            this.$router.push({
                name: routerName,
                query: {
                    symbol: this.symbol,
                    network_id: this.chainId,
                    coin_name: this.coinValue.name,
                    network_name: this.confirmation.network_name,
                    router_name: this.$route.name,
                },
            });
        },
        checkParams(params) {
            if (!params.address) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_addrIncorrect"
                );
                return 0;
            } else if (!params.amount) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_amountIncorrect"
                );
                return 0;
            } else if (!params.network_id) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_networdIncorrect"
                );
                return 0;
            } else {
                this.curNotice = "";
            }
        },
        setsubmitDis() {
            if (
                new BNP(this.curAmount).gt(this.maxAmount)
            ) {
                this.submitDis = true;
                return;
            }
            if (!this.canWithdrawal) {
                this.submitDis = true;
                return;
            }
            if (
                this.curAddress &&
                !this.custAddressError &&
                this.curAmount &&
                !this.amountError
            ) {
                this.submitDis = false;
            } else {
                this.submitDis = true;
            }
        },
        goSubmit() {
            if (this.submitDis) {
                return;
            }
            if (!this.accountIsCustody) {
                const isCurrentChain = this.dealChangeWallet();
                if (!isCurrentChain) return;
            }
            const params = {
                address: this.curAddress,
                amount: this.curAmount,
                // 中心化的账号在confirmation没有transaction_fee
                fee: this.confirmation.transaction_fee || this.transaction_fee,
                coin: this.coinValue.symbol,
                // 中心化的账号在confirmation上是驼峰
                network_id: (this.confirmation.network_id || this.confirmation.networkId) || 1,
                network_name: this.confirmation.chainName,
                symbol: this.symbol,
            };
            if (!params.address) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_addrIncorrect"
                );
                return 0;
            } else if (!params.amount) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_amountIncorrect"
                );
                return 0;
            } else if (!params.network_id) {
                this.curNotice = this.$t(
                    "wallet_withdraw_message_error_networdIncorrect"
                );
                return 0;
            } else {
                this.curNotice = "";
            }

            if (this.curAddress.toLowerCase() !== this.account.toLowerCase()) {
                this.accountTipVisible = true;
                return false;
            }
            // 1、中心化和去中心化流程不一样，这里判断一下
            if (this.accountIsCustody) {
                // 中心化
                this.handleCustodySubmit(params);
            } else {
                // 去中心化
                this.withdrawOrActive();
            }

            // if (!this.walletData.getSyncWallet()) {
            //     this.walletData.init({
            //         successFn: () => {
            //             this.withdrawOrActive();
            //             this.loading = false;
            //         },
            //         loading: () => {
            //             this.loading = true;
            //         },
            //         loadingEnd: () => {
            //             this.loading = false;
            //         },
            //     });
            // } else {
            //     this.withdrawOrActive();
            // }
        },
        // 激活2层账号（改为统一由loginWallet激活了，该方法暂时无用）
        async activeAccount() {
            // 1、中心化和去中心化流程不一样，这里判断一下
            if (this.accountIsCustody) {
                // 中心化2层账号激活
                const { data: submitToken } = await getSubmitTokens();
                try {
                    const res = await l2AccountActive({
                        chain_id: this.chainId,
                        token: this.coinValue.symbol,
                    }, submitToken);

                    if (res && res.is_active) {
                        this.showActive = false;
                    }
                } catch (error) {
                    console.log(error);
                    this.$message.error({
                        content: error.messsage
                    });
                }
            } else {
                // 非中心账号激活
                let syncWallet = this.walletData.getSyncWallet();
                const changePubkey = await syncWallet.setSigningKey({
                    ethAuthType: "ECDSA",
                    feeToken: "ETH",
                });
                await changePubkey.awaitReceipt();
                this.showActive = false;
            }
        },
        async isActive() {
            // let syncWallet = this.walletData.getSyncWallet();
            // console.log("syncWallet:", syncWallet);
            let isActive = false;

            // console.log(
            //     "syncWallet.isSigningKeySet():",
            //     await syncWallet.isSigningKeySet()
            // );
            if (!(await this.$store.dispatch('isL2Active', {
                chainId: this.chainId,
                address: this.account && this.account.toLowerCase(),
            }))) {
                // console.log(
                //     "syncWallet.getAccountId():",
                //     await syncWallet.getAccountId()
                // );
                // if ((await syncWallet.getAccountId()) == undefined) {
                // 	this.$router.push({
                // 		path: "/walletconnect/deposit",
                // 	});
                // }
                isActive = false;
            } else {
                isActive = true;
            }
            return isActive;
        },
        async withdrawOrActive() {
            this.accountTipVisible = false;
            // 异常提取到其他账号，也判断一下是否中心化和去中心化
            if (this.accountIsCustody) {
                this.handleCustodySubmit();
            } else {
                let activeAcount = await this.isActive();
                if (activeAcount) {
                    this.decimals = this.coinValue.decimals;
                    this.withdrawTransaction();
                } else {
                    emitEvents("linkLogin", { type: "active_account", fn: () => {
                        this.getBalance()
                    }});
                }
            }
        },
        async withdrawTransaction() {
            if (this.loading) return;
            this.loading = true;
            let errorStatus = false;
            emitLoading({
                loading: true,
                fn: () => {
                    this.loading = false;
                },
            });
            let syncWallet = this.walletData.getSyncWallet();
            let params = {
                ethAddress: this.curAddress && this.curAddress.toLowerCase(),
                token: this.symbol,
                amount: ethers.utils.parseUnits(
                    this.curAmount + "",
                    this.decimals
                ),
                fee:
                    (this.transaction_fee &&
                        ethers.utils.parseUnits(
                            this.transaction_fee + "",
                            this.decimals
                        )) ||
                    "",
                feeToken: this.feeToken,
                type: "Withdraw"
            };
            let queryFeeParams = {
                address: this.curAddress,
                token: this.symbol,
                amount: this.curAmount + "",
                fee:
                    (this.transaction_fee &&
                        ethers.utils.parseUnits(
                            this.transaction_fee + "",
                            this.decimals
                        )) ||
                    "",
            };
            let feeTokenDetail = {}
            try {
                if (!this.isTokenAvailble) {
                    feeTokenDetail = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
                }
                getWalletCoinFee(
                    Object.assign({ type: "Withdraw" }, queryFeeParams, {token: (this.isTokenAvailble ? this.symbol : this.feeToken)}),
                    async (fee) => {
                        // params.fee = fee.toString() * 1;
                        if (params.token == "ETH") {
                            params.fee = ethers.BigNumber.from(fee);
                        }
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            this.isTokenAvailble ? this.coinValue.decimals : feeTokenDetail.decimals
                        );
                        params.fee = (this.transaction_fee &&
                            ethers.utils.parseUnits(
                                this.transaction_fee + "",
                                this.isTokenAvailble ? this.coinValue.decimals : feeTokenDetail.decimals
                            )) || ""
                        let feeDues = this.transaction_fee
                        if (this.dealAll) {
                            if (!this.isTokenAvailble) {
                                feeDues = 0
                            }
                            this.curAmount = accAddSub(Number(this.balance.replace(/\,/g, "")), feeDues, -1) + ''
                            params.amount = ethers.utils.parseUnits(
                                this.curAmount,
                                this.decimals
                            )
                            this.dealAll = false
                        }
                        try {
                            // unavaiable 币种
                            if (this.isTokenAvailble) {
                                await syncWallet.withdrawFromSyncToEthereum(params);
                            } else {
                                params.address = params.ethAddress
                                params.unAvailabel = true
                                params.fee = params.fee.toString()
                                params.amount = params.amount.toString()
                                console.log("params:", params)
                                let withdraw_Res = await this.walletUtil.transfer_old(params)
                                console.log('withdraw_Res:', withdraw_Res)
                                let config = getChainLayer2Config(this.chainId) || [];
                                let withdrawRes = await withdrawUnavailable(Object.assign({
                                    url: config.rest
                                }, withdraw_Res))
                                this.curAmount = null;
                                if (withdrawRes.status !== 'success') {
                                    let message = withdrawRes.error && withdrawRes.error.message || ''
                                    console.log('transfer-res:', message)
                                    this.$message.error({
                                        content: this.$t("wallet_withdraw_fail")
                                    });
                                    this.loading = false;
                                    emitLoading({
                                        loading: false,
                                    });
                                    return
                                }
                            }
                            !errorStatus && this.$message.success({
                                content: this.$t("wallet_withdraw_success"),
                                duration: this.popShowTime,
                                closable: true,
                            });
                            this.loading = false;
                            this.curAmount = null;
                            emitLoading({
                                loading: false,
                            });
                            setTimeout(() => {
                                this.getInfo();
                            }, 500);
                        } catch (error) {
                            console.log("error", error);
                            errorStatus = true;
                            const h = this.$createElement;
                            this.$message.error({
                                content: [
                                    h('span', this.$t("wallet_withdraw_fail")),
                                    h('br', null),
                                    h('span', `(${error.message})`),
                                ],
                                closable: true,
                            });
                            this.loading = false;
                            emitLoading({
                                loading: false,
                            });
                        }
                    }
                );
            } catch (error) {}
        },
        // 判断中心化账号2层是否激活
        async isCustodyActive() {
            console.log("checkLayer2AccountIsActive");
            return this.$store.dispatch("getL2AccountIdHash", {
                chainId: this.chainId,
                address: this.account && this.account.toLowerCase(),
                // type: "committed",
                // balanceType: "committed",
            }).then((res) => {
                console.log('res:', res);
                if (res && res.pubKeyHash === "sync:0000000000000000000000000000000000000000") {
                    // 未激活，打开激活弹窗
                    this.$bus.$emit('activeLayer2Account', {
                        pubKeyHash: res.pubKeyHash,
                    });
                    return false;
                }
                return true;
            }).catch((err) => {
                this.$message.error({
                    content: err.message || 'Active Failures'
                });
            })
        },
        // 中心化钱包点击确认逻辑
        async handleCustodySubmit(params) {
            const isActive = await this.isCustodyActive();
            if (isActive) {
                // 1、已经激活
                // this.$store.commit('setConfirmFlag', true);
                // this.$store.commit('setSubmitInfo', params);
                this.submit();
            }
        },
        submit() {
            // this.$store.state.confirmFlag = false;
            // this.getSubmitTokens();
            // 重置确认弹窗数据
            // this.$store.commit('setConfirmFlag', false);
            // this.$store.commit('setSubmitInfo', {});
            // 判断是否需要2FA验证
            if (this.needTwoFA) {
                this.showToast = true;
            } else {
                this.getSubmitTokens({});
            }
        },
        toAll() {
            if (this.balance <= 0) {
                this.curAmount = 0;
                return;
            }
            let fee = this.isTokenAvailble ? this.transaction_fee : 0
            let curAmount = accAddSub(Number(this.balance.replace(/\,/g, "")), fee, -1);
            if (curAmount < 0) {
                curAmount = 0;
            }
            let num = this.amountDecimals;
            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            this.curAmount = curAmount.toString().replace(reg, "$1.$2");
            this.checkAmount();
            this.dealAll = true
        },
        toHistory() {
            this.$router.push({
                name: "history",
                query: {
                    transaction_type: "Withdraw",
                    coin_name: this.coinValue.symbol,
                    chainId: this.chainId,
                    layer: 'L2',
                    type: 'ERC20'
                },
            });
        },
		getLayer1NetworkItem(chainId) {
			return this.layer1NetworkList.find(item => item.chainId === chainId);
		},
		getNetworkList(arr) {
			let netWorks = getWalletNetWorkConfig();
			// 融合基础网络列表数据
			netWorks = netWorks.map(item => {
				return Object.assign({}, this.getLayer1NetworkItem(Number(item.chainId)), item);
			})
			return netWorks;
		},
        getInfo() {
            this.depositData = this.getNetworkList();
            if (this.chainId) {
                this.confirmation = this.depositData.find((v) => {
                    return v.chainId == this.chainId;
                });
            }
            this.symbol = this.coinValue.symbol;
            let address = this.account; //this.walletData.getAddress();
            if (!this.curAddress) this.curAddress = address;

            this.getBalance();

            let params = {
                page_no: 0,
                page_size: 10,
                coin_name: this.coinValue.symbol,
                transaction_type: "Withdraw",
                transaction_status: "all",
                start_date: "",
                end_date: "",
                address,
                chain_id: this.chainId,
				layer: "L2",
            };
            this.balance_list_loading = true;
            getHistoryTransaction(params)
                .then((res) => {
                    if (res.code == 200) {
                        this.viewAllShow = res.data.total_number > 5;
                        let networkL2 = getExplorer(this.chainId * 1);
                        let networkL2Name = networkL2 + "_l2";
                        this.data = res.data.transaction_history_list.map(
                            (item) => {
                                item.networkL2Name = networkL2Name;
                                return Object.assign(item, { expand: false });
                            }
                        );
                    }
                }).catch(e => e)
                .finally((fina) => {
                    this.balance_list_loading = false;
                });
        },
        getNftWithdrawHistory() {
            return
            let address = this.account; //this.walletData.getAddress();
            this.getBalance();
            let params = {
                page_no: 0,
                page_size: 10,
                coin_name: '',
                transaction_type: "WithdrawNFT",
                transaction_status: "all",
                start_date: "",
                end_date: "",
                address,
                chain_id: this.nftChainId,
            };
            this.nft_list_loading = true;
            getHistoryTransaction(params)
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.nftViewAllShow = res.data.total_number > 5;
                        let networkL2 = getExplorer(this.chainId * 1);
                        let networkL2Name = networkL2 + "_l2";
                        this.nftWithdrawData = res.data.transaction_history_list.map(
                            (item) => {
                                item.networkL2Name = networkL2Name;
                                return Object.assign(item, { expand: false });
                            }
                        );
                    }
                }).catch(e => e)
                .finally((fina) => {
                    this.nft_list_loading = false;
                });
        },
        getBalance() {
            this.balance_loading = true;
            this.totalBalance = "";
            this.balance = "";
            this.lockedBalance = "";
            let config = getChainLayer2Config(this.chainId) || [];
            let reqUrl = config.rest;
            let queryLayer2Param = {
                url: reqUrl,
                address: this.account,//this.walletData.getAddress(),
                type: "balances",
            };
            if (!queryLayer2Param.address) return;
            getLayer2Balance(queryLayer2Param)
                .then((res) => {
                    if (res.status == "success") {
                        const { decimals, show_scale: showScale, symbol } = this.coinValue;
                        const totalBalanceWei = filterL2Balance(res.result, 'committed', symbol) || "0";
                        const availableBalanceWei = filterL2Balance(res.result, 'available', symbol) || "0";
                        const lockedBalanceWei = filterL2Balance(res.result, 'locked', symbol) || "0";

                        this.totalBalance = formatTokenCommify(
                            ethers.utils.formatUnits(totalBalanceWei, decimals),
                            showScale
                        );
                        this.availableBalanceWei = availableBalanceWei;
                        this.balance = formatTokenCommify(
                            ethers.utils.formatUnits(availableBalanceWei, decimals),
                            showScale
                        );
                        this.lockedBalance = formatTokenCommify(
                            ethers.utils.formatUnits(lockedBalanceWei, decimals),
                            showScale
                        );
                        this.canWithdrawal = true;
                    }
                }).catch(e => e)
                .finally(() => {
                    if (this.dealAll) this.toAll()
                    this.balance_loading = false;
                });
        },
        clearPagesCache() {
            this.custAddressError = null;
            this.curAmount = "";
            this.amountError = "";
        },
        copy() {
            this.$message.config({
                top: 290,
                duration: 10,
            });
            this.$message.success({
                content: this.$t("wallet_common_copied"),
                duration: this.popShowTime,
                closable: true,
            });
        },
        changeCode() {
            this.submitError = null;
        },
        getSubmitTokens(code) {
            this.loading = true;
            this.showToast = false
            getSubmitTokens().then((res) => {
                // 1、判断中心化和非中心化
                //  接口不一样
                if (this.accountIsCustody) {
                    // 中心化
                    const params = {
                        ...code,
                        chain_id: this.chainId,
                        // 转换成wei
                        amount: ethers.utils.parseUnits(this.curAmount, this.coinValue.decimals).toString(),
                        token: this.coinValue.symbol,
                        to_address: this.curAddress,
                        fee_token: this.feeToken
                    }
                    // 2、调用转账接口
                    // https://wiki.everylink.ai/pages/viewpage.action?pageId=90406922
                    l2CommonWithdraw(params, res.data).then(res => {
                        this.$message.success({
                            content: this.$t("wallet_withdraw_success")
                        });
                        try {
                            setTimeout(() => {
                                this.getInfo();
                            }, 500);
                        } catch (error) {
                            console.log("getInfo", error);
                        }
                    }).catch((err) => {
                        console.log("err", err);
                        const h = this.$createElement;
                        this.$message.error({
                            content: [
                                h('span', this.$t("wallet_withdraw_fail")),
                                h('br', null),
                                h('span', `(${err.message})`),
                            ],
                            closable: true,
                        });
                    }).finally(() => {
                        this.loading = false;
                    })
                } else {
                    let params = {
                        requestId: res.data,
                        coin_name: this.coinValue.name,
                        ...code,
                        withdraw_amount: this.curAmount,
                        address: this.curAddress,
                        network_id: this.confirmation.network_id,
                        chain_id: this.chainId,
                    };

                    goWithdraw(params).then(
                        (res) => {
                            this.$store.commit("wallet/setWithdrawInfo", {
                                address: this.curAddress,
                                network_id: this.confirmation.network_id,
                                withdraw_amount: this.curAmount,
                                symbol: this.symbol,
                            });
                            this.$router.push({
                                name: "withdrawResult",
                            });
                        },
                        (res) => {
                            this.submitError = res.message;
                        }
                    ).catch(e => e);
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        resetFee() {
            this.transaction_fee = "";
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
        },
        setSearchKey() {
            sessionStorage.setItem('searchSymbol', this.chainId + '-' + this.coinValue.name)
        },


        //nftMethods
        async checkChainId(chainId) {
            // 链不一致，需要切换链。
            let walletChainId = await this.web3Wallet.get().eth.getChainId();
            if (walletChainId != chainId) {
                this.$bus.$emit("changeBridgeNetWork", chainId);
                return false;
            }

            return true;
        },

        matchWalletChainId(){
            const walletChainId=this.$store.state.wallet.walletChainId;
            if(!walletChainId){
                return
            }
            this.nftNetworkList.forEach(item=>{
                if(Number(item.chainId)===Number(walletChainId)){
                    this.nftNetwork=item.value;
                }
            })
        },

        async withdrawNFT(){


            const bool = await this.checkChainId(this.nftChainId);
            if (!bool) {
                return;
            }
            console.log('withdrawNFT')
            // const contratMap = await this.$store.dispatch('getContratMap')

            if(!this.curAddress||!this.nftId){
                return
            }

            this.withdrawNFTLoading=true;
            const syncWallet = this.walletData.getSyncWallet();


            const params = {
                to: this.curAddress,
                token: this.nftId,
                feeToken: '0x0000000000000000000000000000000000000000',
            }


            const withdrawResult = await syncWallet.withdrawNFT(params).catch(error=>{
                console.log(error.message)
                this.withdrawNFTLoading=false;
                this.$message.error(error.message||'withdrawNFT failed!');
            });

            console.log('withdrawResult')
            console.log(withdrawResult)
            if(!withdrawResult){
                this.withdrawNFTLoading = false;
                this.$message.error({
                    content: this.$t("wallet_withdraw_fail"),
                    duration: this.popShowTime,
                    closable: true,
                });
                return
            }

            const { txHash } = withdrawResult;

            if(!txHash){
                this.withdrawNFTLoading = false;
                this.$message.error({
                    content: this.$t("wallet_withdraw_fail"),
                    duration: this.popShowTime,
                    closable: true,
                });
                return
            }
            this.getTxReceipt(txHash)
        },

        async fetchNftList() {
            return
            const address = this.account; //this.walletData.getAddress();
            console.log(this.nftNetwork)
            if(!this.nftNetwork){
                return
            }
            const res = await fetchNftList({
                page_no: 1,
                page_size: 1000,
                address,
                chain_layer_list:this.nftNetwork,
            })
            let nftList = [];
            const { nfts } = res.data;
            for (let i = 0; i < nfts.length; i++) {
                const item = nfts[i];
                if (item.layer === 1) {
                    const nftData = await parseNFTData(item.nft_data);
                    const nftType = Object.prototype.toString.call(nftData);
                    if (nftType === "[object String]") {
                        item.nft_image = nftData;
                    } else if (nftType === "[object Object]") {
                        item.nft_data_object = nftData;
                        item.nft_image = nftData.image;
                    }

                    nftList.push(item);
                }

                if (item.layer === 2) {
                    const { content_hash } = item;
                    console.log(item);
                    const hash = "e30101701220" + content_hash.split("0x")[1];
                    const cid = content_hashUtils.decode(hash);
                    item.ipfsUrl = IPFS_PREVIEW_URL + cid;
                    let ipfsRes = null;
                    try {
                        ipfsRes =await axios.get(item.ipfsUrl).catch();
                        item.nft_data = ipfsRes.data;
                        item.nft_data_object = ipfsRes.data;
                        item.nft_image = item.nft_data_object.image;
                        nftList.push(item);
                    } catch (error) {
                        item.nft_data={}
                        item.nft_data_object={}
                        item.nft_image='';
                        nftList.push(item);

                    }

                }
            }


            this.nftList=nftList;

        },

        async getTxReceipt(txHash) {
            const provider = this.walletData.getProvider();
            const result = await provider.getTxReceipt(txHash);
            const { executed, failReason, success } = result
            if (!executed) {
                setTimeout(() => {
                    this.getTxReceipt(txHash);
                }, 5000);
                return;
            }

            if(!success){
                this.withdrawNFTLoading = false;
                this.$message.error({
                    content: this.$t("wallet_withdraw_fail"),
                    duration: this.popShowTime,
                    closable: true,
                });
                return
            }

            this.withdrawNFTLoading=false;
            this.$message.success({
                content: this.$t("wallet_withdraw_success"),
                duration: this.popShowTime,
                closable: true,
            });


            setTimeout(() => {
                this.fetchNftList();
                this.getNftWithdrawHistory();
            },1000)


        },

        setNftNetworkList() {

            const list = getL2NetworkList(
                this.$t("wallet_common_all_networks")
            ).filter(item=>{
                return item.layer===2
            });
            console.log('setNftNetworkList:', list)

            const iconList = this.getNetworkList();

            console.log('setNftNetworkList:', iconList)
            // const DTX_ICON = iconList.find(
            //     (item) => item.symbol === "DTX"
            // ).networkLogo;
            // const RINKEBY_ICON = iconList.find(
            //     (item) => item.symbol === "ETH"
            // ).networkLogo;


            // console.log('iconList')
            // console.log('iconList')
            // console.log('iconList')
            // console.log('iconList')
            // console.log('iconList')
            // console.log(iconList)



            list.forEach(item=>{
                let chainitem = iconList.find(v => v.chainId == item.chainId)
                if (chainitem) {
                    item.symbol = chainitem.symbol
                    item.networkLogo = chainitem.networkLogo
                }
            })

            this.nftNetworkList = list;
            console.log(99999999)
            console.log(99999999)
            console.log(99999999)
            console.log(list)

            const filterList = list.filter(item=>{
                return item.layer == 2 && (item.chainId == '97' || item.chainId == '230')
            })

            if(filterList.length){
                this.nftNetwork=filterList[0].value;
            }

            console.log(888888)
            console.log(this.nftNetwork)

        },

        selectNft(){

        },
        selectNftNetwork(item) {
            console.log('sssssssss')
            console.log('sssssssss')
            console.log('sssssssss')
            console.log(item);
            this.nftChainId = item.chainId;
            this.getNftWithdrawHistory();
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem('searchSymbol')
    }
};
