//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CoinSelect from "./components/CoinSelect"
import Confirmation from "./components/Confirmation"
import iconDown from '@/assets/img/wallet/icon-down.png'
import iconUp from '@/assets/img/wallet/icon-up.png'
import { checkAddress, getFinance, getWithdraw, goWithdraw, getSubmitTokens, getHistoryTransaction, getSignleSymbolPrice, getAddress, fetchNftList, sendRawTransaction, web3jQuery} from "../../api/wallet/index"
import Storage from "@/utils/localStorage/Storage"
import securityVerif from '../../components/securityVerif.vue'
import { ethers, utils, BigNumber} from "ethers";
import {  formatTokenCommify, formatFiatCommify, getWalletCoinFee, isTokenETH, onEvents,  getWalletNetWorkConfig, checkEthAddress, accAddSub, parseNFTData, formatParamsByAbi } from '../../utils/index'
import { getAddressByMemberId } from '../../api/common/index'
import { updateTicket } from '../../api/nft/index';
import ChainSelect from '../home/component/ChainSelect.vue';
import TokenSelect from '../home/component/TokenSelect.vue';
import NftSelect from "../home/component/NftSelect.vue";

import BNP from "bignumber.js";
import abi_721 from "../../constracts/erc721.json";
import abi_1155 from "../../constracts/erc1155.json";
import { conf as config } from "./../../config/index";
import { formatUTCTime, commifyAndFloor, historyStatus, getHashBlockUrl } from "../../utils/index";

const txInfoDict = {
    "deposit_to_l2": 1,
    "deposit": 1,
    "supply": 1,
    "withdraw": 1,
    "borrow": 1,
    "repay": 1,
    "mint": 1,
    "burnt": 1,
    "bridge": 2,
    "send": 1,
}
function getTxInfoType(transactionType) {
    return txInfoDict[transactionType.toString().toLowerCase()];
}

export default {
    components: {
        CoinSelect,
        Confirmation,
        securityVerif,
        ChainSelect,
        NftSelect,
        TokenSelect,
    },
    computed: {
        tokens() {
            return this.$store.getters.chainLayerToken(`${this.chainId}-1`) || [];
        },
        // 判断是否需要2FA验证，可以在后台配置
        needTwoFA() {
            return this.$store.getters.needTwoFA;
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        uid() {
            return this.userInfo?.uid;
        },
        account() {
            return this.userInfo?.uid;
        },
		walletNetworks() {
			return this.$store.getters.walletNetworks;
		},
        netWorkList() {
            let networkList = this.$store.getters.walletNetworks || []
            return networkList
        },
        layer1Balance() {
            return this.layer1BalanceWei && ethers.utils.formatUnits(
                this.layer1BalanceWei.toString(),
                this.curToken.decimals
            );
        },
        layer1BalanceString() {
            return this.layer1Balance && formatTokenCommify(
                this.layer1Balance,
                this.curToken.show_scale
            );
        },
        layer1NetworkList() {
			return this.$store.getters.layer1NetworkList;
		},
        // symbol() {
        //     return this.curToken.symbol
        // },
        decimals() {
            return this.curToken.decimals
        },
		maxAmount() {
            if (this.curNativeToken.symbol === this.curToken.symbol) {
                if (new BNP(this.layer1Balance).gt(this.transaction_fee)) {
                    return new BNP(this.layer1Balance)
                        .minus(this.transaction_fee)
                        .toString();
                }
                return 0;
            } else {
                return this.layer1Balance.toString();
            }
		},
        curChain() {
            if (!this.chainId) return null;
            return this.walletNetworks.find(item => item.chainId === this.chainId);
        },
        layer1TokenList() {
            return this.$store.getters.layer1TokenList(this.chainId) || [];
        },
        curNativeToken() {
            return this.layer1TokenList.find(item => item.contract_address === ethers.constants.AddressZero) || {};
        },
        curNft() {
            if (!this.nftId) return null;
            return this.nftList.find(item => item.id === this.nftId)
        },
        nftRecipientError() {
            if (this.curAddress && !utils.isAddress(this.curAddress)) return this.$t(
                    "wallet_transfer_message_error_insufficientError"
                );
            return null;
        },
        supportNFT() {
            return this.$store.getters.supportNFT;
        },

        nftFormInvalid() {
            return !this.nftId || !this.curAddress || this.nftRecipientError || this.nftSendLoading;
        },
        blockExplorerUrl() {
			// 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
			const privateUrl = this.$store.state.siteConfig.blockExplorerUrl;
			if (privateUrl) {
				return privateUrl.endsWith("/")
					? `${privateUrl}${this.chainId}/L2/`
					: `${privateUrl}/${this.chainId}/L2/`;
			} else {
				const url = this.$store.getters.filterNetworkItem(
					this.chainId,
					"L1"
				).blockExplorerUrl;
				return url.endsWith("/") ? url : url + "/";
			}
		},
    },
    watch: {
        uid(val) {
            if (val && this.decimals) {
                this.getInfo()
                this.getFeeFunc()
            }
        },
        netWorkList() {
            if (this.uid) {
                this.getInfo()
                this.getFeeFunc()
            }
        },
        nftId() {
            this.fetchGasFee();
        },
        curAddress() {
            this.fetchGasFee();
        },
        curToken() {
            this.symbol = this.curToken.symbol
        }
    },
    data() {
        return {
            tabId: "token",
            loading: false,
            chainId: null,
            canWithdrawal:false,
            showToast: false,
            submitError:null,
            clipboard:"",
            viewAllShow:false,
            confirmation: {},
            countTime: 0,
            curNotice: '',
            transferAddrList: [],
            bread: [
                {
                    path: "/wallet",
                    name: this.$t('wallet_common_wallet')
                },
                {
                    path: "/wallet/withdraw/index",
                    name: this.$t('wallet_common_send_l1')
                },
            ],
            CoinSelectShow:false,
            curToken: {
                name: "Ethereum",
                symbol: ''
            },
            totalBalance: "",
            balance: "",
            lockedBalance: '',
            // history
			columns: [
				{
					title: this.$t("wallet_common_date"),
					// dataIndex: "date",
					key: "time",
					width: 210,
                    scopedSlots: { customRender: "time" }
				},
				{
					title: this.$t("wallet_common_asset"),
					// dataIndex: "symbol",
					key: "symbol",
					width: 80,
					scopedSlots: { customRender: "symbol" },
				},
				{
					title: this.$t("wallet_common_amount"),
					// dataIndex: "amount",
					key: "amount",
					width: 120,
					scopedSlots: { customRender: "amount" },
				},
				{
					title: this.$t("wallet_common_status"),
					key: "status",
					width: 120,
					scopedSlots: { customRender: "status" },
				},
				{
					title: this.$t("wallet_common_information"),
					key: "information",
					scopedSlots: { customRender: "information" },
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
					width: 100,
					align: "center",
					scopedSlots: { customRender: "action" },
				},
			],
            loadingTokenHistory: false,
            data: [],
            depositData: [
            ],

            curAddress: '',
            custAddressShow:false,
            preAddress: Storage.get('WITHDRAWADDRESS'),
            custAddressError:null,
            timeOut:null,
            curAmount: null,
            amountError: null,
            submitDis:true,
            transaction_fee: 0,
            transaction_fee_value: 0,
            // account: '',
            layer1BalanceWei: 0,
            balance: 0,

            // nft
            nftLoading: false,
            nftList: [],
            nftId: null,
            nftExpand: false,
            nftGasFee: null,
            nftSendLoading: false,


            nftHistoryLoading: false,
            nftHistoryList: [],
            nftViewAllVisible: false,
            nftColumns: [
				{
					title: this.$t("wallet_history_type"),
					// dataIndex: "txType",
					key: "txType",
					width: 80,
					scopedSlots: { customRender: "txType" },
				},
				{
					title: this.$t("wallet_common_date"),
					// dataIndex: "createdAt",
					key: "createdAt",
					width: 120,
					scopedSlots: { customRender: "createdAt" },
				},
				{
					title: this.$t("wallet_common_asset"),
					// dataIndex: "token",
					key: "asset",
					width: 180,
					scopedSlots: { customRender: "asset" },
				},
				{
					title: this.$t("wallet_common_status"),
					key: "status",
					width: 120,
					scopedSlots: { customRender: "status" },
				},
				{
					title: this.$t("wallet_common_information"),
					key: "information",
					scopedSlots: { customRender: "information" },
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
					width: 100,
					align: "center",
					scopedSlots: { customRender: "action" },
				},
			],
            symbol: '',
            getHashBlockUrl,
            gasLimit: 0,
            gasPrice: 0    
        }
    },
    created() {
        const { symbol, chainId, } = this.$route.query;
        this.chainId = chainId ? Number(chainId) : this.$store.state.chainId;
        this.$store.commit('setChainIdAndLayerCache', this.chainId + '-1')
        console.log('withdraw-chaind....', this.chainId)
        if (symbol) {
            this.curToken.symbol = symbol;
            this.symbol = this.curToken.symbol
        }
        this.fetchNftList();
        this.$nextTick(() => {
            onEvents("refresh", (events) => {
                if (!!~events.indexOf("currency")) {
                    this.getFeeFunc(this.curToken.symbol);
                }
            })
        })
        console.log("config:", config);
        this.fetchTokens();
    },
    mounted() {
        this.$store.commit('setChainIdAndLayerCache', this.chainId + '-1')
        this.queryAddress();
    },
    methods: {
        async fetchTokens() {
            await this.$store.dispatch("getTokenByChainAndLayer", {chainLayer: `${this.chainId}-1`}).catch(e => []);
			if (!this.curToken.symbol) {
				this.curToken = structuredClone(this.tokens[0])
			} else {
				// 检查选中的symbol是否在当前tokens中
				const symbols = this.tokens.map(item => item.symbol);
				if (!symbols.includes(this.symbol)) {
					this.curToken = structuredClone(this.tokens[0])
                    this.symbol = this.curToken.symbol
				}
			}

            this.symbol = this.curToken?.symbol || "";
            const item =  this.tokens.find(v => v.symbol === this.symbol);
			this.changeCoin(item || {});
		},
		formatUTCTime,
        changeTab(key) {
            this.tabId = key;
            if (key === 'nft') {
                this.fetchNftList();
                this.getNftTransferHistory();
            }
        },
        goSymbolInfo() {
            this.$store.commit('setWithdrawInfo',{
                address: this.curAddress,
                network_id: this.confirmation.networkId,
                withdraw_amount: this.curAmount,
                symbol: this.symbol
            })
            this.$router.push({
                path: '/custody/send/result'
            })
        },
        changeChain(obj) {
            this.layer1BalanceWei = "0";
            this.fetchTokens();
        },
        changeCoin(item) {
            console.log('changeCoin:init', item)
            if (!Object.keys(item).length) {
                return;
            }
            this.layer1BalanceWei = "0";
            this.curToken = structuredClone(item)
            this.clearPagesCache();
            this.getInfo();
            this.getFeeFunc()
        },
        change(name) {
            this.confirmation = this.depositData.find(item=>item.network_name == name);
            this.clearPagesCache();
        },
        queryAddress() {
            this.transferAddrList = this.$store.state.transferAddrList || [];
            if (this.transferAddrList.length) return;
            let params = Object.assign(
                {},
                {
                    network_id: this.chainId,
                }
            );
            getAddress(params).then((res) => {
                if (res.code == 200) {
                    let addrList =
                        res.data.map((v) => {
                            return v.address;
                        }) || [];
                    if (addrList.length) {
                        this.$store.commit("setTransferAddrList", addrList);
                        this.transferAddrList = addrList;
                    }
                }
            }).catch(e => e);
        },
        addressBlur(value) {
            if(value) {
                this.checkAddress(value)
            }else {
                this.custAddressShow = false;
                this.custAddressError = this.$t('wallet_withdraw_message_error_addressBlurError')
            }
        },
        checkAddress(value) {
            // this.curAddress = value;
            if(!checkEthAddress(this.curAddress)) {
                this.custAddressError = '';
                this.getFeeFunc()
            } else {
                this.custAddressError = this.$t("wallet_invalid_address")
            }
            // this.custAddressError = (checkEthAddress(this.curAddress) && this.$t("wallet_invalid_address")) ||  "";
            this.setsubmitDis()
        },
        blurOrClick(e) {
            console.log(e.target.nodeName)
            let nodeName = e.target.nodeName;
            this.timeOut && clearTimeout(this.timeOut)
            return (()=> {
                this.timeOut = setTimeout(()=>{
                    if(nodeName=="INPUT") {
                        this.custAddressShow = false;
                        this.addressBlur(e.target.value)
                    }else if(nodeName=='DIV'){
                        this.custAddressShow = false;
                        this.checkAddress(this.preAddress)
                    }
                },300)
            })();
        },
        clearCurAddress() {
            this.curAddress = "";
            this.checkAddress();
        },
        // amount
        curAmountInput(e) {
            let num = this.amountDecimals;
            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            let value = e.target.value;
            this.curAmount = value.replace(reg, "$1.$2");
            e.target.value = this.curAmount;
            this.checkAmount();
        },
        toAll() {
            if (this.balance <= 0) {
                this.curAmount = 0;
                return;
            }
            let curAmount;
            if (this.curNativeToken.symbol === this.curToken.symbol) {
                curAmount = accAddSub(
                    Number(this.balance.replace(/\,/g, "")),
                    this.transaction_fee,
                    -1
                );
            } else {
                curAmount = Number(this.balance.replace(/\,/g, ""));
            }
            if (curAmount < 0) {
                curAmount = 0;
            }
            let num = this.curToken.show_scale;
            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            this.curAmount = curAmount.toString().replace(reg, "$1.$2");
            this.checkAmount();
        },
        blurAmount(e) {
            this.checkAmount();
        },
        checkAmount(){
            if(!this.curAmount) {
                this.amountError = this.$t('wallet_withdraw_message_error_amountError')
            }else if(Number(this.curAmount)<this.confirmation.min_withdraw_amount) {
                this.amountError = `${this.$t('wallet_withdraw_message_error_withdrawError')} ${this.confirmation.min_withdraw_amount} ${this.symbol}.`;
            }else if(Number(this.curAmount)>Number(this.balance)) {
                this.amountError = this.$t('wallet_withdraw_message_error_insufficientError')
            }else {
                this.getFeeFunc()
                this.amountError = null;
            }
            this.setsubmitDis()
        },
        goAddress(routerName = "address"){
            this.$router.push({
                // path: "/wallet/withdraw/address",
                name: routerName,
                query: {
                    symbol: this.symbol,
                    network_id: this.confirmation.networkId,
                    coin_name: this.curToken.name,
                    network_name: this.confirmation.name,
                    router_name: this.$route.name,
                }
            })
        },
        checkParams(params){
            if (!params.address){
                this.curNotice = this.$st('wallet_withdraw_message_error_addrIncorrect')
                return 0;
            } else if (!params.amount){
                this.curNotice = this.$st('wallet_withdraw_message_error_amountIncorrect')
                return 0;
            } else if (!params.network_id){
                this.curNotice = this.$st('wallet_withdraw_message_error_networdIncorrect')
                return 0;
            } else{
                this.curNotice = ''
            }
        },
        async getFeeFunc() {
            let GasLimit = 30 * 10000; // 同后端保持一致，300,000
            const provider = ethers.providers.getDefaultProvider(this.confirmation.rpcUrl)
            const fullFee = await provider.getFeeData();
            let gasPrice = fullFee.gasPrice;
            if(this.curToken?.contract_address) {
                const params = []
                if(this.curAddress) {
                    params.push({
                        param_type: "address",
                        param_value: this.curAddress,
                    })
                }
                if(this.curAmount) {
                    params.push({
                        param_type: "uint256",
                        param_value: ethers.utils.parseUnits(this.curAmount, this.curToken.decimals).toString(),
                    })
                }
                const data = {
                    chain_id: this.chainId,
                    to_address: this.curToken.contract_address,
                    method:"transfer",
                    method_type:"GAS_LIMIT",
                    value:"0",
                    params: params,
                }
                const res = await web3jQuery(data)
                if(res.code == '200' && res.data && res.data.result) {
                    GasLimit = res.data.result.gasLimit;
                    gasPrice = BigNumber.from(res.data.result.gasPrice || '0')
                }
            }
            const gasFeeWei = gasPrice.mul(GasLimit);
            const gasFee = ethers.utils.formatUnits(gasFeeWei, this.curNativeToken.decimals);
            this.transaction_fee = commifyAndFloor(gasFee, this.curNativeToken.show_scale);
            this.gasLimit = GasLimit;
            this.gasPrice = gasPrice;
            if (this.transaction_fee == 0) {
                this.transaction_fee = gasFee;
            }
        },
        setsubmitDis() {
            if (
                new BNP(this.curAmount).gt(this.maxAmount)
            ) {
                this.submitDis = true;
                return;
            }
            if (!this.canWithdrawal) {
                this.submitDis = true;
                return;
            }
            if (
                this.curAddress &&
                !this.custAddressError &&
                this.curAmount &&
                !this.amountError
            ) {
                this.submitDis = false;
            } else {
                this.submitDis = true;
            }
        },
        goSubmit(){
            if(this.submitDis) {
                return;
            }
            let params = {
                address:this.curAddress,
                amount: this.curAmount,
                fee: this.transaction_fee,
                coin: this.curToken.symbol,
                network_id: this.confirmation.networkId,
                network_name: this.confirmation.name,
                symbol: this.symbol
            }
            if (!params.address){
                this.curNotice = this.$st('wallet_withdraw_message_error_addrIncorrect')
                return 0;
            } else if (!params.amount){
                this.curNotice = this.$st('wallet_withdraw_message_error_amountIncorrect')
                return 0;
            } else if (!params.network_id){
                this.curNotice = this.$st('wallet_withdraw_message_error_networdIncorrect')
                return 0;
            } else{
                this.curNotice = ''
            }
            // this.submitInfo = Object.assign({},params)
            // Storage.set('CURADDRESS',this.curAddress);
            this.$store.state.submitInfo = Object.assign({},params);
            this.$store.state.confirmFlag = true;
            // goWithdraw({coin_name: this.coinValue.name},params).then(res=> {
            //     if(res.code==200) {
            //         console.log(res);
            //         flag = true;
            //     }
            // });
        },
        submit() {
            this.$store.state.confirmFlag = false;
            if (this.needTwoFA) {
                this.showToast = true;
            } else {
                this.getSubmitTokens({});
            }
        },
        submitFn() {
            alert(1)
        },
        toHistory() {
            this.$router.push({
                path: "history",
                query: {
                    transaction_type: 'withdraw',
                    coin_name: this.coinValue.name,
                }
            })
        },

        getInfo() {
            this.depositData = this.getNetworkList();
            if (this.chainId) {
                this.confirmation = this.depositData.find((v) => {
                    return v.chainId == this.chainId;
                });
            }
            if (!Object.keys(this.confirmation).length) return
            if (!this.curAddress) this.curAddress = '';
            this.getBalance();
            this.getHistory()
        },
        async getBalance() {
            this.balance_loading = true;
            this.totalBalance = "";
            this.balance = "";
            this.lockedBalance = "";

            const rpcUrl = this.confirmation.rpcUrl;
            const chainId = this.chainId;
            const tokenAddress = this.curToken.contract_address;
            let balance;

            if (isTokenETH(tokenAddress)) {
                balance = await this.walletUtil
                    .getWeb3Erc20Amount(rpcUrl, this.account)
                    .then((res) => res)
                    .catch((e) => {
                        console.log('e:', e)
                        this.loadLayer1Failed = true;
                        return "0";
                    });
                console.log('balance:', balance)
            } else {
                if (!rpcUrl  || !tokenAddress) return
                const contract = await this.walletUtil.dealWeb3QueryContract(
                    rpcUrl,
                    tokenAddress
                );
                balance = await contract.methods.balanceOf(this.account).call()
                    .catch((e) => {
                        console.error("[erc20]getLayer1Balance e:", e);
                        this.loadLayer1Failed = true;
                        return "0";
                    })
            }
            // 解决 已经切换 链 或者 token
            if (chainId === this.chainId && tokenAddress === this.curToken.contract_address) {
                this.layer1BalanceWei = balance;
                console.log('this.layer1BalanceWei:', this.layer1BalanceWei)
                this.balance = formatTokenCommify(
                    ethers.utils.formatUnits(balance, this.curToken.decimals),
                    this.curToken.show_scale
                );
            }
            this.canWithdrawal = true
        },
        async getHistory() {
            this.loadingTokenHistory = true;
            let params = {
                page_no: 0,
                page_size: 5,
                token_type: "ERC20",
                coin_name: this.curToken.symbol,
                transaction_type: 'All',
                transaction_status: 'All',
				start_date: "",
				end_date: "",
                layer: "L1",
                address: this.uid,
                chain_id: this.confirmation.chainId,
            }
            await getHistoryTransaction(params).then(res=> {
                if(res.code==200) {
                    console.log(res)
                    this.viewAllShow = res.data.total_number>5;
                    this.data = res.data.transaction_history_list.map(item=>{
                        return Object.assign(item,{expand:false})
                    });
                }
            }).catch(e => e);
            this.loadingTokenHistory = false;
        },
		getLayer1NetworkItem(chainId) {
			return this.layer1NetworkList.find(item => item.chainId === chainId);
		},
		getNetworkList(arr) {
			return this.netWorkList;
		},
        clearPagesCache() {
            this.custAddressError = null;
            this.curAmount = "";
            this.amountError = "";
        },
        copy() {
            this.$Message.config({
                top: 290,
                duration: 1.5
            });
            this.$Message.success({
                content: this.$st('wallet_common_copied'),
            })
        },
        changeCode() {
            this.submitError = null;
        },
        resetFee() {
            this.transaction_fee = "";
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
        },
        getSignleSymbolPrice() {
            console.log("this.$store.state.user", this.$store.state.user);
            getSignleSymbolPrice({
                fsym: this.coinValue.symbol,
                tsyms: this.$store.state.user.currency.name,
                // chain_id: this.chainId
            })
                .then((getFeeVal) => {
                    this.transaction_fee_value =  formatFiatCommify(
                            this.transaction_fee * getFeeVal[this.$store.state.user.currency.name], this.coinValue.show_scale
                        );
                })
                .catch((err) => {
                    console.log("err", err);
                    this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
                });
        },
        getSubmitTokens(code) {
            console.log('getSubmitTokens:', code)
            if (this.loading) {
                return;
            }
            this.loading = true;
            getSubmitTokens().then(res=> {
                let params = {
                    "requestId":res.data,
                    "coin_name":this.curToken.symbol,
                    ...code,
                    "withdraw_amount": this.curAmount,
                    "amount_wei": ethers.utils.parseUnits(this.curAmount, this.curToken.decimals).toString(),
                    "address": this.curAddress,
                    "network_id": this.confirmation.networkId,
                    "token_address": this.curToken.contract_address,
                    "gas_limit": this.gasLimit,
                    "gas_price": this.gasPrice.toString()
                }
                goWithdraw(params).then(res => {
                    console.log('res:', res);
                    this.postTokenTransactionHistory(res.data);
                    this.$store.commit('setWithdrawInfo', {
                        address: this.curAddress,
                        network_id:this.confirmation.networkId,
                        withdraw_amount: this.curAmount,
                        symbol: this.symbol
                    });
                    this.$router.push({
                        path: '/custody/send/result',
                    })
                },res=> {
                    // this.submitError = res.message;
                    // 统一错误提示
                    this.$message.error(res.message);
                }).catch(e => e).finally(() => {
                    this.loading = false;
                })
            }).catch(() => {
                this.loading = false;
            })
        },

        // token交易记录
        // {
        //     "type" : "withdraw",
        //     "action_symbol" : "-",
        //     "id" : 111,
        //     "time" : "2023-03-08 11:32:53 UTC",
        //     "symbol" : "MATIC",
        //     "amount" : "0.001",
        //     "address" : "0x0e3AE202a06A125DFE0dAB65A9D9fB137123ef51",
        //     "memo" : "",
        //     "txid" : "0x9ac9a020f84477af94355429f659614d002dacfc565c17a98942c9facacce6f1",
        //     "status" : "Auditing",
        //     "network_confirmation" : "0/8",
        //     "network_name" : "ETH",
        //     "gas_fee" : "0"
        // }
        async postTokenTransactionHistory(receipt) {
            let params = {
				// submit_block: receipt.blockNumber,
				from_address: this.uid,
				from_chain_id: this.confirmation.networkId,
				layer: "L1",
				from_network: this.confirmation.name,
				from_tx_hash: receipt.txid,
				to_address: receipt.address,
				to_chain_id: "",
				to_network: "",
				token_address: this.curToken.contract_address,
				token_type: "ERC20",
				status: "Pending",
				type: "Send",
				date: formatUTCTime(new Date()),
				symbol: receipt.symbol,
				amount: receipt.amount,
				action_symbol: "-",
			};
			await this.$store.dispatch("sendTransactionRecord", {
				params,
			});
        },

        // nft
        onChangeNftChain(item) {
            this.nftList = [];
            this.fetchNftList();
            // this.getNftTxFee();
            this.getNftTransferHistory();
        },
        async fetchNftList() {
            this.nftLoading = true;
            await fetchNftList({
                page_no: 1,
                page_size: 1000,
                address: this.account,
                chain_layer_list: this.chainId + '-1',
            }).then(res => {
                this.nftList = res.data?.nfts;
                this.formatNftData();
            }).catch(e => e);
            this.nftLoading = false;
        },
        async formatNftData() {
            this.nftList.forEach(async item => {
                let nftData = await parseNFTData(item.nft_data);
                const nftType = Object.prototype.toString.call(nftData);
                // console.log("nftData:", nftData);
                // console.log("nftType:", nftType);
                if (nftType === "[object String]") {
                    try {
                        item.nft_data = JSON.parse(nftData);
                    } catch (e) {
                        item.nft_data = {};
                    }
                } else if (nftType === "[object Object]") {
                    item.nft_data = nftData;
                }
            })
        },
        async fetchGasFee() {
            // 非签名用户预估不了gas.
            return ;
            if (!this.curNft) return;
            if (!this.curAddress) return;
            if (this.nftGasFee) return;

            const rpcUrl = this.curChain.rpcUrl;
            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const contractAddress = this.curNft.contract;
            const tokenType = this.curNft.token_type;
            if (tokenType === 2) {
                const nftContract = new ethers.Contract(contractAddress, abi_721, provider);
                const gasFee = await nftContract.estimateGas.safeTransferFrom(this.account, this.curAddress, this.curNft.nft_id);
                console.log('gasFee:', gasFee);
            } else if (tokenType === 3) {
                const nftContract = new ethers.Contract(contractAddress, abi_1155, provider);
                const gasFee = await nftContract.estimateGas.safeTransferFrom(this.account, this.curAddress, this.curNft.nft_id, this.curNft.amount, "0x");
                console.log('gasFee:', gasFee);
            }
        },
        async sendNFT() {
			// 托管钱包用户
			if (this.userInfo.user_type === "CENTRALIZATION") {
                this.nftSendLoading = true;
                try {
                    const submitToken = await getSubmitTokens().then(res => res.data);
                    const tokenType = this.curNft.token_type; // 1,ERC20;2,ERC721;3,ERC1155;
                    let data;
                    if (tokenType === 2) {
                        const params = [
                            this.account,
                            this.curAddress,
                            this.curNft.nft_id,
                        ];
                        data = {
                            to_address: this.curNft.contract,
                            method: "safeTransferFrom",
                            chain_id: this.chainId,
                            params: formatParamsByAbi(params, "safeTransferFrom", abi_721),
                        }
                    } else if (tokenType === 3) {
                        const params = [
                            this.account,
                            this.curAddress,
                            this.curNft.nft_id,
                            this.curNft.amount,
                            "0x"
                        ];
                        data = {
                            to_address: this.curNft.contract,
                            method: "safeTransferFrom",
                            chain_id: this.chainId,
                            params: formatParamsByAbi(params, "safeTransferFrom", abi_1155),
                            value: 0,
                        }
                    }
                    const receipt = await sendRawTransaction(data, submitToken).then(res => {
                        console.log("sendRawTransaction res:", res);
                        return res.data;
                    }).catch(e => e);
                    console.log("receipt:", receipt);

                    // 提交资产更新
                    this.updateAsset(receipt);

                    // 提交交易数据
                    this.saveRecordData(receipt);

			        this.$message.success("Send successfully!");
                    // this.$router.push({
                    //     path: '/custody/send/result',
                    // })
                } catch (error) {
                    this.$message.error(error.message);
                }
                this.nftSendLoading = false;
                setTimeout(() => {
                    this.fetchNftList();
                }, 500);
            }
        },

        async updateAsset(receipt) {
            await updateTicket({
                chain_id: this.chainId,
                id: this.curNft.id,
                to_address: receipt.to,
            });
        },
        async saveRecordData(receipt) {
            console.log("config", config);
			let params = {
                nft_data: JSON.stringify(this.curNft.nft_data || ""),
				submit_block: receipt.blockNumber,
				from_address: receipt.from,
				from_chain_id: this.chainId,
				layer: "L1",
				from_network: this.curChain.name,
				from_tx_hash: receipt.transactionHash,
				to_address: receipt.to,
				to_chain_id: "",
				to_network: "",
				token_address: this.curNft.contract,
				token_type: config.tokenTypeMap[this.curNft.token_type],
				status: "Pending",
				type: "Send",
                token_id: this.curNft.nft_id,
				// date: getUTCTime(new Date()),
				// symbol: this.symbol,
				amount: this.curNft.amount,
				action_symbol: "-",
			};
			await this.$store.dispatch("sendTransactionRecord", {
				params,
			});
            this.getNftTransferHistory();
		},
        async getNftTransferHistory() {
            let params = {
                coin_name: "",
                address: this.account,
                layer: 'L1',
                chain_id: this.chainId,
                page_no: 0,
                page_size: 10,
				token_type: "ERC721,ERC1155",
                start_date: "",
                end_date: "",
                transaction_type: "Send",
                transaction_status: "All",
            };
            this.nftHistoryLoading = true;
            this.nftHistoryList = [];
            await getHistoryTransaction(params)
                .then((res) => {
                    if (res.code == 200) {
                        this.nftViewAllVisible = res.data.total_number > 5;
                        this.nftHistoryList = res.data.transaction_history_list.map(
                            (item) => {
                                item.expand = false;
                                try {
                                    item.nft_data = JSON.parse(item.nft_data || "{}");
                                } catch (error) {

                                }
                                return item;
                            }
                        );
                    }
                }).catch(e => e)
                .finally((fina) => {
                });
            this.nftHistoryLoading = false;
        },
		toggleExpand(item) {
			item.expand = !item.expand;
		},
        onCopy(record) {
			this.clipboard = record.txid || record.from_tx_hash || "";
			this.$nextTick(function () {
				this.$refs.copyBtn.click();
			});
		},
		copySuccess() {
			this.$message.success(this.$t("wallet_common_copied"));
		},
        getBlock(type, from_tx_hash) {
            const _type = getTxInfoType(type);
            let fromUrl = getHashBlockUrl(from_tx_hash, this.chainId, 'L1', this.$store);
            if (_type === 1) {
                return fromUrl
            } else if (_type === 2) {
                return this.blockExplorerUrl + 'tx/' + from_tx_hash
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('setChainIdAndLayerCache', '')
    }
}
