//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { utils } from "ethers";
import dayjs from "dayjs";
import { getHistoryTransaction } from "../../api/wallet/index";
import { formatUTCTime, formatTxTypeKey } from "../../utils/index";

import MAP from "../../utils/map.js";

export default {
	name: "NftHistory",
	props: {
		showBread: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			time: [],
			chainLayer: null,
			symbol: null,
			tokens: [],

			txType: "",
			// 1. Mint
			// 2. Product Listing
			// 3. Purchase
			// 4. Product Delisting
			// 5. Send
			txTypeList: [
				{
					label: this.$t("wallet_common_all"),
					value: "All",
				},
				{
					label: this.$t("wallet_common_mint"),
					value: "Mint",
				},
				{
					label: this.$t("wallet_common_send"),
					value: "Send",
				},
				{
					label: this.$t("wallet_common_purchase"),
					value: "Purchase",
				},
				{
					label: this.$t("wallet_common_product_listing"),
					value: "Product Listing",
				},
				{
					label: this.$t("wallet_common_product_delisting"),
					value: "Product Delisting",
				},
			],

			txStatus: null,
			txStatusOptions: [
				{
					label: this.$t("wallet_common_all"),
					value: "All",
				},
				{
					label: this.$t("wallet_history_pending"),
					value: "Pending",
				},
				{
					label: this.$t("wallet_history_finalized"),
					value: "Finalized",
				},
				{
					label: this.$t("wallet_history_failure"),
					value: "Failure",
				},
			],

			loadingHistory: false,
			historyList: [],
			pages: {
				pageNo: 1,
				pageSize: 20,
				total: 0,
			},
			columns: [
				{
					title: this.$t("wallet_history_type"),
					// dataIndex: "txType",
					key: "txType",
					width: 80,
					scopedSlots: { customRender: "txType" },
				},
				{
					title: this.$t("wallet_common_date"),
					// dataIndex: "createdAt",
					key: "createdAt",
					width: 210,
					scopedSlots: { customRender: "createdAt" },
				},
				{
					title: this.$t("wallet_common_asset"),
					// dataIndex: "token",
					key: "asset",
					width: 180,
					scopedSlots: { customRender: "asset" },
				},
				{
					title: this.$t("wallet_common_status"),
					key: "status",
					width: 120,
					scopedSlots: { customRender: "status" },
				},
				{
					title: this.$t("wallet_common_information"),
					key: "information",
					scopedSlots: { customRender: "information" },
				},
				{
					title: this.$t("wallet_common_action"),
					key: "action",
					width: 100,
					align: "center",
					scopedSlots: { customRender: "action" },
				},
			],
		};
	},
	computed: {
		account() {
			return this.$store.state.user.userInfo?.wallet_address;
		},
		chainId() {
			const [chainId] = this.chainLayer.split("-");
			return Number(chainId);
		},
		layer() {
			const [, layer ] = this.chainLayer.split('-');
			return layer;
		},
		networks() {
			return this.$store.getters.networksOverview;
		},
		blockExplorerUrl() {
			const url = this.$store.getters.filterNetworkItem(
				this.chainId,
				"L1"
			).blockExplorerUrl;
			return url.endsWith("/") ? url : url + "/";
		},
	},
	watch: {
		chainLayer(val, oldVal) {
			if (val != oldVal) {
				this.onSearch();
			}
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		formatUTCTime,
		formatTxTypeKey,
		async init() {
			const { chainLayer, symbol } = this.$route.query;
			if (chainLayer) {
				this.chainLayer = chainLayer;
			} else {
				// 设置为钱包网络
                let layer = this.networks && this.networks[0] && this.networks[0].value
                let defaultVal = this.$store.state.chainId+ '-1';
                if (this.networks.find(v => v.value == defaultVal)) {
                    this.chainLayer = defaultVal
                } else this.chainLayer = layer
			}
			this.symbol = symbol;
			this.setFilter();

			this.fetchHistory();
		},
		setFilter() {
			this.time = [];
			this.time.push(dayjs().subtract(30, "days").format("YYYY-MM-DD"));
			this.time.push(dayjs().format("YYYY-MM-DD"));
			this.symbol = "All";
			this.txType = "All";
			this.txStatus = "All";
		},

		onChangeDate(val) {
			this.time = val;
		},
		onReset() {
			this.setFilter();
		},
		onSearch() {
			this.pages.pageNo = 1;
			this.fetchHistory();
		},
		onChangePages(page) {
			this.pages.pageNo = page;
			this.fetchHistory();
		},
		async fetchHistory() {
			const startTime =
				this.time.length > 0
					? dayjs(this.time[0] + " 0:0:0").valueOf()
					: null;
			const endTime =
				this.time.length > 0
					? dayjs(this.time[1] + " 23:59:59").valueOf()
					: null;
			const params = {
                coin_name: "",
				address: this.account,
				layer: MAP.layerMap[this.layer],
				chain_id: this.chainId,
				page_no: this.pages.pageNo - 1,
				page_size: this.pages.pageSize,
				// token_type: "ERC721,ERC1155",
                token_type: 'All',
				// transaction_type: this.txType,
                transaction_type: 'TransferNFT',
				transaction_status: this.txStatus,
				start_date: startTime,
				end_date: endTime,
			};
			this.loadingHistory = true;
			this.historyList = [];
			await getHistoryTransaction(params)
				.then((res) => {
					this.historyList = res.data.transaction_history_list.map(item => {
                        item.expand = false;
                        try {
                            item.nft_data = JSON.parse(item.nft_data || "{}");
                        } catch (error) {

                        }
                        return item;
                    });
					this.pages.total = res.data.total_number;
				})
				.catch((e) => e);
			this.loadingHistory = false;
		},
		toggleExpand(item) {
			item.expand = !item.expand;
		},
		onCopy(record) {
			this.clipboard = record.txid || "";
			this.$nextTick(function () {
				this.$refs.copyBtn.click();
			});
		},
		copySuccess() {
			this.$message.success(this.$t("wallet_common_copied"));
		},
	},
};
